import axios from 'axios';
import global from '@/components/Global';
let esaioBase = global.esaioServerBase;

// 查询储能设备列表
export const queryEsaioDeviceInfoList = params => { return axios.post(esaioBase + '/device/queryEsaioDeviceInfoList', params)};

/**
 * 查询已删除储能设备列表.
 * @param params 查询条件
 * @returns 查询结果
 */
export const queryRemovedEsaioDeviceInfoList = params => {

    return axios.post(esaioBase + '/device/queryRemovedEsaioDeviceInfoList', params);
};

// 查询产品型号列表
export const queryProductModelList = () => { return axios.post(esaioBase + '/device/queryProductModelList')};
// 查询服务器类型列表
export const queryServerTypeList = params => { return axios.post(esaioBase + '/device/queryServerTypeList', params)};
// 查询事件分类
export const queryEventClassifyList = () => { return axios.post(esaioBase + '/event/queryEventClassifyList')};
// Drtl设备下发指令请求
export const aioDrtlCommand = params => { return axios.post(esaioBase + '/device/sendEsaioBusinessCommand', params)};
// 查询国家货币.
export const queryCurrencyCodeByStationId = params => {

    return axios({

        url: esaioBase + '/device/queryCurrencyCodeByStationId',
        method: 'POST',
        ping: true,
        data: params
    });
};
// 查询设备自检测试结果信息.
export const queryDeviceSelfTestHisInfoBySn = params => { return axios.post(esaioBase + '/device/queryDrtlSelfTestHisInfo', params)};

// 获取设备的版本信息.
export const getVersionInfo = params => { return axios.post(esaioBase + '/dr5ktlUpgrade/getVersionInfo', params)};

// 获取升级信息.
export const getUpgradeInfo = params => { return axios.post(esaioBase + '/dr5ktlUpgrade/getUpgradeInfo', params)};

// 查询设备重点监测数据
export const queryRealImportantMonitorDataList = params => { return axios.post(esaioBase + '/device/queryRealImportantMonitorDataList', params)};
// 导出设备重要监测数据
export const exportImportantMonitorData = params => { return axios.post(esaioBase + '/device/exportImportantMonitorData', params, {responseType:'blob'}) };
// 查询设备重要监测历史数据
export const queryHistoryImportantMonitorDataList = params => { return axios.post(esaioBase + '/device/queryHistoryImportantMonitorDataList', params)};

// 查询设备数据指令列表
export const queryDeviceDataCommandList = params => { return axios.post(esaioBase + '/device/queryDeviceDataCommandList', params)};
// 查询设备数据指令字段列表
export const queryDeviceDataCommandFieldValueList = params => {

    return axios({

        url: esaioBase + '/device/queryDeviceDataCommandFieldValueList',
        method: 'POST',
        ping: true,
        data: params
    });
};
// 查询设备数据指令字段报表
export const queryDeviceDataCommandFieldChart = params => { return axios.post(esaioBase + '/device/queryDeviceDataCommandFieldChart', params)};

/**
 * 查询已删除的设备数据字段报表.
 * @param params 查询条件，格式如下
 *  {
 *      "id": 100,
 *      "deviceSn":"DR11222233445566778989897",
 *      "commandCode":"energy",
 *      "fieldList":["todayPvEnergy"],
 *      "startDate":"2024-05-11",
 *      "endDate":"2024-05-11"
 *  }
 * @returns 查询结果
 */
export const queryRemovedDeviceDataCommandFieldChart = params => {

    return axios.post(esaioBase + '/device/queryRemovedDeviceDataCommandFieldChart', params);
};
