<template>
    <div>
        <div class="content">
            <span class="text-up">设置路由后将会重启设备，请等待重启完成。</span>
            <el-form :model="form" :rules="formRules" ref="form" label-width="100px" :inline="true">
                <el-row>
                    <el-col :span="24">
                        <el-form-item label-width="140px" label="是否连接到云端：" prop="isCloud">
                            <el-switch class="switch" v-model="form.isCloud" :active-value="'1'" :inactive-value="'0'"/>
                        </el-form-item>
                    </el-col>
                </el-row>
                <el-row>
                    <el-col :span="24">
                        <el-form-item label="WiFi名称：" prop="wifiName">
                            <el-input class="item" v-model="form.wifiName" placeholder="请输入WiFi名称" autocomplete="off"/>
                        </el-form-item>
                    </el-col>
                </el-row>
                <el-row>
                    <el-col :span="24">
                        <el-form-item label="WiFi密码：" prop="wifiPassword">
                            <el-input class="item" v-model="form.wifiPassword" placeholder="请输入WiFi密码"
                                      autocomplete="off"/>
                        </el-form-item>
                    </el-col>
                </el-row>
                <el-row>
                    <el-col :span="24">
                        <el-form-item label="服务器IP：" prop="id">
                            <el-select class="item" v-model="form.id" placeholder="请选择服务器">
                                <el-option v-for="item in serverList" :key="item.id" :label="item.name" :value="item.id">
                                </el-option>
                            </el-select>
                        </el-form-item>
                    </el-col>
                </el-row>
                <span class="text-down">配置逆变器需要连接到平台的IP地址或域名。</span>
            </el-form>
        </div>
        <div style="text-align: center">
            <el-button type="primary" :disabled="disableBtn" @click="sendCmd">设置</el-button>
        </div>
    </div>
</template>

<script>

    import loading from '@/utils/cmdLoading';
    import message from '@/utils/message';
    import mqttService from '@/api/mqtt-service';
    import {aioDrtlCommand} from '@/api/esaio/device/esaioDeviceInfo';
    import Global from '@/components/Global';

    export default {

        name: 'wifi-server-setting',
        props: {

            deviceInfo: {

                type: Object
            },
            serverList: {

                type: Array
            },
        },
        data() {

            return {

                form: {

                    isCloud: '0',
                    id: '',
                    wifiName: '',
                    wifiPassword: ''
                },
                disableBtn: false,
                formRules: {

                    wifiName: [

                        {required: true, message: 'WiFi名称不能为空', trigger: 'blur'},
                        {min: 1, max: 30, message: 'WiFi名称不能超过30个字符', trigger: 'blur'}
                    ],
                    wifiPassword: [

                        {required: true, message: 'WiFi密码不能为空', trigger: 'blur'},
                        {min: 1, max: 30, message: 'WiFi密码不能超过30个字符', trigger: 'blur'}
                    ],
                    id: [

                        {required: true, message: '请选择服务器', trigger: 'change'}
                    ]
                },
                command: {

                    readCommandCode: 'querySysConnectionParam',
                    modifyCommandCode: 'modifySysConnectionParam'
                },
                requestParam: {}
            }
        },
        methods: {

            initPage() {

                this.disableBtn = true;
                this.cmdLoading =this.openCmdLoading('WiFi与服务器参数读取中...');
                let that = this;
                let option = {

                    loading: that.cmdLoading,
                    data: {

                        deviceSn: that.deviceInfo.serialNumber,
                        commandCode: that.command.readCommandCode
                    },
                    mqttMessageCallback: function (res) {

                        if (res.code === Global.response_status_success_obj) {

                            that.successCallback(res.data);
                        } else {

                            message.error("指令读取失败");
                        }
                    }
                };
                mqttService.sendEsaioBusinessCommand(option);
            },
            sendCmd() {

                this.$refs['form'].validate((valid) => {

                    if (valid) {

                        this.disableBtn = true;
                        this.cmdLoading =this.openCmdLoading('WiFi与服务器参数设置中...');
                        let that = this;
                        let port = '';
                        let address = '';
                        this.serverList.forEach((item) => {

                            if (item.id === that.form.id) {

                                port = item.port;
                                address = item.address;
                            }
                        });
                        that.requestParam = {

                            deviceSn: that.deviceInfo.serialNumber,
                            commandCode: that.command.modifyCommandCode,
                            data: {

                                wifiMode: this.form.isCloud,
                                wifiName: this.form.wifiName,
                                wifiPassword: this.form.wifiPassword,
                                serverIp: address,
                                serverPort: port
                            }
                        };
                        aioDrtlCommand(that.requestParam).then((res) => {

                            this.cmdLoading.close();
                            this.disableBtn = false;
                            if (res.code === Global.response_status_success_obj) {

                                message.success('配置成功，请等待设备重启');
                            } else {

                                message.error(res.msg);
                            }
                        }).catch(err => {

                            console.error(err)
                            this.disableBtn = false;
                            this.cmdLoading.close();
                        });
                    }
                });
            },
            successCallback(data) {

                this.disableBtn = false;
                this.form.isCloud = data.wifiMode;
                this.form.wifiName = data.routeSsId;
                this.form.wifiPassword = data.routePassword;
                for (let item of this.serverList) {

                    if (item.address === data.serverIp && item.port === data.serverPort) {

                        this.form.id = item.id;
                        break;
                    }
                }
            },
            openCmdLoading(msg) {

                return loading.loading(msg, 30);
            }
        }
    }
</script>

<style scoped>

    .text-up {

        margin: 0 0 0 17px;
        font-size: 12px;
        color: #999999;
    }

    .text-down {

        margin: 0 0 0 17px;
        font-size: 12px;
        color: #999999;
    }

    .item {

        width: 200px;
    }

    .switch {

        margin-left: 120px;
    }

    .content {

        height: 490px;
        margin-left: 250px;
    }
</style>
