<template>
    <el-dialog title="储能设备指令控制" :visible.sync="deviceCmdPageVisible"
               id="esaioDeviceCmdIndexDialog"
               :close-on-press-escape="false"
               custom-class="cmd-dialog-style"
               @close="closeCmdPage"
               :close-on-click-modal="false" :destroy-on-close=true>
        <el-tabs v-model="activeName" ref="tabs" tab-position="left" class="module-cmd-tabs-style" @tab-click="handleClick">
            <el-tab-pane label="开关机设置" name="powerSettingPage">
                <power-setting :deviceInfo="deviceInfo" ref="powerSettingPage"></power-setting>
            </el-tab-pane>
            <el-tab-pane label="WIFI与服务器参数设置" name="wifiServerSettingPage">
                <wifi-server-setting :deviceInfo="deviceInfo" :serverList=serverList ref="wifiServerSettingPage"></wifi-server-setting>
            </el-tab-pane>
            <el-tab-pane label="工作模式设置" name="workModeSettingPage">
                <work-mode-setting :deviceInfo="deviceInfo" ref="workModeSettingPage"></work-mode-setting>
            </el-tab-pane>
            <el-tab-pane label="基本参数设置" name="baseParamSettingPage">
                <base-param-setting :deviceInfo="deviceInfo" ref="baseParamSettingPage"></base-param-setting>
            </el-tab-pane>
            <el-tab-pane label="高级参数设置" name="advancedParamSettingPage">
                <advanced-param-setting :deviceInfo="deviceInfo" ref="advancedParamSettingPage">
                </advanced-param-setting>
            </el-tab-pane>
            <el-tab-pane label="系统协议设置" name="gridCodeSettingPage">
                <grid-code-setting :deviceInfo="deviceInfo" ref="gridCodeSettingPage"></grid-code-setting>
            </el-tab-pane>
            <el-tab-pane label="电池设置" name="batterySettingPage">
                <battery-setting :deviceInfo="deviceInfo" ref="batterySettingPage"></battery-setting>
            </el-tab-pane>
            <el-tab-pane label="强制充放电设置" name="forcedChargeSettingPage">
                <forced-charge-setting :deviceInfo="deviceInfo" ref="forcedChargeSettingPage"></forced-charge-setting>
            </el-tab-pane>
            <el-tab-pane label="同步时间" name="syncTimeSettingPage">
                <sync-time-setting :deviceInfo="deviceInfo" :autoSyncTime="autoSyncTime" ref="syncTimeSettingPage"></sync-time-setting>
            </el-tab-pane>
            <el-tab-pane label="并机设置" name="parallelSettingPage">
                <parallel-setting :deviceInfo="deviceInfo" ref="parallelSettingPage"></parallel-setting>
            </el-tab-pane>
            <el-tab-pane label="设备自检" name="ipsSelfTestPage">
                <ips-self-test :deviceInfo="deviceInfo" ref="ipsSelfTestPage"></ips-self-test>
            </el-tab-pane>
            <el-tab-pane name="upgradePage">
                <span slot="label">
                    固件升级
                    <el-image v-if="isUpgrade" :src="require('@/assets/image/upgrade_new.png')" class="new-icon"></el-image>
                </span>
                <upgrade :deviceInfo="deviceInfo" @fatherQueryVersionInfo="queryVersionInfo" ref="upgradePage"></upgrade>
            </el-tab-pane>
        </el-tabs>
    </el-dialog>
</template>

<script>
    import PowerSetting from '@/views/esaio/device/device-cmd/power-setting.vue';
    import WifiServerSetting from '@/views/esaio/device/device-cmd/wifi-server-setting.vue';
    import WorkModeSetting from '@/views/esaio/device/device-cmd/work-mode-setting.vue';
    import BaseParamSetting from '@/views/esaio/device/device-cmd/base-param-setting.vue';
    import AdvancedParamSetting from '@/views/esaio/device/device-cmd/advanced-param-setting.vue';
    import GridCodeSetting from '@/views/esaio/device/device-cmd/grid-code-setting.vue';
    import BatterySetting from '@/views/esaio/device/device-cmd/battery-setting.vue';
    import ForcedChargeSetting from '@/views/esaio/device/device-cmd/forced-charge-setting.vue';
    import SyncTimeSetting from '@/views/esaio/device/device-cmd/sync-time-setting';
    import ParallelSetting from '@/views/esaio/device/device-cmd/parallel-setting.vue';
    import IpsSelfTest from '@/views/esaio/device/device-cmd/ips-self-test.vue';
    import Upgrade from '@/views/esaio/device/device-cmd/upgrade.vue';
    import message from '@/utils/message';
    import {getVersionInfo} from '@/api/esaio/device/esaioDeviceInfo';
    import Global from '@/components/Global';

    export default {

        name: 'device-cmd',
        components: {

            ForcedChargeSetting, BatterySetting, GridCodeSetting, AdvancedParamSetting, BaseParamSetting,
            WorkModeSetting, WifiServerSetting, PowerSetting, ParallelSetting, IpsSelfTest, Upgrade, SyncTimeSetting
        },
        props: {

            deviceInfo: {

                type: Object
            },
            serverList: {

              type: Array
            },
        },
        data() {

            return {

                deviceCmdPageVisible: false,
                activeName: 'powerSettingPage',
                isUpgrade: false,
                autoSyncTime: true
            };
        },
        methods: {

            initDeviceCmdPage() {

                this.deviceCmdPageVisible = true;
                this.queryVersionInfo();
                this.activeName = 'powerSettingPage';
                this.$nextTick(() => {

                    this.$refs[this.activeName].initPage();
                });
            },
            handleClick(tab) {

                this.$refs[tab.name].initPage();
            },
            queryVersionInfo() {

                let param = {

                    sn: this.deviceInfo.serialNumber,
                };
                getVersionInfo(param).then((res) => {

                    if (res.code === Global.response_status_success_obj) {

                        let data = res.data;
                        // PCU版本是否有更新
                        let isUpgradePcu = data.pcuCurVersion != null
                            && data.pcuLastVersion != null
                            && data.pcuCurVersion !== data.pcuLastVersion
                        // DSP版本是否有更新
                        let isUpgradeDsp = data.dspCurVersion === 'V0A00D00' || (data.dspCurVersion != null
                            && data.dspLastVersion != null
                            && data.dspCurVersion !== data.dspLastVersion)
                        // PCU版本和DSP版本有任一一个需要更新，显示有新版本可用图标
                        this.isUpgrade = isUpgradePcu || isUpgradeDsp;
                        // PCU版本大于等于6.36允许手动同步时间
                        this.autoSyncTime = data.pcuCurVersion === null || data.pcuCurVersion === ''
                            || parseFloat(data.pcuCurVersion.substring(1)) < 6.36;
                    } else {

                        message.error(res.msg);
                    }
                }).catch(err => {

                    message.error('请求失败,请稍后再试');
                    console.error(err);
                });
            },
            closeCmdPage() {

                this.$refs.tabs.$destroy();
                this.deviceCmdPageVisible = false;
                this.$emit('queryEsaioDeviceInfoList');
            }
        }
    }
</script>

<style lang="scss" scoped>

    .new-icon {

        float: right;
        width: 28px;
        margin-top: 5px;
    }

    ::v-deep .el-dialog.cmd-dialog-style {

        width: 1070px;
        height: 660px;
    }

    .module-cmd-tabs-style {

        height: 560px;
    }
</style>
