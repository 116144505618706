<template>
    <div style="margin-left: 10px">
        <el-row class="top-row">
            <el-col :span="24">
                <label>自检类型： </label>
                <label v-if="selfTestData.localControl === '1' && selfTestData.remoteControl === '1'">检测81.S1</label>
                <label v-else-if="selfTestData.localControl === '0' && selfTestData.remoteControl === '1'">检测81.S2(频率保护时间0.1s)</label>
                <label v-else-if="selfTestData.localControl === '1' && selfTestData.remoteControl === '0'">检测81.S2(过频保护时间1s,欠频保护时间4s)</label>
                <label v-else>/</label>
            </el-col>
        </el-row>
        <el-row class="second-row">
            <el-col :span="24">
                <label>上报时间： </label>
                <label>{{selfTestData.reportTime}}</label>
            </el-col>
        </el-row>
        <el-row>
            <el-col :span="8">
                <el-card shadow="hover" class="card" v-bind:class="checkItemStyle.style59S1">
                    <div class="card-title"><label>十分钟过压</label></div>
                    <div class="card-item"><label>保护值设定值</label><label class="card-value">{{selfTestData.item59S1.tripLimitSet1}}</label></div>
                    <div class="card-item"><label>保护时间设定值</label><label class="card-value">{{selfTestData.item59S1.tripLimitSet2}}</label></div>
                    <div class="card-item"><label>实时值(V)</label><label class="card-value">{{selfTestData.item59S1.vac}}</label></div>
                    <div class="card-item"><label>保护值测试结果(V)</label><label class="card-value">{{selfTestData.item59S1.vacOff}}</label></div>
                    <div class="card-item"><label>保护时间测试结果(s)</label><label class="card-value">{{selfTestData.item59S1.tOff}}</label></div>
                    <div class="card-item">
                        <label>进度(%)</label>
                        <label v-if="selfTestData.item59S1.itemProcess === '3' && selfTestData.item59S1.result === '1'"
                               class="success-text-color">成功</label>
                        <label v-else-if="selfTestData.item59S1.itemProcess === '3' && selfTestData.item59S1.result === '0'"
                               class="fail-text-color">失败</label>
                        <label v-else class="card-value">-</label>
                    </div>
                </el-card>
            </el-col>
            <el-col :span="8">
                <el-card shadow="hover" class="card" v-bind:class="checkItemStyle.style59S2">
                    <div class="card-title"><label>过压最大值</label></div>
                    <div class="card-item"><label>保护值设定值</label><label class="card-value">{{selfTestData.item59S2.tripLimitSet1}}</label></div>
                    <div class="card-item"><label>保护时间设定值</label><label class="card-value">{{selfTestData.item59S2.tripLimitSet2}}</label></div>
                    <div class="card-item"><label>实时值(V)</label><label class="card-value">{{selfTestData.item59S2.vac}}</label></div>
                    <div class="card-item"><label>保护值测试结果(V)</label><label class="card-value">{{selfTestData.item59S2.vacOff}}</label></div>
                    <div class="card-item"><label>保护时间测试结果(s)</label><label class="card-value">{{selfTestData.item59S2.tOff}}</label></div>
                    <div class="card-item">
                        <label>进度(%)</label>
                        <label v-if="selfTestData.item59S2.itemProcess === '3' && selfTestData.item59S2.result === '1'"
                               class="success-text-color">成功</label>
                        <label v-else-if="selfTestData.item59S2.itemProcess === '3' && selfTestData.item59S2.result === '0'"
                               class="fail-text-color">失败</label>
                        <label v-else class="card-value">-</label>
                    </div>
                </el-card>
            </el-col>
            <el-col :span="8">
                <el-card shadow="hover" class="card" v-bind:class="checkItemStyle.style27S1">
                    <div class="card-title"><label>欠压最小值</label></div>
                    <div class="card-item"><label>保护值设定值</label><label class="card-value">{{selfTestData.item27S1.tripLimitSet1}}</label></div>
                    <div class="card-item"><label>保护时间设定值</label><label class="card-value">{{selfTestData.item27S1.tripLimitSet2}}</label></div>
                    <div class="card-item"><label>实时值(V)</label><label class="card-value">{{selfTestData.item27S1.vac}}</label></div>
                    <div class="card-item"><label>保护值测试结果(V)</label><label class="card-value">{{selfTestData.item27S1.vacOff}}</label></div>
                    <div class="card-item"><label>保护时间测试结果(s)</label><label class="card-value">{{selfTestData.item27S1.tOff}}</label></div>
                    <div class="card-item">
                        <label>进度(%)</label>
                        <label v-if="selfTestData.item27S1.itemProcess === '3' && selfTestData.item27S1.result === '1'"
                               class="success-text-color">成功</label>
                        <label v-else-if="selfTestData.item27S1.itemProcess === '3' && selfTestData.item27S1.result === '0'"
                               class="fail-text-color">失败</label>
                        <label v-else class="card-value">-</label>
                    </div>
                </el-card>
            </el-col>
        </el-row>
        <el-row>
            <el-col :span="8">
                <el-card shadow="hover" class="card" v-bind:class="checkItemStyle.style27S2">
                    <div class="card-title"><label>欠压最小值</label></div>
                    <div class="card-item"><label>保护值设定值</label><label class="card-value">{{selfTestData.item27S2.tripLimitSet1}}</label></div>
                    <div class="card-item"><label>保护时间设定值</label><label class="card-value">{{selfTestData.item27S2.tripLimitSet2}}</label></div>
                    <div class="card-item"><label>实时值(V)</label><label class="card-value">{{selfTestData.item27S2.vac}}</label></div>
                    <div class="card-item"><label>保护值测试结果(V)</label><label class="card-value">{{selfTestData.item27S2.vacOff}}</label></div>
                    <div class="card-item"><label>保护时间测试结果(s)</label><label class="card-value">{{selfTestData.item27S2.tOff}}</label></div>
                    <div class="card-item">
                        <label>进度(%)</label>
                        <label v-if="selfTestData.item27S2.itemProcess === '3' && selfTestData.item27S2.result === '1'"
                               class="success-text-color">成功</label>
                        <label v-else-if="selfTestData.item27S2.itemProcess === '3' && selfTestData.item27S2.result === '0'"
                               class="fail-text-color">失败</label>
                        <label v-else class="card-value">-</label>
                    </div>
                </el-card>
            </el-col>
            <el-col :span="8">
                <el-card shadow="hover" class="card" v-bind:class="checkItemStyle.style81Max">
                    <div><label>过频最大值</label></div>
                    <div class="card-item"><label>保护值设定值</label><label class="card-value">{{selfTestData.item81S1Or81S2Max.tripLimitSet1}}</label></div>
                    <div class="card-item"><label>保护时间设定值</label><label class="card-value">{{selfTestData.item81S1Or81S2Max.tripLimitSet2}}</label></div>
                    <div class="card-item"><label>实时值(Hz)</label><label class="card-value">{{selfTestData.item81S1Or81S2Max.vac}}</label></div>
                    <div class="card-item"><label>保护值测试结果(Hz)</label><label class="card-value">{{selfTestData.item81S1Or81S2Max.vacOff}}</label></div>
                    <div class="card-item"><label>保护时间测试结果(s)</label><label class="card-value">{{selfTestData.item81S1Or81S2Max.tOff}}</label></div>
                    <div class="card-item">
                        <label>进度(%)</label>
                        <label v-if="selfTestData.item81S1Or81S2Max.itemProcess === '3' && selfTestData.item81S1Or81S2Max.result === '1'"
                               class="success-text-color">成功</label>
                        <label v-else-if="selfTestData.item81S1Or81S2Max.itemProcess === '3' && selfTestData.item81S1Or81S2Max.result === '0'"
                               class="fail-text-color">失败</label>
                        <label v-else class="card-value">-</label>
                    </div>
                </el-card>
            </el-col>
            <el-col :span="8">
                <el-card shadow="hover" class="card" v-bind:class="checkItemStyle.style81Min">
                    <div><label>欠频最小值</label></div>
                    <div class="card-item"><label>保护值设定值</label><label class="card-value">{{selfTestData.item81S1Or81S2Min.tripLimitSet1}}</label></div>
                    <div class="card-item"><label>保护时间设定值</label><label class="card-value">{{selfTestData.item81S1Or81S2Min.tripLimitSet2}}</label></div>
                    <div class="card-item"><label>实时值(Hz)</label><label class="card-value">{{selfTestData.item81S1Or81S2Min.vac}}</label></div>
                    <div class="card-item"><label>保护值测试结果(Hz)</label><label class="card-value">{{selfTestData.item81S1Or81S2Min.vacOff}}</label></div>
                    <div class="card-item"><label>保护时间测试结果(s)</label><label class="card-value">{{selfTestData.item81S1Or81S2Min.tOff}}</label></div>
                    <div class="card-item">
                        <label>进度(%)</label>
                        <label v-if="selfTestData.item81S1Or81S2Min.itemProcess === '3' && selfTestData.item81S1Or81S2Min.result === '1'"
                               class="success-text-color">成功</label>
                        <label v-else-if="selfTestData.item81S1Or81S2Min.itemProcess === '3' && selfTestData.item81S1Or81S2Min.result === '0'"
                               class="fail-text-color">失败</label>
                        <label v-else class="card-value">-</label>
                    </div>
                </el-card>
            </el-col>
        </el-row>
    </div>
</template>

<script>

    import { queryDeviceSelfTestHisInfoBySn } from '@/api/esaio/device/esaioDeviceInfo';
    import Global from '@/components/Global';

    export default {

        name: 'ips-test-report',
        props: {

            deviceInfo: {

                type: Object
            },
        },
        data() {

            return {

                checkItemStyle: {

                    style59S1: {

                        'un-start-container': true,
                        'checking-container': false,
                        'pass-container': false,
                        'fail-container': false
                    },
                    style59S2: {

                        'un-start-container': true,
                        'checking-container': false,
                        'pass-container': false,
                        'fail-container': false
                    },
                    style27S1: {

                        'un-start-container': true,
                        'checking-container': false,
                        'pass-container': false,
                        'fail-container': false
                    },
                    style27S2: {

                        'un-start-container': true,
                        'checking-container': false,
                        'pass-container': false,
                        'fail-container': false
                    },
                    style81Max: {

                        'un-start-container': true,
                        'checking-container': false,
                        'pass-container': false,
                        'fail-container': false
                    },
                    style81Min: {

                        'un-start-container': true,
                        'checking-container': false,
                        'pass-container': false,
                        'fail-container': false
                    }
                },
                selfTestData: {

                    item59S1: {

                        checkTotalCount: 0,
                        currentCheckCount: 0,
                        itemProcess: '-',
                        result: '-',
                        tOff: '-',
                        tripLimitSet1: '-',
                        tripLimitSet2: '-',
                        vac: '-',
                        vacOff: '-'
                    },
                    item59S2: {

                        checkTotalCount: 0,
                        currentCheckCount: 0,
                        itemProcess: '-',
                        result: '-',
                        tOff: '-',
                        tripLimitSet1: '-',
                        tripLimitSet2: '-',
                        vac: '-',
                        vacOff: '-'
                    },
                    item27S1: {

                        checkTotalCount: 0,
                        currentCheckCount: 0,
                        itemProcess: '-',
                        result: '-',
                        tOff: '-',
                        tripLimitSet1: '-',
                        tripLimitSet2: '-',
                        vac: '-',
                        vacOff: '-'
                    },
                    item27S2: {

                        checkTotalCount: 0,
                        currentCheckCount: 0,
                        itemProcess: '-',
                        result: '-',
                        tOff: '-',
                        tripLimitSet1: '-',
                        tripLimitSet2: '-',
                        vac: '-',
                        vacOff: '-'
                    },
                    item81S1Or81S2Max: {

                        checkTotalCount: 0,
                        currentCheckCount: 0,
                        itemProcess: '-',
                        result: '-',
                        tOff: '-',
                        tripLimitSet1: '-',
                        tripLimitSet2: '-',
                        vac: '-',
                        vacOff: '-'
                    },
                    item81S1Or81S2Min: {

                        checkTotalCount: 0,
                        currentCheckCount: 0,
                        itemProcess: '-',
                        result: '-',
                        tOff: '-',
                        tripLimitSet1: '-',
                        tripLimitSet2: '-',
                        vac: '-',
                        vacOff: '-'
                    },
                    localControl: 1,
                    reportTime: '-',
                    selfTestProgress: 0,
                    selfTestStatus: 0
                },
            };
        },
        methods: {

            queryDeviceSelfTestHisInfoBySn() {

                let param = {

                    sn: this.deviceInfo.serialNumber
                };
                queryDeviceSelfTestHisInfoBySn(param).then((res) => {

                    if (res.code === Global.response_status_success_obj) {

                        let data = res.data;
                        if (data != null) {

                            this.selfTestData = data;
                            this.selfTestData.reportTime = data.reportTime;
                            if (data.item59S1 != null) {

                                this.selfTestData.item59S1 = data.item59S1;
                            } else {

                                this.selfTestData.item59S1 = {

                                    checkTotalCount: 0,
                                    currentCheckCount: 0,
                                    itemProcess: '-',
                                    result: '-',
                                    tOff: '-',
                                    tripLimitSet1: '-',
                                    tripLimitSet2: '-',
                                    vac: '-',
                                    vacOff: '-'
                                };
                            }
                            if (data.item59S2 != null) {

                                this.selfTestData.item59S2 = data.item59S2;
                            } else {

                                this.selfTestData.item59S2 = {

                                    checkTotalCount: 0,
                                    currentCheckCount: 0,
                                    itemProcess: '-',
                                    result: '-',
                                    tOff: '-',
                                    tripLimitSet1: '-',
                                    tripLimitSet2: '-',
                                    vac: '-',
                                    vacOff: '-'
                                };
                            }
                            if (data.item27S1 != null) {

                                this.selfTestData.item27S1 = data.item27S1;
                            } else {

                                this.selfTestData.item27S1 = {

                                    checkTotalCount: 0,
                                    currentCheckCount: 0,
                                    itemProcess: '-',
                                    result: '-',
                                    tOff: '-',
                                    tripLimitSet1: '-',
                                    tripLimitSet2: '-',
                                    vac: '-',
                                    vacOff: '-'
                                };
                            }
                            if (data.item27S2 != null) {

                                this.selfTestData.item27S2 = data.item27S2;
                            } else {

                                this.selfTestData.item27S2 = {

                                    checkTotalCount: 0,
                                    currentCheckCount: 0,
                                    itemProcess: '-',
                                    result: '-',
                                    tOff: '-',
                                    tripLimitSet1: '-',
                                    tripLimitSet2: '-',
                                    vac: '-',
                                    vacOff: '-'
                                };
                            }
                            if (data.item81S1Or81S2Max != null) {

                                this.selfTestData.item81S1Or81S2Max = data.item81S1Or81S2Max;
                            } else {

                                this.selfTestData.item81S1Or81S2Max = {

                                    checkTotalCount: 0,
                                    currentCheckCount: 0,
                                    itemProcess: '-',
                                    result: '-',
                                    tOff: '-',
                                    tripLimitSet1: '-',
                                    tripLimitSet2: '-',
                                    vac: '-',
                                    vacOff: '-'
                                };
                            }
                            if (data.item81S1Or81S2Min != null) {

                                this.selfTestData.item81S1Or81S2Min = data.item81S1Or81S2Min;
                            } else {

                                this.selfTestData.item81S1Or81S2Min = {

                                    checkTotalCount: 0,
                                    currentCheckCount: 0,
                                    itemProcess: '-',
                                    result: '-',
                                    tOff: '-',
                                    tripLimitSet1: '-',
                                    tripLimitSet2: '-',
                                    vac: '-',
                                    vacOff: '-'
                                };
                            }
                            this.messageDataHandler();
                        }
                    } else {

                        message.error(res.msg);
                    }
                }).catch(err => console.error(err));
            },
            messageDataHandler() {

                // 检测项样式处理
                this.checkItemStyleHandler('item59S1', 'style59S1');
                this.checkItemStyleHandler('item59S2', 'style59S2');
                this.checkItemStyleHandler('item27S1', 'style27S1');
                this.checkItemStyleHandler('item27S2', 'style27S2');
                this.checkItemStyleHandler('item81S1Or81S2Max', 'style81Max');
                this.checkItemStyleHandler('item81S1Or81S2Min', 'style81Min');
            },
            checkItemStyleHandler(itemDataName, itemStyleName) {

                let itemData = this.selfTestData[itemDataName];
                if (itemData.currentCheckCount > 0) {

                    this.checkItemStyle[itemStyleName] = {

                        'un-start-container': false,
                        'checking-container': true,
                        'pass-container': false,
                        'fail-container': false
                    };
                } else if (itemData.currentCheckCount === 0 && itemData.result === 1) {

                    this.checkItemStyle[itemStyleName] = {

                        'un-start-container': false,
                        'checking-container': false,
                        'pass-container': true,
                        'fail-container': false
                    };
                } else if (itemData.currentCheckCount === 0 && itemData.result === 0) {

                    this.checkItemStyle[itemStyleName] = {

                        'un-start-container': false,
                        'checking-container': false,
                        'pass-container': false,
                        'fail-container': true
                    };
                } else if (itemData.currentCheckCount === 0 && itemData.checkTotalCount === 0) {

                    this.checkItemStyle[itemStyleName] = {

                        'un-start-container': true,
                        'checking-container': false,
                        'pass-container': false,
                        'fail-container': false
                    };
                }
            }
        }
    }
</script>

<style lang="scss" scoped>

    .card {

        width: 250px;
        height: 200px;
        margin: 0 0 20px 0;
    }

    .card-title {

        font-weight: bold;
    }

    .card-item {

        margin: 5px 0 0 0;
    }

    .card-value {

        float: right;
    }

    .top-row {

        margin-top: -20px;
    }

    .second-row {

        margin: 10px 0 10px 0;
    }

    .un-start-container {

        border: 1px solid #CCCCCC;
    }

    .checking-container {

        border: 1px solid #009DFF;
    }

    .pass-container {

        border: 1px solid limegreen;
    }

    .fail-container {

        border: 1px solid red;
    }

    .success-text-color {

        color: limegreen;
        float: right;
    }

    .fail-text-color {

        color: red;
        float: right;
    }
</style>
