import { render, staticRenderFns } from "./forced-charge-setting.vue?vue&type=template&id=1822d737&scoped=true&"
import script from "./forced-charge-setting.vue?vue&type=script&lang=js&"
export * from "./forced-charge-setting.vue?vue&type=script&lang=js&"
import style0 from "./forced-charge-setting.vue?vue&type=style&index=0&id=1822d737&lang=scss&scoped=true&"


/* normalize component */
import normalizer from "!../../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "1822d737",
  null
  
)

export default component.exports