<template>
    <el-dialog title="储能设备信息详情" :visible.sync="deviceDataDetailPageVisible" top="0"
           custom-class="option-dialog-style" @close="closeDeviceDataDetailPage" :close-on-click-modal="false" :destroy-on-close=true>
        <el-tabs v-model="activeName" tab-position="left" ref="tabs" class="module-tabs-style" @tab-click="handleTabChangeEvent">
            <el-tab-pane v-for="(tab) in commandInfoList" :label="tab.commandName" :name="tab.commandName" :commandCode="tab.commandCode">
                <div v-if="tab.commandCode === 'important_monitor'">
                    <important-monitor-data :deviceInfo="deviceInfo" ref="importantMonitorDataPage"></important-monitor-data>
                </div>
                <div v-else-if="tab.commandCode === 'fault_event'">
                    <alarm-data :deviceInfo="deviceInfo" ref="alarmDataPage"></alarm-data>
                </div>
                <div v-else>
                    <div v-if="showSpecialContent(tab)" style="margin: 0 0 10px 0">
                        <el-button size="mini" type="primary" @click="openCompareFieldDetailPage(tab)">对比</el-button>
                        <span class="report-time">上报时间(中国)：{{ lastReportTime }}</span>
                    </div>
                    <el-table :ref="getTableRef(tab)" :data="realDataVoList" max-height="680" size="small" stripe border
                              @selection-change="handleSelection"
                              :header-cell-style="{background: '#eef1f6', color: '#606266'}"
                              :cell-class-name="getCellClassName"
                              :header-cell-class-name="getHeaderCellClass">
                        <el-table-column type="selection" align="center" width="50" :selectable="handleSelectableEvent" v-if="showSpecialContent(tab)"/>
                        <el-table-column type="index" label="序号" align="center" width="50"/>
                        <el-table-column prop="fieldCn" label="中文含义" align="left"/>
                        <el-table-column prop="fieldEn" label="英文含义" align="left" v-if="showSpecialContent(tab)"/>
                        <el-table-column prop="value" label="数值" align="center" />
                        <el-table-column prop="unit" label="单位" align="center" width="50" v-if="showSpecialContent(tab)"/>
                        <el-table-column prop="dataShouldReportCount" label="应上报次数" align="center" width="85" v-if="showSpecialContent(tab)"/>
                        <el-table-column prop="dataActualReportCount" label="实际上报次数" align="center" width="95" v-if="showSpecialContent(tab)"/>
                        <el-table-column prop="commSuccessRate" label="通讯成功率" align="center" width="85" v-if="showSpecialContent(tab)"/>
                        <el-table-column prop="state" label="状态" align="center" width="70" :formatter="stateFormatter" v-if="showSpecialContent(tab)"/>
                        <el-table-column label="操作" align="center" width="80" v-if="showSpecialContent(tab)">
                            <template slot-scope="scope">
                                <div class="statistics">
                                    <el-tooltip v-delTabIndex effect="dark" content="统计" placement="top">
                                        <i class="statistics-btn option-btn-normal" @click="openFieldDetailPage(scope.row, tab)"/>
                                    </el-tooltip>
                                </div>
                            </template>
                        </el-table-column>
                    </el-table>
                    <el-dialog width="1220px" top="0" :visible.sync="tab.lineChartDialogVisible" :close-on-click-modal="false"
                           append-to-body :before-close="closeLineChartDialog">
                        <div class="dateRange">
                            <el-date-picker v-model="reportTimeRange" type="daterange" value-format="yyyy-MM-dd"
                                            start-placeholder="开始日期" end-placeholder="结束日期"
                                            @change="handleReportTimeRangeChangeEvent" :picker-options="pickerOptions">
                            </el-date-picker>
                        </div>
                        <el-row class="successRateTitle">
                            通讯成功率
                        </el-row>
                        <el-row>
                            <el-form class="successRateForm" :inline="true" size="mini">
                                <el-form-item class="successRateItem" v-for="item in commSuccessRateDataList" :key="item.fieldCn" :label="item.fieldCn + '：'">
                                    {{item.commSuccessRate}}%
                                </el-form-item>
                            </el-form>
                        </el-row>
                        <div v-show="haveData" class="have-data">暂无数据</div>
                        <div :id="getLineChartId(tab)" style="width: 1200px; height: 500px; margin: 15px 0 0 5px;"></div>
                    </el-dialog>
                </div>
            </el-tab-pane>
        </el-tabs>
    </el-dialog>
</template>

<script>
    import {
        queryDeviceDataCommandFieldChart,
        queryDeviceDataCommandFieldValueList,
        queryDeviceDataCommandList
    } from '@/api/esaio/device/esaioDeviceInfo';
    import Global from '@/components/Global';
    import DateUtil from '@/utils/dateUtil';
    import message from '@/utils/message';
    import AlarmData from '@/views/esaio/device/device-detail/alarm-data.vue';
    import ImportantMonitorData from '@/views/esaio/device/device-detail/important-monitor-data.vue';

    export default {

        name: 'device-data-detail',
        components: {AlarmData, ImportantMonitorData},
        data() {

            let self = this;
            return {

                deviceInfo: null,
                eCharts: require('echarts'),
                lastReportTime: '--',
                commandInfoList: [],
                realDataVoList: [],
                activeName: '',
                deviceDataDetailPageVisible: false,
                option: {

                    legendArray: [],
                    xAxis: [],
                    yAxis: {

                        type: 'value',
                    },
                    series: []
                },
                lineChartSearchForm: {

                    deviceSn: '',
                    commandCode: '',
                    fieldList: [],
                    startDate: '',
                    endDate: '',
                },
                firstSelectDate: '',
                reportTimeRange: [],
                pickerOptions: {

                    onPick({maxDate, minDate}) {

                        if (minDate && !maxDate) {

                            self.firstSelectDate = minDate.getTime();
                        }
                        if (maxDate) {

                            self.firstSelectDate = '';
                        }
                    },
                    // 只允许查询7天范围的数据
                    disabledDate(time) {

                        if (self.firstSelectDate) {

                            let timeRange = 6 * 24 * 60 * 60 * 1000;
                            return time.getTime() > self.firstSelectDate + timeRange ||
                                time.getTime() < self.firstSelectDate - timeRange || time.getTime() > Date.now();
                        } else {

                            return time.getTime() > Date.now();
                        }
                    }
                },
                haveData: false,
                row: {},
                dataFieldEnum: {

                },
                commSuccessRateDataList: []
            }
        },
        methods: {

            initDeviceDetailPage(row) {

                this.deviceDataDetailPageVisible = true;
                this.deviceInfo = row;
                this.queryDeviceDataCommandList();
                this.lineChartSearchForm.deviceSn = row.serialNumber;
            },
            queryDeviceDataCommandList() {

                let param = {

                    sn: this.deviceInfo.serialNumber,
                }
                queryDeviceDataCommandList(param).then(res => {

                    if (res.code === Global.response_status_success_obj) {

                        this.commandInfoList = res.data;
                        if (this.commandInfoList && this.commandInfoList.length > 0) {

                            // 设置默认展示的tab页面
                            this.activeName = this.commandInfoList[0].commandName;
                            // 加载列表数据
                            this.loadRealData(this.deviceInfo.serialNumber, this.commandInfoList[0].commandCode);
                            this.lineChartSearchForm.commandCode = this.commandInfoList[0].commandCode;
                        }
                    } else {

                        message.error(res.msg);
                    }
                }).catch(err => {

                    message.error('请求失败,请稍后再试');
                    console.error(err);
                });
            },
            handleTabChangeEvent(tab) {

                if (tab.$attrs.commandCode === 'important_monitor') {

                    this.$refs.importantMonitorDataPage[0].initPage();
                } else if (tab.$attrs.commandCode === 'fault_event') {

                    this.$refs.alarmDataPage[0].initPage();
                } else {

                    this.loadRealData(this.deviceInfo.serialNumber, tab.$attrs.commandCode);
                    this.lineChartSearchForm.commandCode = tab.$attrs.commandCode;
                }
            },
            handleReportTimeRangeChangeEvent() {

                this.queryDataCommandFieldChart(this.row);
            },
            // 加载指令实时数据
            loadRealData(deviceNum, commandCode) {

                let param = {

                    deviceSn: deviceNum,
                    commandCode: commandCode
                };
                queryDeviceDataCommandFieldValueList(param).then(res => {

                    if (res.code === Global.response_status_success_obj) {

                        res.data.fieldList.forEach((item) => {

                            item.selectionEnable = true;
                        });
                        this.realDataVoList = res.data.fieldList;
                        if (res.data.reportTime != null) {

                            this.lastReportTime = res.data.reportTime;
                        } else {

                            this.lastReportTime = '--';
                        }
                    } else {

                        message.error(res.msg);
                    }
                }).catch(err => {

                    message.error('请求失败,请稍后再试');
                    console.error(err);
                })
            },
            closeDeviceDataDetailPage() {

                this.$refs.tabs.$destroy();
                this.deviceDataDetailPageVisible = false;
                this.$emit('queryEsaioDeviceInfoList');
            },
            closeLineChartDialog(done) {

                this.reportTimeRange = [DateUtil.getDateStr(new Date()), DateUtil.getDateStr(new Date())];
                this.eCharts.init(document.getElementById('lineChart_' + this.lineChartSearchForm.commandCode)).dispose();
                done();
            },
            openCompareFieldDetailPage(tab) {

                tab.lineChartDialogVisible = true;
                let length = eval('this.$refs.table_' + this.lineChartSearchForm.commandCode)[0].selection.length;
                if (length < 2) {

                    this.$message.error('对比不能少于两条数据');
                    return;
                }
                this.reportTimeRange = [DateUtil.getDateStr(new Date()), DateUtil.getDateStr(new Date())];
                this.queryDataCommandFieldChart(null);
            },
            // 查看折线图
            openFieldDetailPage(row, tab) {

                tab.lineChartDialogVisible = true;
                this.reportTimeRange = [DateUtil.getDateStr(new Date()), DateUtil.getDateStr(new Date())];
                this.queryDataCommandFieldChart(row);
            },
            // 查询指令字段报表
            queryDataCommandFieldChart(row) {

                let fieldMap = new Map();
                let unitMap = new Map();
                let fieldCnList = [];
                this.row = null;
                this.lineChartSearchForm.fieldList = [];
                this.lineChartSearchForm.startDate = this.reportTimeRange[0];
                this.lineChartSearchForm.endDate = this.reportTimeRange[1];
                if (row === null) {

                    // 多选比较
                    let data = eval('this.$refs.table_' + this.lineChartSearchForm.commandCode)[0].selection;
                    for (let index = 0; index < data.length; index++) {

                        let fieldEn = data[index].fieldEn;
                        let fieldCn = data[index].fieldCn;
                        let unit = data[index].unit;
                        fieldMap.set(fieldEn, fieldCn);
                        unitMap.set(fieldEn, unit);
                        fieldCnList[index] = fieldCn;
                        this.lineChartSearchForm.fieldList[index] = fieldEn;
                    }
                } else {

                    // 某一行
                    this.row = row;
                    fieldMap.set(row.fieldEn, row.fieldCn);
                    unitMap.set(row.fieldEn, row.unit);
                    fieldCnList = [row.fieldCn];
                    this.lineChartSearchForm.fieldList = [row.fieldEn];
                }
                this.option.legendArray = fieldCnList;
                // 查询指令字段数据
                queryDeviceDataCommandFieldChart(this.lineChartSearchForm).then(res => {

                    if (res.code === Global.response_status_success_obj) {
                        // 计算点数据表中的通讯成功率
                        this.calLineChartCommSuccessRate(res.data.valueMap, fieldMap);
                        this.option.xAxis = res.data.reportTimeList;
                        this.option.series = [];
                        let valueMap = res.data.valueMap;
                        this.dataFieldEnum = res.data.enumsMap;
                        let i = 0;
                        this.haveData = res.data.reportTimeList.length === 0;
                        for (let key in valueMap) {

                            this.option.series[i] = {};
                            this.option.series[i].name = fieldMap.get(key);
                            this.option.series[i].type = 'line';
                            this.option.series[i].emphasis = {};
                            this.option.series[i].emphasis.focus = 'series';
                            this.option.series[i]['data'] = [];
                            this.option.series[i]['unit'] = {};
                            this.option.series[i]['unit'] = unitMap.get(key);
                            this.option.series[i]['showSymbol'] = false;
                            this.option.series[i]['data'] = valueMap[key];
                            i++;
                        }
                        let that = this;
                        if (that.row !== null && that.dataFieldEnum != null && that.dataFieldEnum[that.row.fieldEn] != null) {

                            let enums = that.dataFieldEnum[that.row.fieldEn];
                            let yAxisData = Object.keys(enums);
                            let interval;
                            if (yAxisData.length > 1) {

                                interval = yAxisData[yAxisData.length - 1] - yAxisData[yAxisData.length - 2];
                            } else {

                                interval = yAxisData.length > 0 ? yAxisData[0] : 1;
                            }
                            let max = yAxisData.length > 0 ? yAxisData[yAxisData.length - 1] : 1;
                            that.option.yAxis = {

                                data: yAxisData,
                                max: max,
                                interval: interval,
                                type: 'value',
                                axisLabel: {

                                    show: true,
                                    formatter: function(value) {

                                        return [that.dataFieldEnum[that.row.fieldEn][value]];
                                    }
                                }
                            };
                        } else {

                            that.option.yAxis = {

                                type: 'value',
                                axisLabel: {

                                    show: true,
                                    formatter: function(value) {

                                        return [value];
                                    }
                                }
                            };
                        }
                        this.drawChart();
                    } else {

                        message.error(res.msg);
                    }
                }).catch(err => {

                    message.error('请求失败,请稍后再试');
                    console.error(err);
                });
            },
            drawChart() {

                let myChart = this.eCharts.init(document.getElementById('lineChart_' + this.lineChartSearchForm.commandCode));
                // 指定图表的配置项和数据
                let option = {

                    title: {

                        text: '点数据表',
                        textStyle: {

                            color: '#343434',
                            fontSize: 15
                        }
                    },
                    tooltip: {

                        trigger: 'axis',
                        axisPointer: {

                            type: 'cross',
                            label: {

                                backgroundColor: '#6a7985'
                            }
                        },
                        formatter:(params) => {

                            let relVal = params[0].name;
                            for (let i = 0, l = params.length; i < l; i++) {

                                for (let index = 0; index < this.option.series.length; index++) {

                                    if (this.option.series[index].name === params[i].seriesName) {

                                        let val = '--';
                                        if (params[i].value !== '') {

                                            val = params[i].value;
                                        }
                                        relVal = relVal + '<br/>' + params[i].seriesName + ' : ' + val;
                                        if (this.option.series[index].unit !== '--') {

                                            relVal = relVal + ' ' + this.option.series[index].unit;
                                        }
                                    }
                                }
                            }
                            return relVal;
                        }
                    },
                    legend: {

                        data: this.option.legendArray,
                        top: 25
                    },
                    grid: {

                        left: '3%',
                        right: '6%',
                        bottom: '3%',
                        containLabel: true
                    },
                    xAxis: [
                        {
                            type: 'category',
                            data: this.option.xAxis
                        }
                    ],
                    yAxis: [

                        this.option.yAxis
                    ],
                    series: this.option.series,
                    dataZoom: [
                        {
                            type: 'inside',
                            xAxisIndex: 0,
                            start: 0,
                            end: 100
                        }
                    ]
                };
                // 使用刚指定的配置项和数据显示图表。
                myChart.setOption(option);
            },
            stateFormatter(row) {

                if (row.status === '0') {

                    return <span style="color: #67C23A;">正常</span>
                } else if (row.status === '1') {

                    return <span style="color: #F56C6C;">超时</span>;
                } else {

                    return '--';
                }
            },
            // 获取表格引用名称
            getTableRef(tab) {

                return 'table_' + tab.commandCode;
            },
            // 获取折线图的id
            getLineChartId(tab) {

                return 'lineChart_' + tab.commandCode;
            },
            getCellClassName(row) {

                return row.row.comparable === false ? 'disabledCheck' : '';
            },
            getHeaderCellClass(row) {

                return row.columnIndex === 0 ? 'disabledCheck' : '';
            },
            handleSelectableEvent(row) {

                return row.selectionEnable;
            },
            showSpecialContent(tab) {

                return tab.commandCode !== 'base_info';
            },
            handleSelection(selectionArr) {

                if (selectionArr.length >= 10) {

                    let fieldEnList = [];
                    for (let i = 0; i < selectionArr.length; i++) {

                        fieldEnList[i] = selectionArr[i].fieldEn;
                    }
                    // 把未选中的checkbox禁用
                    this.realDataVoList.forEach((item) => {

                        if (!fieldEnList.includes(item.fieldEn)) {

                            item.selectionEnable = false;
                        }
                    });
                } else {

                    // checkbox全部启用
                    this.realDataVoList.forEach(item => item.selectionEnable = true)
                }
            },
            /**
             * 计算点数据表中的通讯成功率.
             * @param valueMap 点数据
             * @param fieldMap 点表中英文Map
             */
            calLineChartCommSuccessRate(valueMap, fieldMap) {
                // 开始时间0点
                let startTime = new Date(this.reportTimeRange[0] + 'T00:00:00');
                // 结束时间
                let endTime = new Date(this.reportTimeRange[1] + 'T23:59:59');
                let now = new Date();
                if (endTime.getDate() === now.getDate() &&
                    endTime.getMonth() === now.getMonth() &&
                    endTime.getFullYear() === now.getFullYear()) {
                    // 结束日期如果是当天则用当前时间
                    endTime = now;
                }
                // 计算隔了多少个5分钟
                let fiveMinuteIntervals = Math.floor((endTime - startTime) / (5 * 60 * 1000));
                // 应上报数据笔数 = 间隔5分钟数 + 1
                let shouldReportCount = fiveMinuteIntervals + 1;
                this.commSuccessRateDataList = [];
                // mode不展示
                delete valueMap['mode'];
                for (let key in valueMap) {

                    let list = valueMap[key];
                    let dataActualReportCount = list.filter(item => item !== '').length;
                    let successRate = (dataActualReportCount / shouldReportCount) * 100;
                    this.commSuccessRateDataList.push({

                        fieldCn: fieldMap.get(key),
                        commSuccessRate: successRate.toFixed(2)
                    });
                    this.commSuccessRateDataList.sort((a, b) => b.commSuccessRate - a.commSuccessRate);
                }
            },
            /**
             * 进度条颜色.
             */
            progressColor(percentage) {

                if (percentage < 50) {

                    return '#F56C6C';
                } else if (percentage < 80) {

                    return '#e6a23c';
                } else {

                    return '#67c23a';
                }
            }
        }
    }
</script>

<style lang="scss" scoped>

    .report-time {

        right: 10px;
        position: absolute;
        text-align: right;
    }
    .have-data {

        position: absolute;
        top: 400px;
        left: 550px;
    }

    ::v-deep .el-table .disabledCheck .cell .el-checkbox__inner {

        display: none !important;
    }

    ::v-deep .el-dialog.option-dialog-style {

        width: 1250px;
        height: 830px;
    }

    ::v-deep .el-form-item__label {

        color: #343434;
        padding-right: 0;
    }

    .module-tabs-style {

        height: 720px;
    }

    .statistics {

        display: flex;
        justify-content: space-around;
        .option-btn-normal {

            font-size: 18px;
            color: #409EFF;
            cursor: pointer;
        }
        .statistics-btn {

            height: 18px;
            width: 18px;
            background-image: url('../../../../assets/icon/button/statistics.svg');
            background-size: cover;
            background-repeat: no-repeat;
        }
    }

    .dateRange {

        text-align: right;
    }
    .successRateTitle {

        color: #343434;
        font-weight: bold;
        margin-left: 11px;
        font-size: 15px;
    }
    .successRateForm {

        margin-top: 20px;

        .successRateItem {

            margin-left: 11px;
        }
    }
</style>
