import axios from 'axios';
import global from '../../../components/Global.vue';

let esaioBase = global.esaioServerBase;

// 查询实时告警数据
export const queryRealEventRecordList = params => { return axios.post(esaioBase + '/esaioEventRecord/queryRealEventRecordList', params)};
// 查询历史告警数据
export const queryEventHistoryRecordList = params => { return axios.post(esaioBase + '/esaioEventRecord/queryEventHistoryRecordList', params)};
// 查询时间线告警数据
export const queryDeviceEventHistoryRecordList = params => { return axios.post(esaioBase + '/esaioEventRecord/queryDeviceEventHistoryRecordList', params)};
// 查询产品型号列表
export const queryProductModeList = () => { return axios.post(esaioBase + '/esaioEventRecord/queryProductModeList')};
// 查询告警分类列表
export const queryEventClassifyList = () => { return axios.post(esaioBase + '/esaioEventRecord/queryEventClassifyList')};
// 查询告警记录数据
export const queryEventRecordList = params => {return axios.post(esaioBase + '/esaioEventRecord/queryEventRecordList', params)};