<template>
    <div>
        <div class="content">
            <el-row>
                <el-col :span="24">
                    <el-form :inline="true">
                        <el-form-item label="自检类型：">
                            <el-select class="item" @change="changeSelfTestType" v-model="selfTestTypeIndex" placeholder="请选择调节方式">
                                <el-option v-for="item in selfTestTypeArray" :key="item.id" :label="item.name" :value="item.id">
                                </el-option>
                            </el-select>
                        </el-form-item>
                    </el-form>
                </el-col>
            </el-row>
            <el-row>
                <el-col :span="8">
                    <el-card shadow="hover" class="card" v-bind:class="checkItemStyle.style59S1">
                        <div class="card-title"><label>十分钟过压</label></div>
                        <div class="card-item"><label>保护值设定值</label><label class="card-value">{{selfTestData.item59S1.tripLimitSet1}}</label></div>
                        <div class="card-item"><label>保护时间设定值</label><label class="card-value">{{selfTestData.item59S1.tripLimitSet2}}</label></div>
                        <div class="card-item"><label>实时值(V)</label><label class="card-value">{{selfTestData.item59S1.vac}}</label></div>
                        <div class="card-item"><label>保护值测试结果(V)</label><label class="card-value">{{selfTestData.item59S1.vacOff}}</label></div>
                        <div class="card-item"><label>保护时间测试结果(s)</label><label class="card-value">{{selfTestData.item59S1.tOff}}</label></div>
                        <div class="card-item">
                            <label>进度(%)</label>
                            <label v-if="selfTestData.item59S1.itemProcess === '成功'" class="success-text-color">{{selfTestData.item59S1.itemProcess}}</label>
                            <label v-else-if="selfTestData.item59S1.itemProcess === '失败'" class="fail-text-color">{{selfTestData.item59S1.itemProcess}}</label>
                            <label v-else class="card-value">{{selfTestData.item59S1.itemProcess}}</label>
                        </div>
                    </el-card>
                </el-col>
                <el-col :span="8">
                    <el-card shadow="hover" class="card" v-bind:class="checkItemStyle.style59S2">
                        <div class="card-title"><label>过压最大值</label></div>
                        <div class="card-item"><label>保护值设定值</label><label class="card-value">{{selfTestData.item59S2.tripLimitSet1}}</label></div>
                        <div class="card-item"><label>保护时间设定值</label><label class="card-value">{{selfTestData.item59S2.tripLimitSet2}}</label></div>
                        <div class="card-item"><label>实时值(V)</label><label class="card-value">{{selfTestData.item59S2.vac}}</label></div>
                        <div class="card-item"><label>保护值测试结果(V)</label><label class="card-value">{{selfTestData.item59S2.vacOff}}</label></div>
                        <div class="card-item"><label>保护时间测试结果(s)</label><label class="card-value">{{selfTestData.item59S2.tOff}}</label></div>
                        <div class="card-item">
                            <label>进度(%)</label>
                            <label v-if="selfTestData.item59S2.itemProcess === '成功'" class="success-text-color">{{selfTestData.item59S2.itemProcess}}</label>
                            <label v-else-if="selfTestData.item59S2.itemProcess === '失败'" class="fail-text-color">{{selfTestData.item59S2.itemProcess}}</label>
                            <label v-else class="card-value">{{selfTestData.item59S2.itemProcess}}</label>
                        </div>
                    </el-card>
                </el-col>
                <el-col :span="8">
                    <el-card shadow="hover" class="card" v-bind:class="checkItemStyle.style27S1">
                        <div class="card-title"><label>欠压最小值</label></div>
                        <div class="card-item"><label>保护值设定值</label><label class="card-value">{{selfTestData.item27S1.tripLimitSet1}}</label></div>
                        <div class="card-item"><label>保护时间设定值</label><label class="card-value">{{selfTestData.item27S1.tripLimitSet2}}</label></div>
                        <div class="card-item"><label>实时值(V)</label><label class="card-value">{{selfTestData.item27S1.vac}}</label></div>
                        <div class="card-item"><label>保护值测试结果(V)</label><label class="card-value">{{selfTestData.item27S1.vacOff}}</label></div>
                        <div class="card-item"><label>保护时间测试结果(s)</label><label class="card-value">{{selfTestData.item27S1.tOff}}</label></div>
                        <div class="card-item">
                            <label>进度(%)</label>
                            <label v-if="selfTestData.item27S1.itemProcess === '成功'" class="success-text-color">{{selfTestData.item27S1.itemProcess}}</label>
                            <label v-else-if="selfTestData.item27S1.itemProcess === '失败'" class="fail-text-color">{{selfTestData.item27S1.itemProcess}}</label>
                            <label v-else class="card-value">{{selfTestData.item27S1.itemProcess}}</label>
                        </div>
                    </el-card>
                </el-col>
            </el-row>
            <el-row>
                <el-col :span="8">
                    <el-card shadow="hover" class="card" v-bind:class="checkItemStyle.style27S2">
                        <div class="card-title"><label>欠压最小值</label></div>
                        <div class="card-item"><label>保护值设定值</label><label class="card-value">{{selfTestData.item27S2.tripLimitSet1}}</label></div>
                        <div class="card-item"><label>保护时间设定值</label><label class="card-value">{{selfTestData.item27S2.tripLimitSet2}}</label></div>
                        <div class="card-item"><label>实时值(V)</label><label class="card-value">{{selfTestData.item27S2.vac}}</label></div>
                        <div class="card-item"><label>保护值测试结果(V)</label><label class="card-value">{{selfTestData.item27S2.vacOff}}</label></div>
                        <div class="card-item"><label>保护时间测试结果(s)</label><label class="card-value">{{selfTestData.item27S2.tOff}}</label></div>
                        <div class="card-item">
                            <label>进度(%)</label>
                            <label v-if="selfTestData.item27S2.itemProcess === '成功'" class="success-text-color">{{selfTestData.item27S2.itemProcess}}</label>
                            <label v-else-if="selfTestData.item27S2.itemProcess === '失败'" class="fail-text-color">{{selfTestData.item27S2.itemProcess}}</label>
                            <label v-else class="card-value">{{selfTestData.item27S2.itemProcess}}</label>
                        </div>
                    </el-card>
                </el-col>
                <el-col :span="8">
                    <el-card shadow="hover" class="card" v-bind:class="checkItemStyle.style81Max">
                        <div v-if="localControl === '1' && remoteControl === '1'" class="card-title"><label>过频最大值</label></div>
                        <div v-else class="card-title"><label>过频最大值</label></div>
                        <div class="card-item"><label>保护值设定值</label><label class="card-value">{{selfTestData.item81S1Or81S2Max.tripLimitSet1}}</label></div>
                        <div class="card-item"><label>保护时间设定值</label><label class="card-value">{{selfTestData.item81S1Or81S2Max.tripLimitSet2}}</label></div>
                        <div class="card-item"><label>实时值(Hz)</label><label class="card-value">{{selfTestData.item81S1Or81S2Max.vac}}</label></div>
                        <div class="card-item"><label>保护值测试结果(Hz)</label><label class="card-value">{{selfTestData.item81S1Or81S2Max.vacOff}}</label></div>
                        <div class="card-item"><label>保护时间测试结果(s)</label><label class="card-value">{{selfTestData.item81S1Or81S2Max.tOff}}</label></div>
                        <div class="card-item">
                            <label>进度(%)</label>
                            <label v-if="selfTestData.item81S1Or81S2Max.itemProcess === '成功'" class="success-text-color">{{selfTestData.item81S1Or81S2Max.itemProcess}}</label>
                            <label v-else-if="selfTestData.item81S1Or81S2Max.itemProcess === '失败'" class="fail-text-color">{{selfTestData.item81S1Or81S2Max.itemProcess}}</label>
                            <label v-else class="card-value">{{selfTestData.item81S1Or81S2Max.itemProcess}}</label>
                        </div>
                    </el-card>
                </el-col>
                <el-col :span="8">
                    <el-card shadow="hover" class="card" v-bind:class="checkItemStyle.style81Min">
                        <div v-if="localControl === '1' && remoteControl === '1'" class="card-title"><label>欠频最小值</label></div>
                        <div v-else class="card-title"><label>欠频最小值</label></div>
                        <div class="card-item"><label>保护值设定值</label><label class="card-value">{{selfTestData.item81S1Or81S2Min.tripLimitSet1}}</label></div>
                        <div class="card-item"><label>保护时间设定值</label><label class="card-value">{{selfTestData.item81S1Or81S2Min.tripLimitSet2}}</label></div>
                        <div class="card-item"><label>实时值(Hz)</label><label class="card-value">{{selfTestData.item81S1Or81S2Min.vac}}</label></div>
                        <div class="card-item"><label>保护值测试结果(Hz)</label><label class="card-value">{{selfTestData.item81S1Or81S2Min.vacOff}}</label></div>
                        <div class="card-item"><label>保护时间测试结果(s)</label><label class="card-value">{{selfTestData.item81S1Or81S2Min.tOff}}</label></div>
                        <div class="card-item">
                            <label>进度(%)</label>
                            <label v-if="selfTestData.item81S1Or81S2Min.itemProcess === '成功'" class="success-text-color">{{selfTestData.item81S1Or81S2Min.itemProcess}}</label>
                            <label v-else-if="selfTestData.item81S1Or81S2Min.itemProcess === '失败'" class="fail-text-color">{{selfTestData.item81S1Or81S2Min.itemProcess}}</label>
                            <label v-else class="card-value">{{selfTestData.item81S1Or81S2Min.itemProcess}}</label>
                        </div>
                    </el-card>
                </el-col>
            </el-row>
        </div>
        <div style="text-align: center">
            <el-button type="primary" :disabled="disableBtn" :loading="startLoading" @click="startIpsCheck">启动自检</el-button>
            <el-button type="danger" :disabled="disableBtn" @click="stopIpsCheck">停止自检</el-button>
            <el-button type="primary" @click="openInfo">历史信息</el-button>
        </div>
        <el-dialog title="自检信息" :visible="selfVisible" :close-on-click-modal="false" :append-to-body="true"
            :close-on-press-escape="false" top="15vh" width="880px" @close="closeIpsSelfTestPage" center>
            <ipsTestReport :deviceInfo="deviceInfo" ref="ipsTestReportPage"></ipsTestReport>
        </el-dialog>
    </div>
</template>

<script>

    import {aioDrtlCommand} from '@/api/esaio/device/esaioDeviceInfo';
    import IpsTestReport from '@/views/esaio/device/device-cmd/ips-test-report';
    import Global from '@/components/Global';
    import loading from '@/utils/cmdLoading';
    import message from '@/utils/message';
    import mqttService from '@/api/mqtt-service';
    import mqtt from 'mqtt';
    import {getToken} from '@/api/auth';

    export default {

        name: 'ips-self-test',
        components: {IpsTestReport},
        props: {

            deviceInfo: {

                type: Object
            },
            serverList: {

                type: Array
            },
        },
        data() {

            return {

                selfVisible: false,
                startLoading: false,
                localControl: '1',
                remoteControl: '1',
                selfTestTypeIndex: 0,
                selfTestTypeArray: [
                    {
                        id : 0,
                        name : '检测81.S1'
                    },
                    {
                        id : 1,
                        name : '检测81.S2(频率保护时间0.1s)'
                    },
                    {
                        id : 2,
                        name : '检测81.S2(过频保护时间1s,欠频保护时间4s)'
                    }
                ],
                checkItemStyle: {

                    style59S1: {

                        'un-start-container': true,
                        'checking-container': false,
                        'pass-container': false,
                        'fail-container': false
                    },
                    style59S2: {

                        'un-start-container': true,
                        'checking-container': false,
                        'pass-container': false,
                        'fail-container': false
                    },
                    style27S1: {

                        'un-start-container': true,
                        'checking-container': false,
                        'pass-container': false,
                        'fail-container': false
                    },
                    style27S2: {

                        'un-start-container': true,
                        'checking-container': false,
                        'pass-container': false,
                        'fail-container': false
                    },
                    style81Max: {

                        'un-start-container': true,
                        'checking-container': false,
                        'pass-container': false,
                        'fail-container': false
                    },
                    style81Min: {

                        'un-start-container': true,
                        'checking-container': false,
                        'pass-container': false,
                        'fail-container': false
                    }
                },
                selfTestData: {

                    item59S1: {

                        checkTotalCount: 0,
                        currentCheckCount: 0,
                        itemProcess: '-',
                        result: '-',
                        tOff: '-',
                        tripLimitSet1: '-',
                        tripLimitSet2: '-',
                        vac: '-',
                        vacOff: '-'
                    },
                    item59S2: {

                        checkTotalCount: 0,
                        currentCheckCount: 0,
                        itemProcess: '-',
                        result: '-',
                        tOff: '-',
                        tripLimitSet1: '-',
                        tripLimitSet2: '-',
                        vac: '-',
                        vacOff: '-'
                    },
                    item27S1: {

                        checkTotalCount: 0,
                        currentCheckCount: 0,
                        itemProcess: '-',
                        result: '-',
                        tOff: '-',
                        tripLimitSet1: '-',
                        tripLimitSet2: '-',
                        vac: '-',
                        vacOff: '-'
                    },
                    item27S2: {

                        checkTotalCount: 0,
                        currentCheckCount: 0,
                        itemProcess: '-',
                        result: '-',
                        tOff: '-',
                        tripLimitSet1: '-',
                        tripLimitSet2: '-',
                        vac: '-',
                        vacOff: '-'
                    },
                    item81S1Or81S2Max: {

                        checkTotalCount: 0,
                        currentCheckCount: 0,
                        itemProcess: '-',
                        result: '-',
                        tOff: '-',
                        tripLimitSet1: '-',
                        tripLimitSet2: '-',
                        vac: '-',
                        vacOff: '-'
                    },
                    item81S1Or81S2Min: {

                        checkTotalCount: 0,
                        currentCheckCount: 0,
                        itemProcess: '-',
                        result: '-',
                        tOff: '-',
                        tripLimitSet1: '-',
                        tripLimitSet2: '-',
                        vac: '-',
                        vacOff: '-'
                    },
                    localControl: 1,
                    reportTime: '-',
                    selfTestProgress: 0,
                    selfTestStatus: 0
                },
                disableBtn: false,
                command: {

                    sysProtocolReadCommandCode: 'querySysProtocolParam',
                    advanceParamReadCommandCode: 'queryAdvanceParam',
                    selfTestCommandCode: 'deviceSelfTest'
                },
                selfTopicPrefix: '$device_self_test/',
                client: {}
            }
        },
        methods: {

            initPage() {

                this.disableBtn = false;
                this.connectSelfTestMqtt();
            },
            connectSelfTestMqtt() {

                let connection = {

                    clientId: 'doartWeb_' + getToken(),
                    username: Global.mqttServerUserName,
                    password: Global.mqttServerPassword,
                };
                const connectUrl = Global.mqttServerSite + '/mqtt';
                let that = this;
                that.client = mqtt.connect(connectUrl, connection);
                let subscribeTopic = this.selfTopicPrefix + this.deviceInfo.serialNumber
                that.client.on('connect', function() {

                    console.info('自定义自检MQTT On Connect');
                    that.client.subscribe(subscribeTopic, function(err) {

                        if (err) {

                            console.error(err);
                        }
                    });
                }).on('message', function(topic, message) {

                    console.log(`ips-self-test ${message} from topic ${topic}`)
                    if (topic === subscribeTopic) {

                        that.handleSelfTestMessage(message);
                    }
                });
            },
            disconnectSelfTestMqtt() {

                if (typeof this.client.connected != 'undefined' && this.client.connected) {

                    this.client.end(true);
                }
            },
            startIpsCheck() {

                this.startLoading = true;
                this.disconnectSelfTestMqtt();
                this.querySysProtocolSettingInfo();
            },
            stopIpsCheck() {

                this.deviceSelfTestOperatorBySn('stop');
            },
            querySysProtocolSettingInfo() {

                this.disableBtn = true;
                this.cmdLoading = loading.loading('电网代码参数读取中...', 60);
                let that = this;
                let option = {

                    data: {

                        deviceSn: this.deviceInfo.serialNumber,
                        commandCode: this.command.sysProtocolReadCommandCode
                    },
                    mqttMessageCallback: function (res) {

                        if (res.code === Global.response_status_success_obj) {

                            that.readSysProtocolSuccessCallback(res.data);
                        } else {

                            that.closeLoading();
                            message.error("指令读取失败");
                        }
                    }
                };
                mqttService.sendEsaioBusinessCommand(option);
            },
            closeLoading() {

                this.startLoading = false;
                if (this.cmdLoading) {

                    this.cmdLoading.close();
                }
            },
            readSysProtocolSuccessCallback(data) {

                let that = this;
                // 查询电网代码回调，5表示意大利电网代码，如果不是则不启动自检
                if (data.gridCode === '5') {

                    setTimeout(() => {

                        // 查询高级参数中后备输出功能，没有打开不启动自检
                        that.queryAdvanceParamInfo();
                    }, 500);
                } else {

                    that.closeLoading();
                    message.error('电网代码不正确');
                }
            },
            queryAdvanceParamInfo() {

                let that = this;
                let option = {

                    data: {

                        deviceSn: this.deviceInfo.serialNumber,
                        commandCode: this.command.advanceParamReadCommandCode
                    },
                    mqttMessageCallback: function (res) {

                        if (res.code === Global.response_status_success_obj) {

                            that.readAdvanceParamSuccessCallback(res.data);
                        } else {

                            that.closeLoading();
                            message.error("指令读取失败");
                        }
                    }
                };
                mqttService.sendEsaioBusinessCommand(option);
            },
            readAdvanceParamSuccessCallback(data) {

                // 查询后备输出功能回调，若打开，方可开启自检
                if (data.bakOutputStatus === '00ee') {

                    this.closeLoading();
                    message.error('在“高级参数设置”中打开“后备输出功能”');
                } else {

                    this.deviceSelfTestOperatorBySn("start");
                }
            },
            deviceSelfTestOperatorBySn(optionType) {

                this.disableBtn = true;
                let param = {

                    deviceSn: this.deviceInfo.serialNumber,
                    messageId: new Date().getTime(),
                    commandCode: this.command.selfTestCommandCode,
                    data: {

                        localControl: this.localControl,
                        remoteControl: this.remoteControl,
                        selfTestTypeIndex: this.selfTestTypeIndex,
                        operatorFlag: optionType
                    }
                };
                aioDrtlCommand(param).then((res) => {

                    if (res.code === Global.response_status_success_obj) {

                        if (optionType === 'start') {

                            setTimeout(() => {

                                this.connectSelfTestMqtt();
                            }, 500);
                        } else {

                            this.startLoading = false;
                            message.success('请求成功！');
                        }
                    } else {

                        let failureTipMsg = (optionType === 'start' ? '自检启动失败' : '自检停止失败');
                        message.error(failureTipMsg);
                    }
                    this.disableBtn = false;
                }).catch(err => {

                    this.disableBtn = false;
                    message.error('请求失败,请稍后再试');
                    console.error(err);
                });
            },
            changeSelfTestType() {

                if (this.selfTestTypeIndex === 0) {

                    // 检测81.S1
                    this.localControl = '1';
                    this.remoteControl = '1';
                } else if (this.selfTestTypeIndex === 1) {

                    // 检测81.S2(频率保护时间0.1s)
                    this.localControl = '0';
                    this.remoteControl = '1';
                } else {

                    // 检测81.S2(过频保护时间1s，欠频保护时间4s)
                    this.localControl = '1';
                    this.remoteControl = '0';
                }
            },
            handleSelfTestMessage(msg) {

                if (this.cmdLoading) {

                    this.cmdLoading.close();
                }
                let data = JSON.parse(msg);
                this.selfTestData.reportTime = data.reportTime;
                if (data.localControl === '1' && data.remoteControl === '1') {

                    this.selfTestTypeIndex = 0;
                } else if (data.localControl === '0' && data.remoteControl === '1') {

                    this.selfTestTypeIndex = 1;
                } else {

                    this.selfTestTypeIndex = 2;
                }
                if (typeof data.item59S1 != 'undefined') {

                    this.selfTestData.item59S1 = data.item59S1;
                } else {

                    this.selfTestData.item59S1 = {

                        checkTotalCount: 0,
                        currentCheckCount: 0,
                        itemProcess: '-',
                        result: '-',
                        tOff: '-',
                        tripLimitSet1: '-',
                        tripLimitSet2: '-',
                        vac: '-',
                        vacOff: '-'
                    };
                }
                if (typeof data.item59S2 != 'undefined') {

                    this.selfTestData.item59S2 = data.item59S2;
                } else {

                    this.selfTestData.item59S2 = {

                        checkTotalCount: 0,
                        currentCheckCount: 0,
                        itemProcess: '-',
                        result: '-',
                        tOff: '-',
                        tripLimitSet1: '-',
                        tripLimitSet2: '-',
                        vac: '-',
                        vacOff: '-'
                    };
                }
                if (typeof data.item27S1 != 'undefined') {

                    this.selfTestData.item27S1 = data.item27S1;
                } else {

                    this.selfTestData.item27S1 = {

                        checkTotalCount: 0,
                        currentCheckCount: 0,
                        itemProcess: '-',
                        result: '-',
                        tOff: '-',
                        tripLimitSet1: '-',
                        tripLimitSet2: '-',
                        vac: '-',
                        vacOff: '-'
                    };
                }
                if (typeof data.item27S2 != 'undefined') {

                    this.selfTestData.item27S2 = data.item27S2;
                } else {

                    this.selfTestData.item27S2 = {

                        checkTotalCount: 0,
                        currentCheckCount: 0,
                        itemProcess: '-',
                        result: '-',
                        tOff: '-',
                        tripLimitSet1: '-',
                        tripLimitSet2: '-',
                        vac: '-',
                        vacOff: '-'
                    };
                }
                if (typeof data.item81S1Or81S2Max != 'undefined') {

                    this.selfTestData.item81S1Or81S2Max = data.item81S1Or81S2Max;
                } else {

                    this.selfTestData.item81S1Or81S2Max = {

                        checkTotalCount: 0,
                        currentCheckCount: 0,
                        itemProcess: '-',
                        result: '-',
                        tOff: '-',
                        tripLimitSet1: '-',
                        tripLimitSet2: '-',
                        vac: '-',
                        vacOff: '-'
                    };
                }
                if (typeof data.item81S1Or81S2Min != 'undefined') {

                    this.selfTestData.item81S1Or81S2Min = data.item81S1Or81S2Min;
                } else {

                    this.selfTestData.item81S1Or81S2Min = {

                        checkTotalCount: 0,
                        currentCheckCount: 0,
                        itemProcess: '-',
                        result: '-',
                        tOff: '-',
                        tripLimitSet1: '-',
                        tripLimitSet2: '-',
                        vac: '-',
                        vacOff: '-'
                    };
                }
                if (this.selfTestData.item81S1Or81S2Min.itemProcess === '3' && this.selfTestData.item81S1Or81S2Min.result === '1') {

                    message.success('自检完成');
                }
                console.log(JSON.stringify(this.selfTestData));
                this.messageDataHandler();
                this.disableBtn = false;
            },
            messageDataHandler() {

                // 检测项样式处理
                this.checkItemStyleHandler('item59S1', 'style59S1');
                this.checkItemStyleHandler('item59S2', 'style59S2');
                this.checkItemStyleHandler('item27S1', 'style27S1');
                this.checkItemStyleHandler('item27S2', 'style27S2');
                this.checkItemStyleHandler('item81S1Or81S2Max', 'style81Max');
                this.checkItemStyleHandler('item81S1Or81S2Min', 'style81Min');
                // 检测项进度处理
                this.checkItemProgressHandler('item59S1');
                this.checkItemProgressHandler('item59S2');
                this.checkItemProgressHandler('item27S1');
                this.checkItemProgressHandler('item27S2');
                this.checkItemProgressHandler('item81S1Or81S2Max');
                this.checkItemProgressHandler('item81S1Or81S2Min');
            },
            checkItemStyleHandler(itemDataName, itemStyleName) {

                let itemData = this.selfTestData[itemDataName];
                if (itemData.currentCheckCount > 0) {

                    this.checkItemStyle[itemStyleName] = {

                        'un-start-container': false,
                        'checking-container': true,
                        'pass-container': false,
                        'fail-container': false
                    };
                } else if (itemData.currentCheckCount === 0 && itemData.result === 1) {

                    this.checkItemStyle[itemStyleName] = {

                        'un-start-container': false,
                        'checking-container': false,
                        'pass-container': true,
                        'fail-container': false
                    };
                } else if (itemData.currentCheckCount === 0 && itemData.result === 0) {

                    this.checkItemStyle[itemStyleName] = {

                        'un-start-container': false,
                        'checking-container': false,
                        'pass-container': false,
                        'fail-container': true
                    };
                } else if (itemData.currentCheckCount === 0 && itemData.checkTotalCount === 0) {

                    this.checkItemStyle[itemStyleName] = {

                        'un-start-container': true,
                        'checking-container': false,
                        'pass-container': false,
                        'fail-container': false
                    };
                }
            },
            checkItemProgressHandler(itemDataName) {

                let itemData = this.selfTestData[itemDataName];
                if (itemData.checkTotalCount > 0) {

                    // 进行中时显示进度
                    let percent = (itemData.checkTotalCount - itemData.currentCheckCount) / itemData.checkTotalCount * 100;
                    itemData.itemProcess = percent.toFixed() + '%';
                    // 完成时显示结果
                    if (itemData.itemProcess === '100%') {

                        if (itemData.result === '1') {

                            itemData.itemProcess = '成功';
                        } else {

                            itemData.itemProcess = '失败';
                        }
                    }
                } else {

                    itemData.itemProcess = '-';
                }
                if (this.selfTestData.item81S1Or81S2Min.itemProcess === '成功' ||
                    this.selfTestData.item81S1Or81S2Min.itemProcess === '失败') {

                    this.startLoading = false;
                }
            },
            closeIpsSelfTestPage() {

                this.selfVisible = false;
                this.disconnectSelfTestMqtt();
            },
            openInfo() {

                this.selfVisible = true;
                this.$nextTick(() => {

                    this.$refs.ipsTestReportPage.queryDeviceSelfTestHisInfoBySn();
                })
            },
        },
    }
</script>

<style lang="scss" scoped>

    .card {

        width: 250px;
        height: 200px;
        margin: 0 0 20px 0;
    }

    .card-title {

        font-weight: bold;
    }

    .card-item {

        margin: 5px 0 0 0;
    }

    .card-value {

        float: right;
    }

    .item {

        width: 444px;
    }

    .un-start-container {

        border: 1px solid #CCCCCC;
    }

    .checking-container {

        border: 1px solid #009DFF;
    }

    .pass-container {

        border: 1px solid limegreen;
    }

    .fail-container {

        border: 1px solid red;
    }

    .success-text-color {

        color: limegreen;
        float: right;
    }

    .fail-text-color {

        color: red;
        float: right;
    }

    .content {

        margin-left: 20px;
        overflow: auto;
        height: 510px;
    }

    .content::-webkit-scrollbar {

        width: 3px;
    }

    .content::-webkit-scrollbar-thumb {

        background: #8798AF;
        border-radius: 2px;
    }

    .content::-webkit-scrollbar-track {

        background: transparent;
    }
</style>
