<template>
    <div>
        <div class="content">
            <el-form :model="editForm" :rules="formRules" ref="editForm" label-width="270px" label-position="left" :inline="true">
                <el-row>
                    <el-col :span="24">
                        <el-form-item prop="gridCode">
                            <template slot="label">
                                <div class="form-item-label">电网代码：</div>
                            </template>
                            <el-select class="item" v-model="editForm.gridCode" placeholder="请选择电网代码">
                                <el-option v-for="item in gridCodeList" :key="item.value" :label="item.name" :value="item.value"/>
                            </el-select>
                        </el-form-item>
                    </el-col>
                </el-row>
                <span class="text-down">需要关机后设置，设置完毕后设备会重新启动</span>
                <el-row>
                    <el-col :span="24">
                        <el-form-item prop="dcBatteryType">
                            <template slot="label">
                                <div class="form-item-label">电池类型：</div>
                            </template>
                            <el-select class="item" v-model="editForm.dcBatteryType" placeholder="请选择电池类型">
                                <el-option v-for="item in dcBatteryTypeList" :key="item.value" :label="item.name"
                                           :value="item.value" :disabled="item.disabled"/>
                            </el-select>
                        </el-form-item>
                    </el-col>
                </el-row>
                <span class="text-down">设置完毕后设备会重新启动</span>
                <el-row>
                    <el-col :span="24">
                        <el-form-item prop="batteryProtocol">
                            <template slot="label">
                                <div class="form-item-label">电池通信协议：</div>
                            </template>
                            <el-select class="item" v-model="editForm.batteryProtocol" placeholder="请选择电池通信协议">
                                <el-option v-for="item in batteryProtocolList" :key="item.value" :label="item.name"
                                           :value="item.value" :disabled="item.disabled"/>
                            </el-select>
                        </el-form-item>
                    </el-col>
                </el-row>
                <span class="text-down">设置完毕后设备会重新启动</span>
                <el-row>
                    <el-col :span="24">
                        <el-form-item prop="ammeterProtocol">
                            <template slot="label">
                                <div class="form-item-label">电表通讯协议：</div>
                            </template>
                            <el-select class="item" v-model="editForm.ammeterProtocol" placeholder="请选择电表通讯协议">
                                <el-option v-for="item in ammeterProtocolList" :key="item.value" :label="item.name"
                                           :value="item.value"/>
                            </el-select>
                        </el-form-item>
                    </el-col>
                </el-row>
                <span class="text-down">设置完毕后设备会重新启动</span>
            </el-form>
        </div>
        <div style="text-align: center">
            <el-button type="primary" :disabled="disableBtn" @click="sysProtocolParamSubmit">设置</el-button>
        </div>
    </div>
</template>

<script>

    import {aioDrtlCommand} from '@/api/esaio/device/esaioDeviceInfo';
    import mqttService from '@/api/mqtt-service';
    import loading from '@/utils/cmdLoading';
    import message from '@/utils/message';
    import Global from '@/components/Global';

    export default {

        name: 'grid-code-setting',
        props: {

            deviceInfo: {

                type: Object
            }
        },
        data() {

            return {

                editForm: {

                    gridCode: '',
                    dcBatteryType: '',
                    batteryProtocol: '',
                    ammeterProtocol: ''
                },
                batteryProtocolList: [

                    { value : '1', name : '无电池' },
                    { value : '30', name : '东安485' },
                    { value : 'none', name : '其它', disabled: true}
                ],
                dcBatteryTypeList: [

                    { value : '1', name : '锂电池' },
                    { value : 'none', name : '其它', disabled: true}
                ],
                ammeterProtocolList: [

                    { value : '1', name : '无电表' },
                    { value : '2', name : '安科瑞10K' },
                    { value : '3', name : '安科瑞5K' },
                    { value : '4', name : '东鸿10K' },
                    { value : '5', name : '东鸿5K' },
                    { value : '6', name : '正泰10K' },
                    { value : '7', name : '正泰5K' }
                ],
                gridCodeList: [

                    { value : '1', name : '中国' },
                    { value : '2', name : '澳洲' },
                    { value : '3', name : '德国' },
                    { value : '4', name : '英国' },
                    { value : '5', name : '意大利' },
                    { value : '6', name : '法国' },
                    { value : '7', name : '奥地利' },
                    { value : '8', name : '南非' },
                    { value : '9', name : '捷克' },
                    { value : '10', name : '比利时' },
                    { value : '11', name : '瑞士' },
                    { value : '12', name : '菲律宾' },
                    { value : '13', name : '默认50Hz' },
                    { value : '14', name : '默认60Hz' },
                    { value : '15', name : '泰国' },
                    { value : '16', name : '丹麦' },
                    { value : '17', name : '希腊' },
                    { value : '18', name : '荷兰' },
                    { value : '19', name : '印度' },
                    { value : '20', name : '爱尔兰' },
                    { value : '21', name : '新西兰' },
                    { value : '22', name : '波兰' },
                    { value : '23', name : '西班牙' },
                    { value : '24', name : '葡萄牙' },
                    { value : '25', name : '巴基斯坦' },
                    { value : '26', name : '自定义' },
                    { value : '27', name : '乌克兰' },
                    { value : '28', name : '斯洛文尼亚' },
                    { value : '29', name : '越南' },
                    { value : '30', name : '芬兰' },
                    { value : '31', name : '挪威' },
                    { value : '32', name : '瑞典' },
                    { value : '33', name : '博茨瓦纳' },
                    { value : '34', name : '安哥拉' },
                    { value : '35', name : '津巴布韦' },
                    { value : '36', name : '坦桑尼亚' },
                    { value : '37', name : '尼日利亚' },
                    { value : '38', name : '肯尼亚' }
                ],
                disableBtn: false,
                formRules: {

                    gridCode: [

                        { required: true, message: '请选择电网代码', trigger: 'change' }
                    ],
                    dcBatteryType: [

                        { required: true, message: '请选择电池类型', trigger: 'change' }
                    ],
                    batteryProtocol: [

                        { required: true, message: '请选择电池通信协议', trigger: 'change' }
                    ],
                    ammeterProtocol: [

                        { required: true, message: '请选择电表通讯协议', trigger: 'change' }
                    ]
                },
                command: {

                    readCommandCode: 'querySysProtocolParam',
                    modifyCommandCode: 'modifySysProtocolParam'
                }
            }
        },
        methods: {

            initPage() {

                this.disableBtn = true;
                this.cmdLoading = this.openCmdLoading('电网代码参数读取中...');
                let that = this;
                let option = {

                    loading: that.cmdLoading,
                    data: {

                        deviceSn: that.deviceInfo.serialNumber,
                        commandCode: that.command.readCommandCode
                    },
                    mqttMessageCallback: function (res) {

                        if (res.code === Global.response_status_success_obj) {

                            that.successCallback(res.data);
                        } else {

                            message.error("指令读取失败");
                        }
                    }
                }
                mqttService.sendEsaioBusinessCommand(option);
            },
            sysProtocolParamSubmit() {

                this.$refs['editForm'].validate((valid) => {

                    if (valid) {

                        if (this.editForm.dcBatteryType === 'none') {

                            message.warning('电池类型不能选择“其他”');
                            return;
                        }
                        if (this.editForm.batteryProtocol === 'none') {

                            message.warning('电池通讯协议不能选择“其他”');
                            return;
                        }
                        this.disableBtn = true;
                        let param = {

                            deviceSn: this.deviceInfo.serialNumber,
                            commandCode: this.command.modifyCommandCode,
                            messageId: new Date().getTime(),
                            data: {

                                gridCode: this.editForm.gridCode,
                                dcBatteryType: this.editForm.dcBatteryType,
                                batteryProtocol: this.editForm.batteryProtocol,
                                ammeterProtocol: this.editForm.ammeterProtocol
                            }
                        };
                        aioDrtlCommand(param).then((res) => {

                            this.cmdLoading.close();
                            this.disableBtn = false;
                            if (res.code === Global.response_status_success_obj) {

                                message.success('命令已发送完成，请等待设备重启');
                            } else {

                                message.error(res.msg);
                            }
                        }).catch(err => {

                            console.error(err)
                            this.disableBtn = false;
                            this.cmdLoading.close();
                        });
                    }
                });
            },
            successCallback(data) {

                this.disableBtn = false;
                // 电网代码
                this.editForm.gridCode = data.gridCode;
                // 电池类型
                let dcBatteryType = data.dcBatteryType;
                if (dcBatteryType === '1') {

                    this.editForm.dcBatteryType = dcBatteryType;
                } else {

                    this.editForm.dcBatteryType = 'none';queryEsaioStationInfoList
                }
                // 电池通信协议
                let batteryProtocol = data.batteryProtocol;
                if (batteryProtocol === '1' || batteryProtocol === '30') {

                    this.editForm.batteryProtocol = batteryProtocol;
                } else {

                    this.editForm.batteryProtocol = 'none';
                }
                // 电表通讯协议
                this.editForm.ammeterProtocol = data.ammeterProtocol
            },
            openCmdLoading(msg) {

                return loading.loading(msg, 30);
            }
        }
    }
</script>

<style lang="scss" scoped>

    .text-down {

        float: left;
        margin: -42px 0 0 10px;
        font-size: 10px;
        color: #b9b9b9;
    }

    .form-item-label {

        margin: -50px 0 0 10px;
    }

    .item {

        width: 200px;
    }

    .content {

        margin-left: 180px;
        height: 490px;
    }
</style>
