/**
 * 获取日期字符串 yyyy-MM-dd格式.
 */
let getDateStr = function(now) {

    let year = now.getFullYear();
    let month = (now.getMonth() + 1).toString().padStart(2, '0');
    let day =  now.getDate().toString().padStart(2, '0');
    return year + '-' + month + '-' + day;
}

/**
 * 获取日期字符串 yyyy-MM-dd HH:mm:ss格式.
 */
let getDateTimeStr = function(now) {

    let year = now.getFullYear();
    let month = (now.getMonth() + 1).toString().padStart(2, '0');
    let day =  now.getDate().toString().padStart(2, '0');
    let hour = String(now.getHours()).padStart(2, '0');
    let minute = String(now.getMinutes()).padStart(2, '0');
    let second = String(now.getSeconds()).padStart(2, '0');
    return year + '-' + month + '-' + day + ' ' + hour + ':' + minute + ':' + second;
}

export default {

    getDateStr,
    getDateTimeStr
}
