import { render, staticRenderFns } from "./ips-self-test.vue?vue&type=template&id=4c331b14&scoped=true&"
import script from "./ips-self-test.vue?vue&type=script&lang=js&"
export * from "./ips-self-test.vue?vue&type=script&lang=js&"
import style0 from "./ips-self-test.vue?vue&type=style&index=0&id=4c331b14&lang=scss&scoped=true&"


/* normalize component */
import normalizer from "!../../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "4c331b14",
  null
  
)

export default component.exports