<template>
    <div>
        <div class="content">
            <el-form :model="editForm" :rules="formRules" ref="editForm" label-width="200px" :inline="true">
                <el-row>
                    <el-col :span="24">
                        <el-form-item label="是否开启强制充电：" prop="isForcedCharge">
                            <el-switch class="switch" v-model="editForm.isForcedCharge" :active-value="'01'"
                                    :inactive-value="'00'"/>
                        </el-form-item>
                    </el-col>
                </el-row>
                <el-row v-if="editForm.isForcedCharge === '01'">
                    <el-col :span="24">
                        <el-form-item label="强制充电SOC上限：" prop="forcedChargeSocMax">
                            <el-input class="item" width="200px" v-model="editForm.forcedChargeSocMax" @blur="handleForcedChargeSocMaxBlurEvent"
                                      placeholder="[1~100]" autocomplete="off">
                                <template slot="append">%</template>
                            </el-input>
                        </el-form-item>
                    </el-col>
                </el-row>
                <el-row v-if="editForm.isForcedCharge === '01'">
                    <el-button class="date-button" type="primary" size="mini" @click="openDateSelectPage(true)" round>日期选择</el-button>
                </el-row>
                <el-row v-if="editForm.isForcedCharge === '01' && editForm.forcedChargeWeekList && editForm.forcedChargeWeekList.length > 0">
                    <el-table :data="editForm.forcedChargeWeekList" size="mini" class="table">
                        <el-table-column prop="weekday" label="日期" :formatter="weekdayFormatter" align="center"/>
                        <el-table-column prop="effectMode" label="生效次数" :formatter="effectModeFormatter" align="center"/>
                        <el-table-column label="删除" align="center" >
                            <template slot-scope="scope">
                                <el-button size="mini" @click="handleChargeDateDeleteEvent(scope.$index)" type="danger">删除</el-button>
                            </template>
                        </el-table-column>
                    </el-table>
                </el-row>
                <el-row v-if="editForm.isForcedCharge === '01'">
                    <el-button class="time-button" type="primary" size="mini" @click="openSelectTimePage(true)" round>时间选择</el-button>
                </el-row>
                <el-row v-if="editForm.isForcedCharge === '01' && editForm.forcedChargeTimeList && editForm.forcedChargeTimeList.length > 0">
                    <el-table :data="editForm.forcedChargeTimeList" size="mini" class="table">
                        <el-table-column prop="startTime" label="起始时间" align="center"/>
                        <el-table-column prop="endTime" label="结束时间" align="center"/>
                        <el-table-column label="删除" align="center" >
                            <template slot-scope="scope">
                                <el-button size="mini" @click="handleChargeTimeDeleteEvent(scope.$index)" type="danger">删除</el-button>
                            </template>
                        </el-table-column>
                    </el-table>
                </el-row>
                <el-row style="margin-top: 20px">
                    <el-col :span="24">
                        <el-form-item label="是否开启强制放电：" prop="isForcedDisCharge">
                            <el-switch class="switch" v-model="editForm.isForcedDisCharge" :active-value="'01'"
                                    :inactive-value="'00'"/>
                        </el-form-item>
                    </el-col>
                </el-row>
                <el-row v-if="editForm.isForcedDisCharge === '01'">
                    <el-col :span="24">
                        <el-form-item label="强制放电SOC下限：" prop="forcedDisChargeSocMin">
                            <el-input class="item" width="200px" v-model="editForm.forcedDisChargeSocMin"
                                      placeholder="[1~100]" autocomplete="off">
                                <template slot="append">%</template>
                            </el-input>
                        </el-form-item>
                    </el-col>
                </el-row>
                <el-row v-if="editForm.isForcedDisCharge === '01'">
                    <el-button class="date-button" type="primary" size="mini" @click="openDateSelectPage(false)" round>日期选择</el-button>
                </el-row>
                <el-row v-if="editForm.isForcedDisCharge === '01' && editForm.forcedDisChargeWeekList && editForm.forcedDisChargeWeekList.length > 0">
                    <el-table :data="editForm.forcedDisChargeWeekList" size="mini" class="table">
                        <el-table-column prop="weekday" label="日期" :formatter="weekdayFormatter" align="center"/>
                        <el-table-column prop="effectMode" label="生效次数" :formatter="effectModeFormatter" align="center"/>
                        <el-table-column label="删除" align="center" >
                            <template slot-scope="scope">
                                <el-button size="mini" @click="handleDisChargeDateDeleteEvent(scope.$index)" type="danger">删除</el-button>
                            </template>
                        </el-table-column>
                    </el-table>
                </el-row>
                <el-row v-if="editForm.isForcedDisCharge === '01'">
                    <el-button class="time-button" type="primary" size="mini" @click="openSelectTimePage(false)" round>时间选择</el-button>
                </el-row>
                <el-row v-if="editForm.isForcedDisCharge === '01' && editForm.forcedDisChargeTimeList && editForm.forcedDisChargeTimeList.length > 0">
                    <el-table :data="editForm.forcedDisChargeTimeList" size="mini" class="table">
                        <el-table-column prop="startTime" label="起始时间" align="center"/>
                        <el-table-column prop="endTime" label="结束时间" align="center"/>
                        <el-table-column label="删除" align="center" >
                            <template slot-scope="scope">
                                <el-button size="mini" @click="handleDisChargeTimeDeleteEvent(scope.$index)" type="danger">删除</el-button>
                            </template>
                        </el-table-column>
                    </el-table>
                </el-row>
            </el-form>
        </div>
        <div style="text-align: center">
            <el-button type="primary" :disabled="disableBtn" @click="forcedChargeParamSubmit">设置</el-button>
        </div>
        <el-dialog title="日期选择" :visible="selectDateVisible" :close-on-click-modal="false" :append-to-body="true"
            :close-on-press-escape="false" :show-close="false" width="800px" center>
            <el-form :model="selectDateForm" :rules="selectDateRules" ref="selectDateForm" label-width="100px">
                <el-row>
                    <el-col :span="12">
                        <el-form-item label="日期：" prop="weekday">
                            <template>
                                <el-select v-model="selectDateForm.weekday">
                                    <el-option v-for="item in dateList" :key="item.value" :label="item.name" :value="item.value">
                                    </el-option>
                                </el-select>
                            </template>
                        </el-form-item>
                    </el-col>
                    <el-col :span="12">
                        <el-form-item label="生效次数：" prop="effectMode">
                            <template>
                                <el-select v-model="selectDateForm.effectMode">
                                    <el-option v-for="item in effectList" :key="item.value" :label="item.name" :value="item.value">
                                    </el-option>
                                </el-select>
                            </template>
                        </el-form-item>
                    </el-col>
                </el-row>
            </el-form>
            <div slot="footer" class="dialog-footer">
                <el-button type="primary" @click="dateConfirm">确定</el-button>
                <el-button @click="selectDateVisible = false">取消</el-button>
            </div>
        </el-dialog>
        <el-dialog title="时间选择" :visible="selectTimeVisible" :close-on-click-modal="false" :append-to-body="true"
            :close-on-press-escape="false" :show-close="false" width="600px" center>
            <el-form :model="selectTimeForm" :rules="selectTimeRules" ref="selectTimeForm" label-width="100px">
                <el-row>
                    <el-col :span="12">
                        <el-form-item label="时间范围：" prop="timeRange">
                            <template>
                                <el-time-picker is-range v-model="selectTimeForm.timeRange" range-separator="至"
                                    start-placeholder="开始时间" end-placeholder="结束时间" placeholder="选择时间范围"
                                    format="HH:mm" value-format="HH:mm"/>
                            </template>
                        </el-form-item>
                    </el-col>
                </el-row>
            </el-form>
            <div slot="footer" class="dialog-footer">
                <el-button type="primary" @click="timeConfirm">确定</el-button>
                <el-button @click="selectTimeVisible = false">取消</el-button>
            </div>
        </el-dialog>
    </div>
</template>

<script>

    import loading from '@/utils/cmdLoading';
    import mqttService from '@/api/mqtt-service';
    import tool from '@/utils/tool';
    import message from '@/utils/message';
    import Global from '@/components/Global';

    export default {

        name: 'forced-charge-setting',
        props: {

            deviceInfo: {

                type: Object
            }
        },
        data() {

            return {

                selectDateVisible: false,
                selectDateForm: {

                    weekday: '',
                    effectMode: ''
                },
                selectTimeVisible: false,
                selectTimeForm: {

                    timeRange: ''
                },
                dateList: [

                    { value : '1', name : '周一' },
                    { value : '2', name : '周二' },
                    { value : '3', name : '周三' },
                    { value : '4', name : '周四' },
                    { value : '5', name : '周五' },
                    { value : '6', name : '周六' },
                    { value : '7', name : '周日' }
                ],
                effectList: [

                    { value : '01', name : '生效一次' },
                    { value : '11', name : '每周生效' }
                ],
                editForm: {

                    isForcedCharge: '',
                    forcedChargeSocMax: '',
                    isForcedDisCharge: '',
                    forcedDisChargeSocMin: '',
                    forcedChargeWeekList: [],
                    forcedChargeTimeList: [],
                    forcedDisChargeWeekList: [],
                    forcedDisChargeTimeList: []
                },
                chargeOn: true,
                disableBtn: false,
                formRules: {

                    forcedChargeSocMax: [

                        { required: true, validator: this.validateSocMinAndMax, trigger: 'blur' }
                    ],
                    forcedDisChargeSocMin: [

                        { required: true, validator: this.validateSocMinAndMax, trigger: 'blur' }
                    ]
                },
                selectDateRules: {

                    weekday: [

                        { required: true, message: '请选择日期', trigger: 'change' }
                    ],
                    effectMode: [

                        { required: true, message: '请选择生效次数', trigger: 'change' }
                    ],
                },
                selectTimeRules: {

                    timeRange: [

                        { required: true, message: '请选择时间范围', trigger: 'change' }
                    ]
                },
                command: {

                    readCommandCode: 'queryForcedChargeParam',
                    modifyCommandCode: 'modifyForcedChargeParam'
                },
                requestParam: {}
            }
        },
        methods: {

            initPage() {

                this.disableBtn = true;
                this.cmdLoading = this.openCmdLoading('强制充放电读取中...');
                let that = this;
                let option = {

                    loading: that.cmdLoading,
                    data: {

                        deviceSn: that.deviceInfo.serialNumber,
                        commandCode: that.command.readCommandCode
                    },
                    mqttMessageCallback: function (res) {

                        if (res.code === Global.response_status_success_obj) {

                            that.successCallback(res.data, false);
                        } else {

                            message.error("指令读取失败");
                        }
                    }
                };
                mqttService.sendEsaioBusinessCommand(option);
            },
            forcedChargeParamSubmit() {

                this.$refs['editForm'].validate((valid) => {

                    if (valid) {

                        if (this.editForm.isForcedCharge === '01') {

                            if (this.editForm.forcedChargeWeekList.length === 0) {

                                this.$message.error({

                                    message: '开启强制充电后，至少需要添加一组强充日期配置',
                                    center: true
                                });
                                return;
                            }
                            if (this.editForm.forcedChargeTimeList.length === 0) {

                                this.$message.error({

                                    message: '开启强制充电后，至少需要添加一组强充时间段配置',
                                    center: true
                                });
                                return;
                            }
                        }
                        if (this.editForm.isForcedDisCharge === '01') {

                            if (this.editForm.forcedDisChargeWeekList.length === 0) {

                                this.$message.error({

                                    message: '开启强制放电后，至少需要添加一组强放日期配置',
                                    center: true
                                });
                                return;
                            }
                            if (this.editForm.forcedDisChargeTimeList.length === 0) {

                                this.$message.error({

                                    message: '开启强制放电后，至少需要添加一组强放时间段配置',
                                    center: true
                                });
                                return;
                            }
                        }
                        this.disableBtn = true;
                        this.cmdLoading = this.openCmdLoading('强制充放电设置中...');
                        let that = this;
                        that.requestParam = Object.assign({}, that.editForm);
                        let option = {

                            loading: that.cmdLoading,
                            data: {

                                deviceSn: that.deviceInfo.serialNumber,
                                commandCode: that.command.modifyCommandCode,
                                data: {

                                    ...that.editForm
                                }
                            },
                            mqttMessageCallback: function (res) {

                                that.successCallback(res.data, true);
                                if (tool.isObjectValueEqual(that.requestParam, that.editForm)) {

                                    message.success('配置成功');
                                } else {

                                    message.error('配置失败');
                                }
                            }
                        };
                        mqttService.sendEsaioBusinessCommand(option);
                    }
                });
            },
            successCallback(data, flag) {

                this.disableBtn = false;
                // 强制充电设置前与设置后一致，并且都为关时，不校验日期数组、上限是否一致
                if (flag === true && this.requestParam.isForcedCharge === data.isForcedCharge && data.isForcedCharge === '00') {

                    data.forcedChargeWeekList = [];
                    this.requestParam.forcedChargeWeekList = [];
                    data.forcedChargeSocMax = '0';
                    this.requestParam.forcedChargeSocMax = '0';
                    data.forcedChargeTimeList = [];
                    this.requestParam.forcedChargeTimeList = [];
                } else {

                    data.forcedChargeSocMax = data.forcedChargeSocMax + '';
                }
                // 强制放电设置前与设置后一致，并且都为关时，不校验日期数组、上限是否一致
                if (flag === true && this.requestParam.isForcedDisCharge === data.isForcedDisCharge && data.isForcedDisCharge === '00') {

                    data.forcedDisChargeWeekList = [];
                    this.requestParam.forcedDisChargeWeekList = [];
                    data.forcedDisChargeSocMin = '0';
                    this.requestParam.forcedDisChargeSocMin = '0';
                    data.forcedDisChargeTimeList = [];
                    this.requestParam.forcedDisChargeTimeList = [];
                } else {

                    data.forcedDisChargeSocMin = data.forcedDisChargeSocMin + '';
                }
                this.editForm = data;
            },
            openDateSelectPage(e) {

                this.selectDateVisible = true;
                this.$nextTick(() => {

                    // 重置表单
                    this.$refs.selectDateForm.resetFields();
                });
                this.selectDateForm.weekday = '';
                this.selectDateForm.effectMode = '';
                this.chargeOn = e;
            },
            openSelectTimePage(e) {

                this.selectTimeVisible = true;
                this.$nextTick(() => {

                    // 重置表单
                    this.$refs.selectTimeForm.resetFields();
                });
                this.selectTimeForm.timeRange = '';
                this.selectTimeForm.effectMode = '';
                this.chargeOn = e;
            },
            handleChargeDateDeleteEvent(index) {

                this.editForm.forcedChargeWeekList.splice(index, 1);
            },
            handleChargeTimeDeleteEvent(index) {

                this.editForm.forcedChargeTimeList.splice(index, 1);
            },
            handleDisChargeDateDeleteEvent(index) {

                this.editForm.forcedDisChargeWeekList.splice(index, 1);
            },
            handleDisChargeTimeDeleteEvent(index) {

                this.editForm.forcedDisChargeTimeList.splice(index, 1);
            },
            dateConfirm() {

                this.$refs['selectDateForm'].validate((valid) => {

                    if (valid) {

                        this.selectDateVisible = false;
                        if (this.chargeOn) {

                            this.addForcedChargeWeek();
                        } else {

                            this.addForcedDisChargeWeek();
                        }
                    }
                });
            },
            timeConfirm() {

                this.$refs['selectTimeForm'].validate((valid) => {

                    if (valid) {

                        this.selectTimeVisible = false;
                        if (this.chargeOn) {

                            this.addForcedChargeTime();
                        } else {

                            this.addForcedDisChargeTime();
                        }
                    }
                });
            },
            weekdayFormatter(row, column, cellValue) {

                switch(cellValue){

                    case '1':
                        return '周一';
                    case '2':
                        return '周二';
                    case '3':
                        return '周三';
                    case '4':
                        return '周四';
                    case '5':
                        return '周五';
                    case '6':
                        return '周六';
                    case '7':
                        return '周日';
                    default:
                        return '未知';
                }
            },
            effectModeFormatter(row, column, cellValue) {

                switch(cellValue){

                    case '01':
                        return '生效一次';
                    case '11':
                        return '每周生效';
                    default:
                        return '未知';
                }
            },
            addForcedChargeWeek() {

                if (this.editForm.forcedChargeWeekList.length === 7) {

                    this.$message.error({

                        message: '最多添加7组日期',
                        center: true
                    });
                    return;
                }
                if (!this.validateWeekRepeat(1, this.selectDateForm.weekday)) {

                    return;
                }
                let obj = {

                    weekday: this.selectDateForm.weekday,
                    effectMode: this.selectDateForm.effectMode
                }
                this.editForm.forcedChargeWeekList.push(obj);
            },
            addForcedDisChargeWeek() {

                if (this.editForm.forcedDisChargeWeekList.length === 7) {

                    this.$message.error({

                        message: '最多添加7组日期',
                        center: true
                    });
                    return;
                }
                if (!this.validateWeekRepeat(2, this.selectDateForm.weekday)) {

                    return;
                }
                let obj = {

                    weekday: this.selectDateForm.weekday,
                    effectMode: this.selectDateForm.effectMode,
                };
                this.editForm.forcedDisChargeWeekList.push(obj);
            },
            addForcedChargeTime() {

                if (this.editForm.forcedChargeTimeList.length === 4) {

                    this.$message.error({

                        message: '最多添加四个时间段',
                        center: true
                    });
                    return;
                }
                let obj = {

                    startTime : this.selectTimeForm.timeRange[0],
                    endTime : this.selectTimeForm.timeRange[1],
                };
                if (!this.validateTimeRange(obj.startTime, obj.endTime)) {

                    return;
                }
                if (!this.validateTimeRepeat(1, obj.startTime, obj.endTime)) {

                    return;
                }
                this.editForm.forcedChargeTimeList.push(obj);
            },
            addForcedDisChargeTime() {

                if (this.editForm.forcedDisChargeTimeList.length === 4) {

                    this.$message.error({

                        message: '最多添加四个时间段',
                        center: true
                    });
                    return;
                }
                let obj = {

                    startTime : this.selectTimeForm.timeRange[0],
                    endTime : this.selectTimeForm.timeRange[1],
                };
                if (!this.validateTimeRange(obj.startTime, obj.endTime)) {

                    return;
                }
                if (!this.validateTimeRepeat(2, obj.startTime, obj.endTime)) {

                    return;
                }
                this.editForm.forcedDisChargeTimeList.push(obj);
            },
            /**
             * 检查添加的强制充放电日期是否重复.
             * @param {Object} type type 类型 1：校验强充时间段 2：校验强放时间段
             * @param {Object} weekday 星期 1-7
             */
            validateWeekRepeat(type, weekday) {

                let tempWeekList;
                if (type === 1) {

                    tempWeekList = this.editForm.forcedChargeWeekList;
                } else {

                    tempWeekList = this.editForm.forcedDisChargeWeekList;
                }
                // 检查是否重复添加
                for (let i = 0; i < tempWeekList.length; i++) {

                    let forcedChargeWeek = tempWeekList[i];
                    if (forcedChargeWeek.weekday === weekday) {

                        // 提示重复添加
                        this.$message.error({

                            message: '重复添加日期',
                            center: true
                        });
                        return false;
                    }
                }
                return true;
            },
            /**
             * 校验开始时间不能小于结束时间.
             * @param {Object} startTime 开始时间
             * @param {Object} endTime 结束时间
             */
            validateTimeRange(startTime, endTime) {

                let startTimeSplit = startTime.split(':');
                let startTimeHour = startTimeSplit[0];
                let startTimeMinute = startTimeSplit[1];

                let endTimeSplit = endTime.split(':');
                let endTimeHour = endTimeSplit[0];
                let endTimeMinute = endTimeSplit[1];

                let startTimeMinuteTot = (parseInt(startTimeHour) * 60 + parseInt(startTimeMinute));
                let endTimeMinuteTot = (parseInt(endTimeHour) * 60 + parseInt(endTimeMinute));
                if (endTimeMinuteTot <= startTimeMinuteTot) {

                    this.$message.error({

                        message: '结束时间不能小于开始时间',
                        center: true
                    });
                    return false;
                } else {

                    return true;
                }
            },
            /**
             * 校验添加的时间段是否重复.
             * @param {Object} type 类型 1：校验强充时间段 2：校验强放时间段
             * @param {Object} startTime 开始时间
             * @param {Object} endTime 结束时间
             */
            validateTimeRepeat(type, startTime, endTime) {

                /**
                 * 校验新的时间和老的时间是否存在重合，时间格式为 yyyy-MM-hh hh:mm.
                 * @param {Object} startTimeOld 老的开始时间
                 * @param {Object} endTimeOld 老的结束时间
                 * @param {Object} startTimeNew 新的开始时间
                 * @param {Object} endTimeNew 新的结束时间
                 */
                let checkTimeRepeat = function(startTimeOld, endTimeOld, startTimeNew, endTimeNew) {

                    let startTimeOldDate = new Date(startTimeOld).getTime();
                    let endTimeOldDate = new Date(endTimeOld).getTime();
                    let startTimeNewDate = new Date(startTimeNew).getTime();
                    let endTimeNewDate = new Date(endTimeNew).getTime();
                    return (endTimeNewDate <= startTimeOldDate || startTimeNewDate >= endTimeOldDate);
                };
                let date = new Date();
                let dateStr = date.getFullYear() + '-' + (date.getMonth() + 1) + '-' + date.getDate();
                startTime = dateStr + ' ' + startTime;
                endTime = dateStr + ' ' + endTime;
                let tempTimeList;
                if (type === 1) {

                    tempTimeList = this.editForm.forcedChargeTimeList;
                } else {

                    tempTimeList = this.editForm.forcedDisChargeTimeList;
                }
                for (let i = 0; i < tempTimeList.length; i++) {

                    let tempTime = tempTimeList[i];
                    let tempStartTime = dateStr + ' ' + tempTime.startTime;
                    let tempEndTime = dateStr + ' ' + tempTime.endTime;
                    if (!checkTimeRepeat(tempStartTime, tempEndTime, startTime, endTime)) {

                        this.$message.error({

                            message: '该时间段已被包含',
                            center: true
                        });
                        return false;
                    }
                }
                return true;
            },
            validateSocMinAndMax(rule, value, callback) {

                let reg = /^\d+$/;
                if (value === null) {

                    callback(new Error('[1-100]'));
                } else if (!reg.test(value)){

                    callback(new Error('[1-100]'));
                } else if (value < 1 || value > 100) {

                    callback(new Error('[1-100]'));
                } else {

                    callback();
                }
            },
            // 不知道为什么当指令回调成功后，修改该字段的值时无法触发blur事件，只能注册一个blur事件实现校验
            handleForcedChargeSocMaxBlurEvent() {

                this.$refs.editForm.validateField('forcedChargeSocMax');
            },
            openCmdLoading(msg) {

                return loading.loading(msg, 30);
            }
        }
    }
</script>

<style lang="scss" scoped>

    .date-button {

        float: right;
        margin: -8px 260px 0 0;
    }

    .time-button {

        float: right;
        margin: 5px 260px 0 0;
    }

    .table {

        width: 350px;
        float: right;
        margin: 0 260px 10px 0;
    }

    .item {

        width: 200px;
    }

    .switch {

        margin-left: 160px;
    }

    .content {

        overflow: auto;
        margin-left: 180px;
        height: 490px;
    }

    .content::-webkit-scrollbar {

        width: 3px;
    }

    .content::-webkit-scrollbar-thumb {

        background: #8798AF;
        border-radius: 2px;
    }

    .content::-webkit-scrollbar-track {

        background: transparent;
    }
</style>
