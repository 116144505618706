<template>
    <div>
        <el-tabs v-model="activeTabName" type="card">
            <el-tab-pane label="当前" name="realAlarmTab">
                <el-form :inline="true" :model="currentTabSearchForm" size="mini">
                    <el-row>
                        <el-form-item label="告警分类：">
                            <el-select v-model="currentTabSearchForm.alarmClassify" clearable placeholder="全部">
                                <el-option v-for="item in eventClassifyList" :key="item.name" :label="item.name" :value="item.value"/>
                            </el-select>
                        </el-form-item>
                        <el-form-item label="上报时间：">
                            <el-select class="search-timeZone" v-model="currentTabSearchForm.timeZone">
                                <el-option v-for="(item, index) in timeZoneOption" :key="index + item.label" :label="item.label" :value="item.value"/>
                            </el-select>
                            <el-date-picker class="search-date-picker" v-model="realReportTimeRange" type="daterange" value-format="yyyy-MM-dd"
                                            start-placeholder="开始日期" end-placeholder="结束日期"
                                            :picker-options="pickerOptions"/>
                        </el-form-item>
                        <el-form-item label="告警名称：">
                            <el-input v-model="currentTabSearchForm.alarmName" clearable placeholder="请输入告警名称"/>
                        </el-form-item>
                        <el-form-item>
                            <el-button type="primary" @click="queryRealEventRecordList()">查询</el-button>
                        </el-form-item>
                    </el-row>
                </el-form>
                <el-table :data="realTimeAlarmDataList" size="small" max-height="550"
                        :header-cell-style="{background: '#eef1f6', color: '#606266'}">
                    <el-table-column type="index" label="序号" align="center" width="50"/>
                    <el-table-column prop="alarmClassify" label="告警分类" align="center" :formatter="alarmClassifyFormatter"/>
                    <el-table-column prop="alarmName" label="告警名称"/>
                    <el-table-column prop="alarmLevel" label="告警等级" align="center" :formatter="alarmLevelFormatter"/>
                    <el-table-column prop="reportTime" label="上报时间" align="center" width="150"/>
                    <el-table-column label="操作" align="center" width="80">
                        <template slot-scope="scope">
                            <el-button size="mini" type="primary" @click="openAlarmHistoryDialog(scope.row)">展开</el-button>
                        </template>
                    </el-table-column>
                </el-table>
            </el-tab-pane>
            <el-tab-pane label="历史" name="historyAlarmTab">
                <el-form :inline="true" :model="historyTabSearchForm" size="mini">
                    <el-row>
                        <el-form-item label="告警分类：">
                            <el-select v-model="historyTabSearchForm.alarmClassify" clearable placeholder="全部">
                                <el-option v-for="item in eventClassifyList" :key="item.name" :label="item.name" :value="item.value"/>
                            </el-select>
                        </el-form-item>
                        <el-form-item label="上报时间：">
                            <el-select class="search-timeZone" v-model="historyTabSearchForm.timeZone">
                                <el-option v-for="(item, index) in timeZoneOption" :key="index + item.label" :label="item.label" :value="item.value"/>
                            </el-select>
                            <el-date-picker class="search-date-picker" v-model="historyReportTimeRange" type="daterange" value-format="yyyy-MM-dd"
                                            start-placeholder="开始日期" end-placeholder="结束日期" :picker-options="pickerOptions">
                            </el-date-picker>
                        </el-form-item>
                        <el-form-item label="告警名称：">
                            <el-input v-model="historyTabSearchForm.alarmName" clearable placeholder="请输入告警名称"/>
                        </el-form-item>
                        <el-form-item>
                            <el-button type="primary" @click="queryEventHistoryRecordList()">查询</el-button>
                        </el-form-item>
                    </el-row>
                </el-form>
                <el-table :data="historyAlarmDataList" size="small" max-height="550"
                        :header-cell-style="{background: '#eef1f6', color: '#606266'}">
                    <el-table-column type="index" label="序号" align="center" width="50"/>
                    <el-table-column prop="alarmClassify" label="告警分类" align="center" :formatter="alarmClassifyFormatter"/>
                    <el-table-column prop="alarmName" label="告警名称" />
                    <el-table-column prop="alarmLevel" label="告警等级" align="center" :formatter="alarmLevelFormatter"/>
                    <el-table-column prop="reportTime" label="上报时间" align="center" width="150"/>
                    <el-table-column label="操作" align="center" width="80">
                        <template slot-scope="scope">
                            <el-button size="mini" type="primary" @click="openAlarmHistoryDialog(scope.row)">展开</el-button>
                        </template>
                    </el-table-column>
                </el-table>
            </el-tab-pane>
        </el-tabs>
        <el-dialog title="告警历史" :visible.sync="alarmTimelineDialogVisible" append-to-body width="540px"
                   :before-close="handleAlarmHistoryDialogClose" :close-on-click-modal="false" top="5vh">
            <el-form :inline="true" :model="timeLineDataSearchForm" size="mini">
                <el-row>
                    <el-form-item label="上报时间">
                        <el-select class="search-timeZone" v-model="timeLineDataSearchForm.timeZone">
                            <el-option v-for="(item, index) in timeZoneOption" :key="index + item.label" :label="item.label" :value="item.value"/>
                        </el-select>
                        <el-date-picker class="search-date-picker" v-model="timelineReportTimeRange" type="daterange" value-format="yyyy-MM-dd"
                                        start-placeholder="开始日期" end-placeholder="结束日期"
                                        :picker-options="pickerOptions">
                        </el-date-picker>
                    </el-form-item>
                    <el-form-item>
                        <el-button type="primary" @click="queryDeviceEventHistoryRecordList(0)">查询</el-button>
                    </el-form-item>
                </el-row>
            </el-form>
            <div class="alarm-dialog-time">
                <el-timeline>
                    <el-timeline-item v-for="(alarmData, index) in alarmDataTimeLine"
                                      :key="index"
                                      :color="alarmData.color"
                                      :timestamp="alarmData.reportTime">
                        <span v-if="alarmData.alarmValue === '0'" style="color:#409EFF">{{alarmData.alarmName}}-解除</span>
                        <span v-if="alarmData.alarmValue === '1' && alarmData.alarmLevel === '1'" style="color:#F56C6C">{{alarmData.alarmName}}-发生</span>
                        <span v-if="alarmData.alarmValue === '1' && alarmData.alarmLevel === '2'" style="color:#E6A23C">{{alarmData.alarmName}}-发生</span>
                        <span v-if="alarmData.alarmValue === '1' && alarmData.alarmLevel === '3'" style="color:#000000">{{alarmData.alarmName}}-发生</span>
                    </el-timeline-item>
                </el-timeline>
            </div>
            <el-button type="text" @click="queryDeviceEventHistoryRecordList(10)" v-show="showMoreButton" class="alarm-dialog-more">更多</el-button>
        </el-dialog>
    </div>
</template>

<script>
    import { queryEventClassifyList } from '@/api/esaio/device/esaioDeviceInfo';
    import { queryRealEventRecordList, queryEventHistoryRecordList, queryDeviceEventHistoryRecordList } from '@/api/esaio/event/esaioEventRecordApi';
    import Global from '@/components/Global';
    import message from '@/utils/message';

    export default {

    name: 'alarm-data',
    props: {

        deviceInfo: {

            type: Object
        }
    },
    data() {

        return {

            activeTabName: 'realAlarmTab',
            realReportTimeRange: [],
            historyReportTimeRange: [],
            timelineReportTimeRange: [],
            currentTabSearchForm: {

                deviceId: null,
                alarmClassify: '',
                alarmName: '',
                startDate: '',
                endDate: '',
                timeZone: 8
            },
            historyTabSearchForm: {

                deviceId: null,
                alarmClassify: '',
                alarmName: '',
                startDate: '',
                endDate: '',
                timeZone: 8
            },
            timeLineDataSearchForm: {

                alarmDefineId: '',
                startDate: '',
                endDate: '',
                pageSize: 0,
                timeZone: 8
            },
            realTimeAlarmDataList: [],
            historyAlarmDataList: [],
            eventClassifyList: [],
            alarmTimelineDialogVisible: false,
            alarmDataTimeLine: [],
            showMoreButton: true,
            pickerOptions: {

                disabledDate(time) {

                    return time.getTime() > Date.now();
                }
            },
            timeZoneOption:[
                { label: 'UTC+14', value: 14},
                { label: 'UTC+13', value: 13},
                { label: 'UTC+12', value: 12},
                { label: 'UTC+11', value: 11},
                { label: 'UTC+10', value: 10},
                { label: 'UTC+9', value: 9},
                { label: 'UTC+8', value: 8},
                { label: 'UTC+7', value: 7},
                { label: 'UTC+6', value: 6},
                { label: 'UTC+5', value: 5},
                { label: 'UTC+4', value: 4},
                { label: 'UTC+3', value: 3},
                { label: 'UTC+2', value: 2},
                { label: 'UTC+1', value: 1},
                { label: 'UTC', value: 0},
                { label: 'UTC-1', value: -1},
                { label: 'UTC-2', value: -2},
                { label: 'UTC-3', value: -3},
                { label: 'UTC-4', value: -4},
                { label: 'UTC-5', value: -5},
                { label: 'UTC-6', value: -6},
                { label: 'UTC-7', value: -7},
                { label: 'UTC-8', value: -8},
                { label: 'UTC-9', value: -9},
                { label: 'UTC-10', value: -10},
                { label: 'UTC-11', value: -11},
                { label: 'UTC-12', value: -12}
            ]
        }
    },
    methods: {

        initPage() {

            this.currentTabSearchForm.deviceId = this.deviceInfo.id;
            this.historyTabSearchForm.deviceId = this.deviceInfo.id;
            this.queryEventClassifyList();
            this.queryRealEventRecordList();
            this.queryEventHistoryRecordList();
        },
        queryEventClassifyList() {

            queryEventClassifyList().then(res => {

                if (res.code === Global.response_status_success_obj) {

                    this.eventClassifyList = res.data;
                } else {

                    message.error(res.msg);
                }
            }).catch(err => {

                message.error('请求失败,请稍后再试');
                console.error(err);
            });
        },
        // 获取设备告警实时数据
        queryRealEventRecordList() {

            this.currentTabSearchForm.startDate = null;
            this.currentTabSearchForm.endDate = null;
            if (this.realReportTimeRange && this.realReportTimeRange.length > 1) {

                this.currentTabSearchForm.startDate = this.realReportTimeRange[0];
                this.currentTabSearchForm.endDate = this.realReportTimeRange[1];
            }
            queryRealEventRecordList(this.currentTabSearchForm).then(res => {

                if (res.code === Global.response_status_success_obj) {

                    this.realTimeAlarmDataList = res.data;
                } else {

                    message.error(res.msg);
                }
            }).catch(err => {

                message.error('请求失败,请稍后再试');
                console.error(err);
            });
        },
        // 获取设备告警历史数据
        queryEventHistoryRecordList() {

            this.historyTabSearchForm.startDate = null;
            this.historyTabSearchForm.endDate = null;
            if (this.historyReportTimeRange && this.historyReportTimeRange.length > 1) {

                this.historyTabSearchForm.startDate = this.historyReportTimeRange[0];
                this.historyTabSearchForm.endDate = this.historyReportTimeRange[1];
            }
            queryEventHistoryRecordList(this.historyTabSearchForm).then(res => {

                if (res.code === Global.response_status_success_obj) {

                    this.historyAlarmDataList = res.data;
                } else {

                    message.error(res.msg);
                }
            }).catch(err => {

                message.error('请求失败,请稍后再试');
                console.error(err);
            });
        },
        // 获取告警时间线数据
        queryDeviceEventHistoryRecordList(limit) {

            this.timeLineDataSearchForm.startDate = null;
            this.timeLineDataSearchForm.endDate = null;
            if (this.timelineReportTimeRange && this.timelineReportTimeRange.length > 1) {

                this.timeLineDataSearchForm.startDate = this.timelineReportTimeRange[0];
                this.timeLineDataSearchForm.endDate = this.timelineReportTimeRange[1];
            }
            this.timeLineDataSearchForm.pageSize = Number(this.timeLineDataSearchForm.pageSize) +  Number(limit);
            this.timeLineDataSearchForm.pageNum = 1;
            this.timeLineDataSearchForm.deviceId = this.deviceInfo.id;
            queryDeviceEventHistoryRecordList(this.timeLineDataSearchForm).then(res => {

                if (res.code === Global.response_status_success_obj) {

                    this.alarmDataTimeLine = res.data.result;
                    this.showMoreButton = res.data.result.length !== res.data.total;
                    this.alarmDataTimeLine.forEach(element => {

                        if (element.alarmValue === '0') {

                            element.color = '#409EFF';
                        } else if (element.alarmValue === '1' && element.alarmLevel === '1') {

                            element.color = '#F56C6C';
                        } else if (element.alarmValue === '1' && element.alarmLevel === '2') {

                            element.color = '#E6A23C';
                        } else if (element.alarmValue === '1' && element.alarmLevel === '3') {

                            element.color = '#000000';
                        }
                    });
                } else {

                    message.error(res.msg);
                }
            }).catch(err => {

                message.error('请求失败,请稍后再试');
                console.error(err);
            })
        },
        // 打开告警历史框
        openAlarmHistoryDialog(row) {

            this.alarmTimelineDialogVisible = true;
            this.timelineReportTimeRange = [];
            this.timeLineDataSearchForm.alarmDefineId = row.alarmDefineId;
            this.queryDeviceEventHistoryRecordList(10);
        },
        handleAlarmHistoryDialogClose(done) {

            this.timeLineDataSearchForm.limit = 0;
            this.timeLineDataSearchForm.pageSize = 0;
            done();
        },
        alarmLevelFormatter(row, column, cellValue) {

            if (cellValue === '1') {

                return <span style="color:#F56C6C">故障</span>;
            } else if (cellValue === '2') {

                return <span style="color:#E6A23C">告警</span>;
            } else if (cellValue === '3') {

                return <span style="color:#409EFF">通知</span>
            } else {

                return '-';
            }
        },
        alarmClassifyFormatter(row, column, cellValue) {

            let eventClassify = this.eventClassifyList.find(item => {

                return item.value === cellValue;
            });
            if (eventClassify) {

                return eventClassify.name;
            } else {

                return '-';
            }
        }
    }
}
</script>
<style lang="scss" scoped>

    .alarm-dialog-time {

        height: 610px;
        overflow-y: auto;
    }
    .alarm-dialog-more {

        margin-left: 35px;
    }
    .search-timeZone {

        width: 95px;
    }
    .search-date-picker {

        width: 250px;
        margin-left: 5px;
    }
</style>
