<template>
    <div>
        <NavBreadcrumb></NavBreadcrumb>
        <div class="panel-style device">
            <el-form :inline="true" size="mini">
                <el-form-item label="归属站点">
                    <el-input class="normal-item" v-model="searchForm.stationName" clearable placeholder="站点名称"/>
                </el-form-item>
                <el-form-item label="产品型号">
                    <el-select placeholder="全部" clearable v-model="searchForm.productModelId">
                        <el-option v-for="item in productModelOption" :key="item.id" :label="item.name" :value="item.id">
                        </el-option>
                    </el-select>
                </el-form-item>
                <el-form-item label="设备编号" class="sn-input">
                    <el-input v-model="searchForm.serialNumber" placeholder="设备编号" clearable/>
                </el-form-item>
                <el-form-item label="设备名称">
                    <el-input class="normal-item" v-model="searchForm.invName" placeholder="设备名称" clearable/>
                </el-form-item>
                <el-form-item label="创建时间">
                    <el-date-picker v-model="createTimeRange" type="daterange" value-format="yyyy-MM-dd"
                                    start-placeholder="开始日期" end-placeholder="结束日期"
                                    :picker-options="pickerOptions" style="width: 200px;">
                    </el-date-picker>
                </el-form-item>
                <el-form-item>
                    <el-button type="primary" @click="handleQueryClickEvent">查询</el-button>
                </el-form-item>
            </el-form>
            <el-table :data="tableData" border size="small" stripe style="width: 100%" max-height="550px"
                      :header-cell-style="{background: '#eef1f6', color: '#606266'}" v-loading="listLoading"
                      @sort-change="handleSortChangeEvent">
                <el-table-column prop="stationName" label="归属站点" sortable="custom"/>
                <el-table-column prop="serialNumber" label="设备编号" width="200px" sortable="custom"/>
                <el-table-column prop="invName" label="设备名称" width="200px" />
                <el-table-column prop="comstickNumber" label="通讯棒编号" align="center" :formatter="comstickFormatter" width="160px"/>
                <el-table-column prop="comstickKey" label="通讯棒key" align="center" :formatter="comstickFormatter" width="120px"/>
                <el-table-column prop="productModelName" label="产品型号" width="150px"/>
                <el-table-column prop="isOnline" label="是否在线" width="80px" align="center" :formatter="isOnlineFormatter"/>
                <el-table-column prop="lastReportTime" label="最后上报时间" width="150px" align="center" :formatter="lastReportTimeFormatter"/>
                <el-table-column prop="createTime" label="创建时间" width="150px" align="center" sortable="custom"/>
                <el-table-column fixed="right" label="操作" width="120px" align="center">
                    <template v-slot="scope">
                        <div class="option-btn-div">
                            <el-tooltip v-delTabIndex effect="dark" content="详细" placement="top">
                                <i class="el-icon-my-info option-btn-normal" @click="openDeviceDetailPage(scope.row)"/>
                            </el-tooltip>
                            <el-tooltip v-delTabIndex effect="dark" content="指令" placement="top">
                                <i class="el-icon-setting option-btn-normal" @click="openDeviceCmdPage(scope.row)"/>
                            </el-tooltip>
                            <el-tooltip v-delTabIndex effect="dark" content="日志" placement="top">
                                <i class="el-icon-document option-btn-normal" @click="openCommandLogPage(scope.row)"/>
                            </el-tooltip>
                        </div>
                    </template>
                </el-table-column>
            </el-table>
            <div class="pagination">
                <el-pagination
                        background
                        layout="total, sizes, prev, pager, next, jumper"
                        :total="tableTotal"
                        :current-page.sync="searchForm.pageNum"
                        :page-sizes="[10, 50, 100]"
                        :page-size="searchForm.pageSize"
                        @size-change="handleSizeChangeEvent"
                        @current-change="handleCurrentChangeEvent"/>
            </div>
            <device-detail ref="deviceDetailPage" @queryEsaioDeviceInfoList="queryEsaioDeviceInfoList"></device-detail>
            <device-cmd ref="deviceCmdPage" :deviceInfo=curOptionDeviceInfo :serverList=serverList
                @queryEsaioDeviceInfoList="queryEsaioDeviceInfoList"></device-cmd>
            <device-modbus-cmd ref="deviceModbusCmdPage" :deviceInfo=curOptionDeviceInfo
                @queryEsaioDeviceInfoList="queryEsaioDeviceInfoList"></device-modbus-cmd>
            <device-schf5k-cmd ref="schf5kCmdPage" :deviceInfo=curOptionDeviceInfo
                @queryEsaioDeviceInfoList="queryEsaioDeviceInfoList"></device-schf5k-cmd>
            <el-dialog title="指令日志" center :visible.sync="commandLogPageVisible" width="1500px" top="3vh">
                <EsaioOperationLog ref="esaioOperationLogPage"/>
            </el-dialog>
        </div>
    </div>
</template>

<script>
    import {
        queryEsaioDeviceInfoList,
        queryProductModelList,
        queryServerTypeList
    } from '@/api/esaio/device/esaioDeviceInfo';
    import NavBreadcrumb from '@/components/NavBreadcrumb';
    import DeviceCmd from '@/views/esaio/device/device-cmd.vue';
    import DeviceDetail from '@/views/esaio/device/device-detail/device-data-detail.vue';
    import EsaioOperationLog from '@/views/esaio/oplog/operation-log-list.vue';
    import DeviceModbusCmd from '@/views/esaio/device/device-modbus-cmd';
    import Global from '@/components/Global';
    import DeviceSchf5kCmd from "@/views/esaio/device/device-schf5k-cmd.vue";

    export default {

        name: 'device-list',
        components: {
            DeviceDetail, NavBreadcrumb, DeviceCmd, EsaioOperationLog, DeviceModbusCmd, DeviceSchf5kCmd},
        data() {

            return {

                curOptionDeviceInfo: null,
                serverList: [],
                commandLogPageVisible: false,
                devicePointDataPageVisible: false,
                listLoading: false,
                productModelOption: [],
                selectedDeviceSn: '',
                productModelCode: '',
                searchForm: {

                    pageNum: 1,
                    pageSize: 10,
                    stationName: '',
                    productModelId: null,
                    serialNumber: null,
                    invName: '',
                    createTimeStart: '',
                    createTimeEnd: '',
                    sortColumn: '',
                    sort: ''
                },
                tableData: [],
                tableTotal: 0,
                createTimeRange: '',
                pickerOptions: {

                    disabledDate(time) {

                        return time.getTime() > Date.now();
                    }
                },
            };
        },
        methods: {

            initDeviceListPage() {

                this.initProductModelOption();
                this.initTableData();
                this.queryServerTypeList();
            },
            initProductModelOption() {

                queryProductModelList().then(res => {

                    if (res.code === Global.response_status_success_obj) {

                        this.productModelOption = res.data;
                    } else {

                        message.error(res.msg);
                    }
                }).catch(err => {

                    message.error('请求失败,请稍后再试');
                    console.error(err);
                });
            },
            queryServerTypeList() {

                queryServerTypeList().then(res => {

                    if (res.code === Global.response_status_success_obj) {

                        this.serverList = res.data;
                    } else {

                        message.error(res.msg);
                    }
                }).catch(err => {

                    message.error('请求失败,请稍后再试');
                    console.error(err);
                });
            },
            handleSortChangeEvent({prop, order}) {

                if (order) {

                    this.searchForm.sortColumn = prop;
                    this.searchForm.sort = order;
                } else {

                    this.searchForm.sortColumn = undefined;
                    this.searchForm.sort = undefined;
                }
                this.queryEsaioDeviceInfoList();
            },
            handleQueryClickEvent() {

                this.searchForm.pageNum = 1;
                this.queryEsaioDeviceInfoList();
            },
            queryEsaioDeviceInfoList() {

                if (this.createTimeRange && this.createTimeRange.length > 1) {

                    this.searchForm.createTimeStart = this.createTimeRange[0];
                    this.searchForm.createTimeEnd = this.createTimeRange[1];
                } else {

                    this.searchForm.createTimeStart = null;
                    this.searchForm.createTimeEnd = null;
                }
                queryEsaioDeviceInfoList(this.searchForm).then(res => {

                    if (res.code === Global.response_status_success_obj) {

                        this.tableData = res.data.result;
                        this.tableTotal = res.data.total;
                    } else {

                        message.error(res.msg);
                    }
                }).catch(err => {

                    message.error('请求失败,请稍后再试');
                    console.error(err);
                });
            },
            initTableData() {

                this.searchForm.pageNum = 1;
                this.searchForm.pageSize = 10;
                this.queryEsaioDeviceInfoList();
            },
            handleSizeChangeEvent(val) {

                this.searchForm.pageSize = val;
                this.queryEsaioDeviceInfoList();
            },
            handleCurrentChangeEvent(val) {

                this.searchForm.pageNum = val;
                this.queryEsaioDeviceInfoList();
            },
            openDeviceDetailPage(row) {

                this.$refs.deviceDetailPage.initDeviceDetailPage(row);
            },
            openDeviceCmdPage(row) {

                this.curOptionDeviceInfo = row;
                if (row.productModelCode === 'AioMini' || row.productModelCode === 'DRPVG50T') {

                    this.$refs.deviceModbusCmdPage.initDeviceModbusCmdPage(row.productModelCode, row.serialNumber);
                } else if (row.productModelCode === 'SCHF5K') {

                    this.$refs.schf5kCmdPage.initSchf5kCmdPage();
                } else {

                    this.$nextTick(() => {

                        this.$refs.deviceCmdPage.initDeviceCmdPage();
                    });
                }
            },
            openCommandLogPage(row) {

                this.commandLogPageVisible = true;
                this.$nextTick(() => {

                    this.$refs.esaioOperationLogPage.asComponentInitPage(row.serialNumber, row.productModelId);
                })
            },
            comstickFormatter(row, column, cellValue) {

                if (row.commMode === '0' && cellValue) {

                    return cellValue;
                }
                return '--';
            },
            lastReportTimeFormatter(row, column, cellValue) {

                return cellValue ? cellValue : '-';
            },
            isOnlineFormatter(row, column, cellValue) {

                // 在线并且升级中，显示升级中
                if (row.isUpgrade) {

                    return <el-tooltip effect="dark" content="固件升级中" placement="right"><div class="upgrade-style"/></el-tooltip>;
                } else if (cellValue) {

                    return <el-tooltip effect="dark" content="在线" placement="right"><div class="online-style"/></el-tooltip>;
                } else {

                    return <el-tooltip effect="dark" content="离线" placement="right"><div class="offline-style"/></el-tooltip>;
                }
            }
        },
        mounted() {

            this.initDeviceListPage();
        }
    }
</script>

<style lang="scss" scoped>

    .pagination {

        margin-top: 15px;
        text-align: right;
    }
    .option-btn-normal {

        font-size: 18px;
        color: #409EFF;
        cursor: pointer;
    }
    .el-icon-my-info {

        height: 18px;
        width: 18px;
        background-image: url('../../../assets/icon/button/info.svg');
        background-size: cover;
        background-repeat: no-repeat;
    }
    .status {

        width: 10px;
        height: 10px;
        border-radius: 50%;
        margin-left: 45%;
        cursor: pointer;
    }
    .online-style {

        @extend .status;
        background: #67C23A;
    }
    .offline-style {

        @extend .status;
        background: #B3ABAB;
    }
    .upgrade-style {

        @extend .status;
        background: #f1b04f;
    }
</style>
<style lang="scss">

    .option-btn-div {

        display: flex;
        justify-content: space-around
    }
    .normal-item {

        width: 200px;
    }
    .sn-input .el-input {

        width: 220px;
    }
</style>
