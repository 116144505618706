<template>
    <div>
        <el-form :inline="true" size="mini" class="form-box">
            <el-form-item label="上报时间">
                <el-select placeholder="全部" v-model="searchForm.utcValue" @change="timeZoneChange">
                    <el-option v-for="(item, index) in timeZoneOption" :key="index" :label="item" :value="item"/>
                </el-select>
            </el-form-item>
            <el-form-item>
                <el-date-picker v-model="searchForm.date" type="date" value-format="yyyy-MM-dd" :clearable="false"
                    placeholder="选择日期" :picker-options="pickerDateOptions" @change="pickerDateChange"></el-date-picker>
                <span class="line">-</span>
                <el-time-select v-model="searchForm.time" :picker-options="pickerTimeOptions" :clearable="false"
                    placeholder="选择时间">
                </el-time-select>
            </el-form-item>
            <el-form-item>
                <el-button type="primary" @click="queryImportantMonitorList()">查询</el-button>
                <el-button type="primary" @click="openComparePage()">对比</el-button>
                <el-button type="primary" @click="openExportPage()">导出</el-button>
            </el-form-item>
        </el-form>
        <el-table ref="multipleTable" :data="importantDataArray" size="small" height="680"
                  :header-cell-style="{background: '#eef1f6', color: '#606266'}"
                  :cell-class-name="getCellClassName" v-loading="listLoading">
            <el-table-column type="selection" width="50" :selectable="handleSelectableEvent"/>
            <el-table-column type="index" label="序号" width="50" align="center"/>
            <el-table-column prop="name" label="名称" align="center"/>
            <el-table-column prop="value" label="数值" align="center" :formatter="valueFormatter"/>
            <el-table-column prop="unit" label="单位" align="center" width="80"/>
            <el-table-column prop="dataShouldReportCount" label="应上报次数" width="80" align="center" :formatter="commFormatter"/>
            <el-table-column prop="dataActualReportCount" label="实际上报次数" width="95" align="center" :formatter="commFormatter"/>
            <el-table-column prop="commSuccessRate" label="通讯成功率" width="80" align="center" :formatter="commFormatter"/>
            <el-table-column prop="state" label="状态" width="80" align="center" :formatter="stateFormatter"/>
            <el-table-column label="操作" align="center" width="60">
                <template v-slot="scope">
                    <div v-if="scope.row.selectionEnable" class="statistics">
                        <el-tooltip v-delTabIndex effect="dark" content="统计" placement="top">
                            <i class="statistics-btn option-btn-normal" @click="openChartPage(scope.row)"/>
                        </el-tooltip>
                    </div>
                </template>
            </el-table-column>
        </el-table>
        <el-dialog width="500px" :visible.sync="exportDialogVisible" :close-on-click-modal="false" v-if="exportDialogVisible"
                   append-to-body :before-close="closeExportDialog" center title="导出监测数据">
            <div style="text-align: center;">
                <el-date-picker v-model="exportDateRange" type="daterange" value-format="yyyy-MM-dd"
                    start-placeholder="开始日期" end-placeholder="结束日期" :picker-options="pickerDateRangeOptions">
                </el-date-picker>
            </div>
            <span slot="footer">
                <el-button type="primary" @click="exportImportantMonitorData()" :loading="exportLoading">确定</el-button>
                <el-button @click="exportDialogVisible = false">取消</el-button>
            </span>
        </el-dialog>
        <el-dialog width="1220px" top="0" :visible.sync="lineChartDialogVisible" :close-on-click-modal="false"
                   append-to-body :before-close="closeLineChartDialog" v-if="lineChartDialogVisible">
            <el-form :inline="true" size="mini" style="text-align: right">
                <el-form-item>
                    <el-date-picker v-model="reportTimeRange" type="daterange" value-format="yyyy-MM-dd"
                                    start-placeholder="开始日期" end-placeholder="结束日期" @change="handleReportTimeRangeChangeEvent"
                                    :picker-options="pickerDateRangeOptions">
                    </el-date-picker>
                </el-form-item>
                <el-form-item>
                    <el-button type="primary" @click="exportDataJs">导出</el-button>
                </el-form-item>
            </el-form>
            <el-row class="successRateTitle">
                通讯成功率
            </el-row>
            <el-row>
                <el-form class="successRateForm" :inline="true" size="mini">
                    <el-form-item class="successRateItem" v-for="item in commSuccessRateDataList" :key="item.fieldCn" :label="item.fieldCn + '：'">
                        {{item.commSuccessRate}}%
                    </el-form-item>
                </el-form>
            </el-row>
            <div v-show="haveData" class="have-data">暂无数据</div>
            <div id="importantDataChartId" style="width: 1200px; height: 500px; margin: 15px 0 0 5px;"></div>
        </el-dialog>
    </div>
</template>

<script>
import ExportJsonExcel from 'js-export-excel';
import Global from '@/components/Global';
import DateUtil from '@/utils/dateUtil';
import message from '@/utils/message';
import {queryRealImportantMonitorDataList, exportImportantMonitorData, queryHistoryImportantMonitorDataList} from '@/api/esaio/device/esaioDeviceInfo';

export default {

    name: 'important-monitor-data',
    props: {

        deviceInfo: {

            type: Object
        }
    },
    data() {

        let self = this;
        return {

            eCharts: require('echarts'),
            searchForm: {

                utcValue: 'UTC+8',
                // 根据时区获取当前日期
                date: '',
                // 若当前时间非整点获取最近的上一个五分钟,比如17:08显示17:05
                time: ''
            },
            timeZoneOption:[
                'UTC+14',
                'UTC+13',
                'UTC+12',
                'UTC+11',
                'UTC+10',
                'UTC+9',
                'UTC+8',
                'UTC+7',
                'UTC+6',
                'UTC+5',
                'UTC+4',
                'UTC+3',
                'UTC+2',
                'UTC+1',
                'UTC',
                'UTC-1',
                'UTC-2',
                'UTC-3',
                'UTC-4',
                'UTC-5',
                'UTC-6',
                'UTC-7',
                'UTC-8',
                'UTC-9',
                'UTC-10',
                'UTC-11',
                'UTC-12'
            ],
            pickerDateOptions: {

                disabledDate(time) {

                    return time.getTime() > Date.now();
                }
            },
            pickerTimeOptions: {

                start: '00:00',
                step: '00:05',
                end: '23:55'
            },
            // 根据时区计算出的最大可选日期
            maxDate: '',
            importantDataArray: [],
            selectionEnableFiledNameList: [
                'stationName',
                'deviceNum',
                'productModelName',
                'lastOnlineTime',
                'isOnline',
                'pcuVersion',
                'dspVersion',
                'dataShouldReportCount',
                'dataActualReportCount',
                'commSuccessRate'
            ],
            exportDialogVisible: false,
            exportDateRange: [DateUtil.getDateStr(new Date()), DateUtil.getDateStr(new Date())],
            firstSelectDate: '',
            pickerDateRangeOptions: {

                // 只允许查询7天范围的数据
                onPick({maxDate, minDate}) {

                    if (minDate && !maxDate) {

                        self.firstSelectDate = minDate.getTime();
                    }
                    if (maxDate) {

                        self.firstSelectDate = '';
                    }
                },
                disabledDate(time) {

                    if (self.firstSelectDate) {

                        let timeRange = 6 * 24 * 60 * 60 * 1000;
                        return time.getTime() > self.firstSelectDate + timeRange ||
                            time.getTime() < self.firstSelectDate - timeRange || time.getTime() > Date.now();
                    } else {

                        return time.getTime() > Date.now();
                    }
                }
            },
            exportForm: {

                deviceInfoId: null,
                startDate: '',
                endDate: ''
            },
            exportLoading: false,
            lineChartDialogVisible: false,
            haveData: false,
            lineChartSearchForm: {

                deviceInfoId: null,
                startDate: '',
                endDate: '',
                data: []
            },
            reportTimeRange: [DateUtil.getDateStr(new Date()), DateUtil.getDateStr(new Date())],
            // 表格中被选中查看折线图的行数据
            rowData: {},
            workModeInfo: {

                '1' : '通用模式',
                '2' : '储能模式',
                '3' : '微网模式',
                '4' : '峰谷模式',
                '5' : '指令模式',
                '6' : 'AC耦合模式'
            },
            echartsData: [],
            legendArray: [],
            listLoading: false,
            commSuccessRateDataList: []
        }
    },
    methods: {

        initPage() {

            // 初始化日期组件
            this.getCurrentDate();
            this.queryImportantMonitorList();
        },
        queryImportantMonitorList() {

            if (this.searchForm.date === '') {

                message.warning('日期不能为空');
                return;
            }
            if (this.searchForm.time === '') {

                message.warning('时间不能为空');
                return;
            }
            let param = {

                deviceInfoId: this.deviceInfo.id,
                reportTime: this.searchForm.date + " " + this.searchForm.time + ':00',
                utcValue: this.searchForm.utcValue
            }
            this.listLoading = true;
            queryRealImportantMonitorDataList(param).then(res => {

                this.listLoading = false;
                if (res.code === Global.response_status_success_obj) {

                    res.data.forEach((item) => {

                        item.selectionEnable = true;
                    })
                    this.importantDataArray = res.data;
                } else {

                    message.error(res.msg);
                }
            }).catch(() => {

                message.error('请求失败,请稍后再试');
            });
        },
        openExportPage() {

            this.exportDateRange = [DateUtil.getDateStr(new Date()), DateUtil.getDateStr(new Date())];
            this.exportDialogVisible = true;
        },
        exportImportantMonitorData() {

            if (this.exportDateRange && this.exportDateRange.length > 1) {

                this.exportForm.startDate = this.exportDateRange[0];
                this.exportForm.endDate = this.exportDateRange[1];
            } else {

                message.warning('日期不能为空');
                return;
            }
            this.exportForm.utcValue = this.searchForm.utcValue;
            this.exportForm.deviceInfoId = this.deviceInfo.id;
            this.exportLoading = true;
            exportImportantMonitorData(this.exportForm).then(res => {

                this.exportLoading = false;
                let blob = new Blob([res]);
                let fileName = "重点监测数据" + ".xlsx";
                let elink = document.createElement('a');
                elink.download = fileName;
                elink.href = URL.createObjectURL(blob);
                document.body.appendChild(elink);
                elink.click();
                URL.revokeObjectURL(elink.href);
                document.body.removeChild(elink);
                this.exportDialogVisible = false;
            });
        },
        closeExportDialog(done) {

            this.exportDateRange = [DateUtil.getDateStr(new Date()), DateUtil.getDateStr(new Date())];
            this.exportForm = {

                deviceInfoId: null,
                startDate: '',
                endDate: ''
            }
            done();
        },
        openComparePage() {

            if (this.$refs.multipleTable.selection.length < 2) {

                message.warning('至少选择两条数据');
                return;
            }
            this.lineChartDialogVisible = true;
            this.queryImportantMonitorData();
        },
        openChartPage(row) {

            this.rowData = row;
            this.lineChartDialogVisible = true;
            this.queryImportantMonitorData(row);
        },
        queryImportantMonitorData(row) {

            if (this.reportTimeRange && this.reportTimeRange.length > 1) {

                this.lineChartSearchForm.startDate = this.reportTimeRange[0];
                this.lineChartSearchForm.endDate = this.reportTimeRange[1];
            } else {

                message.warning('日期不能为空');
                return;
            }
            this.lineChartSearchForm.deviceInfoId = this.deviceInfo.id;
            this.lineChartSearchForm.utcValue = this.searchForm.utcValue;
            let searchData = [];
            this.rowData = null;
            if (row) {

                this.rowData = row;
                // 查看其中的一行的信息
                searchData.push({

                    name: row.name,
                    fieldName: row.fieldName,
                    unit: row.unit
                });
                this.legendArray.push(row.name);
            } else {

                // 获取选中的table行
                let checkData = this.$refs.multipleTable.selection;
                for (let i = 0; i < checkData.length; i++) {

                    searchData.push({

                        name: checkData[i].name,
                        fieldName: checkData[i].fieldName,
                        unit: checkData[i].unit
                    });
                    this.legendArray.push(checkData[i].name);
                }
            }
            this.lineChartSearchForm.data = searchData;
            queryHistoryImportantMonitorDataList(this.lineChartSearchForm).then(res => {

                if (res.code === Global.response_status_success_obj) {
                    // 计算通讯成功率
                    this.calLineChartCommSuccessRate(res.data.valueMap);
                    if (res.data.reportTime.length === 0) {

                        this.haveData = true;
                        this.eCharts.init(document.getElementById('importantDataChartId')).dispose();
                        this.dealEChartData();
                    } else {

                        this.haveData = false;
                        this.dealEChartData(res.data);
                    }
                    this.echartsData = res.data;
                } else {

                    message.error(res.msg);
                }
            }).catch(() => {

                message.error('请求失败,请稍后再试');
            });
        },
        closeLineChartDialog(done) {

            this.eCharts.init(document.getElementById('importantDataChartId')).dispose();
            this.reportTimeRange = [DateUtil.getDateStr(new Date()), DateUtil.getDateStr(new Date())];
            this.lineChartSearchForm = {

                deviceInfoId: null,
                startDate: '',
                endDate: '',
                data: []
            }
            done();
        },
        handleReportTimeRangeChangeEvent() {

            this.queryImportantMonitorData(this.rowData);
        },
        exportDataJs() {

            if (this.echartsData.reportTime === null || this.echartsData.valueMap === null) {

                message.warning('暂无数据');
                return;
            }
            /**
             * 定义表头，table需要的格式：[{
             *
             *      "上报时间": "2024-02-19 00:00:00",
             *      "二级母线1（V）": "310.4",
             *      "二级母线2（V）": "310.5",
             *      ...
             * }]
             */
            let tableData = [], tableTitle = ['上报时间'], columnWidths = [8];
            let data = this.echartsData.valueMap;
            let reportTime = this.echartsData.reportTime;
            let keyProperties = Object.keys(data).filter(key => data[key].value && data[key].value.length === reportTime.length);
            reportTime.forEach((time, index) => {

                let row = { "上报时间": time };
                // 遍历所有具有value数组的属性
                keyProperties.forEach(key => {

                    let title = data[key].name;
                    if (data[key].unit !== '--') {

                        // 给表头拼接单位
                        title = title + '(' + data[key].unit + ')';
                    }
                    if (!tableTitle.includes(title)) {

                        tableTitle.push(title);
                        if (title.length > 10) {

                            columnWidths.push(9);
                        } else {

                            columnWidths.push(6);
                        }
                    }
                    let value = data[key].value[index];
                    if (value === '') {

                        value = '--';
                    } else {

                        if (key === 'workMode') {

                            value = this.workModeInfo[value];
                        }
                    }
                    // 为当前时间点添加属性值
                    row[title] = value;
                });
                // 将行对象添加到tableData数组中
                tableData.push(row);
            });
            //下载表格参数
            let option = {

                fileName : '重点监测数据统计',
                datas : [
                    {
                        // sheet名(可有可无，默认 sheet1)
                        sheetName: '重点监测数据统计',
                        // 数据源
                        sheetData: tableData,
                        // 表头数据
                        sheetHeader: tableTitle,
                        // 列宽
                        columnWidths: columnWidths
                    }
                ]
            }
            let toExcel = new ExportJsonExcel(option);
            toExcel.saveExcel();
        },
        dealEChartData(echartsData) {

            if (echartsData) {

                let data = echartsData.valueMap;
                let seriesData = [];
                let tooltipMap = new Map();
                let color = [
                    // '#5470c6',
                    '#d81e06',
                    '#91cc75',
                    '#87ceeb',
                    '#ee6666',
                    '#73c0de',
                    '#fc8452',
                    '#9a60b4',
                    '#ea7ccc',
                    '#54cFB2',
                    '#CF00C8',
                    '#E94D96',
                    '#FFFF00',
                    '#00C73C',
                    '#003892',
                    '#FEE407'];
                let seriesObj = {

                    type: 'line',
                    smooth: true,
                }
                let legendArray = [];
                let i = 0;
                for (let key in data) {

                    let obj = {

                        name: data[key].name,
                        data: data[key].value,
                        lineStyle: {width: 3}
                    };
                    // 实际数据
                    Object.assign(obj, seriesObj);
                    seriesData.push(obj);
                    // 处理悬浮显示是内容
                    let unit = data[key].unit;
                    if (unit === '--') {

                        unit = '';
                    }
                    tooltipMap.set(data[key].name, {

                        unit: unit,
                        // 用于点为异常数据时，前面的图例颜色不变
                        color: color[i]
                    });
                    // 处理图例
                    legendArray.push(data[key].name);
                    i++;
                }
                this.legendArray = legendArray;
                this.drawEChart({

                    xAxisData : echartsData.reportTime,
                    seriesData: seriesData,
                    tooltipMap: tooltipMap,
                    color: color
                })
            } else {

                this.drawEChart({

                    xAxisData : [],
                    seriesData: [],
                    tooltipMap: [],
                    visualMap: [],
                    color: []
                })
            }

        },
        drawEChart(allData) {

            let that = this;
            let option = {

                title: {

                    text: '重点监测数据',
                    textStyle: {

                        color: '#343434',
                        fontSize: 15
                    }
                },
                tooltip: {

                    trigger: 'axis',
                    axisPointer: {

                        type: 'cross',
                        label: {

                            backgroundColor: '#6a7985'
                        }
                    },
                    formatter: function (params) {

                        let val = params[0].name;
                        for (let i in params) {

                            if (allData.tooltipMap.has(params[i].seriesName)) {

                                let obj = allData.tooltipMap.get(params[i].seriesName);
                                let spanHtml = `<span style="background-color:${obj.color};display:inline-block;margin-right:5px;border-radius:10px;width:10px;height:10px;"></span>`;
                                if (params[i].value === '') {

                                    val += '<br/>' + spanHtml + params[i].seriesName + ' : ' + '--';
                                } else {

                                    let value = params[i].value;
                                    if (params[i].seriesName === '工作模式') {

                                        value = that.workModeInfo[value];
                                    }
                                    val += '<br/>' + params[i].marker + params[i].seriesName + ' : ' + value + obj.unit;
                                }
                            }
                        }
                        return val;
                    }
                },
                grid: {

                    left: '3%',
                    right: '4%',
                    bottom: '4%',
                    top: '10%',
                    containLabel: true
                },
                color: allData.color,
                legend: {

                    data: allData.legendArray,
                    top: 22
                },
                xAxis: {

                    data: allData.xAxisData,
                    type: 'category',
                },
                yAxis: {

                    type: 'value'
                },
                visualMap: allData.visualMap,
                series: allData.seriesData,
                dataZoom: [
                    {
                        type: 'inside',
                        xAxisIndex: 0,
                        start: 0,
                        end: 100
                    }
                ]
            }
            let myChart = this.eCharts.init(document.getElementById('importantDataChartId'));
            myChart.setOption(option);
        },
        getCellClassName(row) {

            return this.selectionEnableFiledNameList.indexOf(row.row.fieldName) !== -1 ? 'disabledCheck' : '';
        },
        handleSelectableEvent(row) {

            if (this.selectionEnableFiledNameList.indexOf(row.fieldName) !== -1) {

                row.selectionEnable = false;
            }
            return row.selectionEnable;
        },
        valueFormatter(row, column, cellValue) {

            if (row.fieldName === 'isOnline') {

                if (cellValue === 'true') {

                    return '在线';
                } else {

                    return '离线';
                }
            }
            if (row.fieldName === 'workMode') {

                return cellValue != null ? this.workModeInfo[cellValue] : '--';
            }
            return cellValue != null ? cellValue : '--';
        },
        stateFormatter(row, column, cellValue) {

            if (cellValue === '0') {

                return <span style='color: #67C23A;'>正常</span>
            } else {

                return <span style='color: #F56C6C;'>超时</span>;
            }
        },
        commFormatter(row, column, cellValue) {

            return cellValue != null ? cellValue : '--';
        },
        timeZoneChange() {

            this.getCurrentDate();
        },
        getCurrentDate() {

            // 使用Date对象的getTimezoneOffset()先获取当前所在国家和格林威治时间之间的差值，默认是分钟数
            let difference = new Date().getTimezoneOffset() * 60 * 1000 //将差异值转成毫秒值
            // 获取格林威治时间的毫秒值,用 + 的原因是如果比格林威治时间快的地区，这个方法返回值是一个负数，而慢的地区返回值是一个正数
            let millMinutes = new Date().getTime() + difference;
            //可以根据时区，对时间戳进行加减运算，获得对应时区的时间戳
            let str = this.searchForm.utcValue;
            let utcNumber = Number(str.substring(str.indexOf('UTC') + 'UTC'.length));
            let timeStampDate = new Date(millMinutes + utcNumber * 60 * 60 * 1000);
            this.maxDate = timeStampDate;
            // 修改最大可选日期范围
            this.pickerDateOptions = {

                disabledDate(time) {

                    return time.getTime() > timeStampDate;
                }
            }
            this.searchForm.date = DateUtil.getDateStr(this.maxDate);
            this.getPreviousFiveMinutes(this.maxDate);
        },
        getPreviousFiveMinutes(now) {

            // 计算上一个五分钟的时间点
            let minutesBack = now.getMinutes() % 5;
            // 减去多出的分钟
            let previousFiveMinutes = new Date(now - minutesBack * 60 * 1000);
            // 返回格式化后的小时和分钟
            this.searchForm.time = previousFiveMinutes.toLocaleTimeString([],{ hour: '2-digit', minute: '2-digit' });
            // 只比较日期，不比较时间
            let currentDate = new Date(this.searchForm.date).toLocaleDateString();
            // 当前全中日期不是最大日期时，00:00-23:55都能选（必须大于end时间，否则end时间不能选）
            let maxTime = '23:56';
            if (currentDate === now.toLocaleDateString()) {

                // 如果当前选中的日期是最大日期，时间只能选到当天最大时间
                // 由于组件缺陷，设置最大时间时要比当前时间大一分钟，否则无法选中当前时间
                previousFiveMinutes.setMinutes( previousFiveMinutes.getMinutes() + 1);
                maxTime = previousFiveMinutes.toLocaleTimeString([],{ hour: '2-digit', minute: '2-digit' });
            }
            // 1、先清空对象中的maxTime；2、在dom更新后重新给对象赋值；否则下拉框中可选项不更新
            this.pickerTimeOptions = {};
            this.$nextTick(() => {

                this.pickerTimeOptions = {

                    start: '00:00',
                    step: '00:05',
                    end: '23:55',
                    maxTime: maxTime
                }
            })
        },
        pickerDateChange() {

            this.getPreviousFiveMinutes(this.maxDate);
        },
        /**
         * 计算点数据表中的通讯成功率.
         * @param valueMap 数据
         */
        calLineChartCommSuccessRate(valueMap) {
            // 开始时间0点
            let startTime = new Date(this.reportTimeRange[0] + 'T00:00:00');
            // 结束时间
            let endTime = new Date(this.reportTimeRange[1] + 'T23:59:59');
            let now = new Date();
            if (endTime.getDate() === now.getDate() &&
                endTime.getMonth() === now.getMonth() &&
                endTime.getFullYear() === now.getFullYear()) {
                // 结束日期如果是当天则用当前时间
                endTime = now;
            }
            // 计算隔了多少个5分钟
            let fiveMinuteIntervals = Math.floor((endTime - startTime) / (5 * 60 * 1000));
            // 应上报数据笔数 = 间隔5分钟数 + 1
            let shouldReportCount = fiveMinuteIntervals + 1;
            this.commSuccessRateDataList = [];
            // mode不展示
            delete valueMap['mode'];
            for (let key in valueMap) {

                let list = valueMap[key].value;
                let dataActualReportCount = list.filter(item => item !== '').length;
                let successRate = (dataActualReportCount / shouldReportCount) * 100;
                this.commSuccessRateDataList.push({

                    fieldCn: valueMap[key].name,
                    commSuccessRate: successRate.toFixed(2)
                });
                this.commSuccessRateDataList.sort((a, b) => b.commSuccessRate - a.commSuccessRate);
            }
        },
        /**
         * 进度条颜色.
         */
        progressColor(percentage) {

            if (percentage < 50) {

                return '#F56C6C';
            } else if (percentage < 80) {

                return '#e6a23c';
            } else {

                return '#67c23a';
            }
        }
    }
}
</script>

<style lang="scss" scoped>
.form-box {

    $width : 130px;
    .el-select {

        width: $width
    }
    .el-input {

        width: $width;
    }
    .line {

        padding: 0 8px;
    }
}
.statistics {

    display: flex;
    justify-content: space-around;
    .option-btn-normal {

        font-size: 18px;
        color: #409EFF;
        cursor: pointer;
    }
    .statistics-btn {

        height: 20px;
        width: 20px;
        background-image: url('../../../../assets/icon/button/statistics.svg');
        background-size: cover;
        background-repeat: no-repeat;
    }
}

::v-deep .el-table .disabledCheck .cell .el-checkbox__inner {

    display: none !important;
}
.have-data {

    position: absolute;
    top: 400px;
    left: 550px;
}
#importantDataChartId {

    width: 1180px;
    height: 640px;
}
::v-deep .el-form-item__label {

    color: #343434;
    padding-right: 0;
}
.dateRange {

    text-align: right;
}
.successRateTitle {

    color: #343434;
    font-weight: bold;
    margin-left: 11px;
    font-size: 15px;
}
.successRateForm {

    margin-top: 20px;

    .successRateItem {

        margin-left: 11px;
    }
}
</style>
