<template>
    <div>
        <div class="content">
            <el-form :model="editForm" :rules="formRules" ref="editForm" label-width="200px" :inline="true">
                <el-row>
                    <el-col :span="24">
                        <el-form-item>
                            <template slot="label">
                                <div class="form-item-label">电网电压跌落调节方式：</div>
                            </template>
                            <el-select class="item" v-model="editForm.id" placeholder="请选择调节方式">
                                <el-option v-for="item in gridVoltageArray" :key="item.id" :label="item.name" :value="item.id"/>
                            </el-select>
                        </el-form-item>
                    </el-col>
                </el-row>
                <span class="text-down">需要关机后设置</span>
                <el-row>
                    <el-col :span="24">
                        <el-form-item >
                            <template slot="label">
                                <div class="form-item-label">防逆流功能：</div>
                            </template>
                            <el-switch class="switch" v-model="editForm.antiCounter" :active-value="'EE00'"
                                       :inactive-value="'00EE'"/>
                        </el-form-item>
                    </el-col>
                </el-row>
                <span class="text-down">需要关机后设置</span>
                <el-row>
                    <el-col :span="24">
                        <el-form-item label="并网SOC下限设置：" prop="gridSocMin">
                            <el-input class="item" v-model="editForm.gridSocMin" placeholder="[10~100]" autocomplete="off">
                                <template slot="append">%</template>
                            </el-input>
                        </el-form-item>
                    </el-col>
                </el-row>
                <el-col :span="24">
                    <el-form-item label="并网功率上限：" prop="powerMax5K">
                        <el-input class="item" v-model="editForm.powerMax5K" placeholder="[0~5000]"
                                  autocomplete="off">
                            <template slot="append">W</template>
                        </el-input>
                    </el-form-item>
                </el-col>
            </el-form>
        </div>
        <div style="text-align: center">
            <el-button type="primary" :disabled="disableBtn" @click="sendCmd">设置</el-button>
        </div>
    </div>
</template>

<script>

    import loading from '@/utils/cmdLoading';
    import tool from '@/utils/tool.js';
    import message from '@/utils/message';
    import mqttService from '@/api/mqtt-service';
    import Global from '@/components/Global';
    export default {

        name: 'base-param-setting',
        props: {

            deviceInfo: {

                type: Object
            }
        },
        data() {

            return {

                editForm: {

                    antiCounter: '00EE',
                    id: '1',
                    gridSocMin: '',
                    powerMax5K: '',
                },
                gridVoltageArray: [
                    {
                        id : '1',
                        name : '孤岛保护'
                    },
                    {
                        id : '2',
                        name : '低电压穿越'
                    },
                    {
                        id : '3',
                        name : '不间断切换'
                    },
                ],
                disableBtn: false,
                formRules: {

                    gridSocMin: [

                        { required: true, message: '[10-100]', trigger: 'blur' },
                        { pattern: /^([1-9][0-9]|100)$/, message: '[10-100]' }
                    ],
                    powerMax5K: [

                        { required: true, message: '[0-5000]', validator: this.validatePowerMax5K, trigger: 'blur' }
                    ]
                },
                command: {

                    readCommandCode: 'querySysBasicParam',
                    modifyCommandCode: 'modifySysBasicParam'
                },
                requestParam: {}
            }
        },
        methods: {

            initPage() {

                this.disableBtn = true;
                this.cmdLoading = this.openCmdLoading('基本参数读取中...');
                let that = this;
                let option = {

                    loading: that.cmdLoading,
                    data: {

                        deviceSn: that.deviceInfo.serialNumber,
                        commandCode: that.command.readCommandCode
                    },
                    mqttMessageCallback: function (res) {

                        if (res.code === Global.response_status_success_obj) {

                            that.successCallback(res.data);
                        } else {

                            message.error("指令读取失败");
                        }
                    }
                };
                mqttService.sendEsaioBusinessCommand(option);
            },
            sendCmd() {

                this.$refs['editForm'].validate((valid) => {

                    if (valid) {

                        this.disableBtn = true;
                        this.cmdLoading = this.openCmdLoading('基本参数设置中...');
                        let that = this;
                        that.requestParam = {

                            gridVoltageControlMode: this.editForm.id,
                            gridSocMin: this.editForm.gridSocMin,
                            powerMax: this.editForm.powerMax5K,
                            antiCounterFlow: this.editForm.antiCounter,
                        };
                        let option = {

                            loading: that.cmdLoading,
                            data: {

                                deviceSn: that.deviceInfo.serialNumber,
                                commandCode: that.command.modifyCommandCode,
                                data: {

                                    ...that.requestParam
                                }
                            },
                            mqttMessageCallback: function (res) {

                                let resultData = that.successCallback(res.data);
                                // 修改后重新读到的值与下发值一致时提示用户修改成功,否则修改失败
                                if (tool.isObjectValueEqual(that.requestParam, resultData)) {

                                    message.success('配置成功');
                                } else {

                                    message.error('配置失败');
                                }
                            }
                        };
                        mqttService.sendEsaioBusinessCommand(option);
                    }
                });
            },
            successCallback(data) {

                this.disableBtn = false;
                this.editForm.gridSocMin = data.gridSocMin;
                this.editForm.id = data.gridVoltageControlMode;
                this.editForm.powerMax5K = data.powerMax;
                this.editForm.antiCounter = data.antiCounterFlow.toUpperCase();
                return {

                    gridVoltageControlMode: data.gridVoltageControlMode,
                    gridSocMin: data.gridSocMin,
                    powerMax: data.powerMax,
                    antiCounterFlow: data.antiCounterFlow.toUpperCase()
                };
            },
            validatePowerMax5K(rule, value, callback) {

                let reg = /^\d+$/;
                if (value === '') {

                    callback(new Error('[0-5000]'));
                } else if (!reg.test(value)){

                    callback(new Error('[0-5000]'));
                } else if (value < 0 || value > 5000) {

                    callback(new Error('[0-5000]'));
                } else {

                    callback();
                }
            },
            openCmdLoading(msg) {

                return loading.loading(msg, 30);
            }
        }
    }
</script>

<style lang="scss" scoped>

    .text-down {

        float: left;
        margin: -40px 0 0 90px;
        font-size: 10px;
        color: #b9b9b9;
    }

    .form-item-label {

        margin: -8px 0 0 0;
    }

    .item {

        width: 200px;
    }

    .switch {

        margin-left: 160px;
    }

    .content {

        height: 490px;
        margin-left: 180px;
    }
</style>
