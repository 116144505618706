<template>
    <div>
        <div class="content">
            <el-form :model="editForm" :rules="formRules" ref="editForm" label-width="200px" :inline="true">
                <el-row>
                    <el-col :span="24">
                        <el-form-item prop="ammeterCheckStatus">
                            <template slot="label">
                                <div class="form-item-label">电表检测功能：</div>
                            </template>
                            <el-switch class="switch" v-model="editForm.ammeterCheckStatus" :active-value="'EE00'"
                                    :inactive-value="'00EE'"/>
                        </el-form-item>
                    </el-col>
                </el-row>
                <span class="text-down">需要关机后设置</span>
                <el-row>
                    <el-col :span="24">
                        <el-form-item prop="threePhaseUnbalance">
                            <template slot="label">
                                <div class="form-item-label">三相不平衡输出：</div>
                            </template>
                            <el-switch class="switch" v-model="editForm.threePhaseUnbalance" :active-value="'EE00'"
                                    :inactive-value="'00EE'"/>
                        </el-form-item>
                    </el-col>
                </el-row>
                <span class="text-down">需要关机后设置</span>
                <el-row>
                    <el-col :span="24">
                        <el-form-item label="阴影扫描：" prop="shadowScanStatus">
                            <el-switch class="switch" v-model="editForm.shadowScanStatus" :active-value="'EE00'"
                                    :inactive-value="'00EE'"/>
                        </el-form-item>
                    </el-col>
                </el-row>
                <el-row>
                    <el-col :span="24">
                        <el-form-item label="后备输出功能：" prop="bakOutputStatus">
                            <el-switch class="switch" v-model="editForm.bakOutputStatus" :active-value="'EE00'"
                                    :inactive-value="'00EE'"/>
                        </el-form-item>
                    </el-col>
                </el-row>
                <el-row>
                    <el-col :span="24">
                        <el-form-item label="PV源选择：" prop="pvSource">
                            <el-select class="item" v-model="editForm.pvSource" placeholder="请选择PV源">
                                <el-option v-for="item in pvSourceList" :key="item.value" :label="item.name"
                                           :value="item.value"/>
                            </el-select>
                        </el-form-item>
                    </el-col>
                </el-row>
                <el-row>
                    <el-col :span="24">
                        <el-form-item label="后备负载供电顺序：" prop="backupLoad">
                            <el-select class="item" v-model="editForm.backupLoad" placeholder="请选择后备负载供电顺序">
                                <el-option v-for="item in backupLoadList" :key="item.value" :label="item.name"
                                           :value="item.value"/>
                            </el-select>
                        </el-form-item>
                    </el-col>
                </el-row>
                <el-row>
                    <el-col :span="24">
                        <el-form-item label="离网SOC下限设置：" prop="offGridMin">
                            <el-input class="item" width="200px" v-model="editForm.offGridMin"
                                      placeholder="[10~100]"
                                      autocomplete="off">
                                <template slot="append">%</template>
                            </el-input>
                        </el-form-item>
                    </el-col>
                </el-row>
            </el-form>
        </div>
        <div style="text-align: center">
            <el-button type="primary" :disabled="disableBtn" @click="sendCmd">设置</el-button>
        </div>
    </div>
</template>

<script>
    import loading from '@/utils/cmdLoading';
    import tool from '@/utils/tool.js';
    import message from '@/utils/message';
    import mqttService from '@/api/mqtt-service';
    import Global from '@/components/Global';

    export default {

        name: 'advanced-param-setting',
        props: {

            deviceInfo: {

                type: Object
            }
        },
        data() {

            return {

                editForm: {

                    ammeterCheckStatus: '00EE',
                    threePhaseUnbalance: '00EE',
                    shadowScanStatus: '00EE',
                    bakOutputStatus: '00EE',
                    pvSource: '',
                    backupLoad: '',
                    offGridMin: ''
                },
                pvSourceList: [

                    {
                        value: '0',
                        name: 'PV1+PV2'
                    },
                    {
                        value: '1',
                        name: '无PV'
                    },
                    {
                        value: '2',
                        name: 'PV1'
                    },
                    {
                        value: '3',
                        name: 'PV2'
                    }
                ],
                backupLoadList: [

                    {
                        value: '0',
                        name: 'PV>Battery>Grid'
                    },
                    {
                        value: '1',
                        name: 'PV>Grid>Battery'
                    },
                ],
                disableBtn: false,
                formRules: {

                    offGridMin: [

                        {required: true, message: '[10~100]', trigger: 'blur'},
                        {pattern: /^([1-9][0-9]|100)$/, message: '[10~100]'}
                    ],
                    pvSource: [

                        {required: true, message: '请选择PV源', trigger: 'change'},
                    ],
                    backupLoad: [

                        {required: true, message: '请选择后备负载供电顺序', trigger: 'change'},
                    ]
                },
                command: {

                    readCommandCode: 'queryAdvanceParam',
                    modifyCommandCode: 'modifyAdvanceParam'
                },
                requestParam: {}
            }
        },
        methods: {

            initPage() {

                this.disableBtn = true;
                this.cmdLoading = this.openCmdLoading('高级参数读取中...');
                let that = this;
                let option = {

                    loading: that.cmdLoading,
                    data: {

                        deviceSn: that.deviceInfo.serialNumber,
                        commandCode: that.command.readCommandCode
                    },
                    mqttMessageCallback: function (res) {

                        if (res.code === Global.response_status_success_obj) {

                            that.successCallback(res.data);
                        } else {

                            message.error("指令读取失败");
                        }
                    }
                };
                mqttService.sendEsaioBusinessCommand(option);
            },
            sendCmd() {

                this.$refs['editForm'].validate((valid) => {

                    if (valid) {

                        this.disableBtn = true;
                        this.cmdLoading = this.openCmdLoading('高级参数设置中...');
                        let that = this;
                        that.requestParam = {

                            ammeterCheckStatus: this.editForm.ammeterCheckStatus,
                            threePhaseUnbalance: this.editForm.threePhaseUnbalance,
                            shadowScanStatus: this.editForm.shadowScanStatus,
                            bakOutputStatus: this.editForm.bakOutputStatus,
                            pvSource: this.editForm.pvSource,
                            bakLoadPowerOrder: this.editForm.backupLoad,
                            offGridMin: this.editForm.offGridMin,
                        };
                        let option = {

                            loading: that.cmdLoading,
                            data: {

                                deviceSn: that.deviceInfo.serialNumber,
                                commandCode: that.command.modifyCommandCode,
                                data: {

                                    ...that.requestParam
                                }
                            },
                            mqttMessageCallback: function (res, topic) {

                                let resultData = that.successCallback(res.data);
                                // 修改后重新读到的值与下发值一致时提示用户修改成功,否则修改失败
                                if (tool.isObjectValueEqual(that.requestParam, resultData)) {

                                    message.success('配置成功');
                                } else {

                                    message.error('配置失败');
                                }
                            }
                        };
                        mqttService.sendEsaioBusinessCommand(option);
                    }
                });
            },
            successCallback(data) {

                this.disableBtn = false;
                this.editForm.backupLoad = data.bakLoadPowerOrder;
                this.editForm.pvSource = data.pvSource;
                this.editForm.ammeterCheckStatus = data.ammeterCheckStatus.toUpperCase();
                this.editForm.threePhaseUnbalance = data.threePhaseUnbalance.toUpperCase();
                this.editForm.shadowScanStatus = data.shadowScanStatus.toUpperCase();
                this.editForm.bakOutputStatus = data.bakOutputStatus.toUpperCase();
                this.editForm.offGridMin = data.offGridMin;
                return {

                    ammeterCheckStatus : data.ammeterCheckStatus.toUpperCase(),
                    threePhaseUnbalance: data.threePhaseUnbalance.toUpperCase(),
                    shadowScanStatus : data.shadowScanStatus.toUpperCase(),
                    bakOutputStatus : data.bakOutputStatus.toUpperCase(),
                    pvSource: data.pvSource,
                    bakLoadPowerOrder: data.bakLoadPowerOrder,
                    offGridMin: data.offGridMin
                };
            },
            openCmdLoading(msg) {

                return loading.loading(msg, 30);
            }
        }
    }
</script>

<style scoped>

    .text-down {

        float: left;
        margin: -40px 0 0 90px;
        font-size: 10px;
        color: #b9b9b9;
    }

    .form-item-label {

        margin: -8px 0 0 0;
    }

    .item {

        width: 200px;
    }

    .switch {

        margin-left: 160px;
    }

    .content {

        margin-left: 180px;
        height: 490px;
    }
</style>
