<template>
    <div>
        <div class="content">
            <el-form :model="editForm" :rules="formRules" ref="editForm" label-width="270px" label-position="left" :inline="true">
                <el-row>
                    <el-col :span="24">
                        <el-form-item prop="parallelConnBatteryType">
                            <template slot="label">
                                <div class="form-item-label">并机电池接线类型</div>
                            </template>
                            <el-select class="item" v-model="editForm.parallelConnBatteryType" placeholder="请选择并机电池接线类型">
                                <el-option v-for="item in parallelConnBatteryTypeList" :key="item.value"
                                           :label="item.name" :value="item.value"/>
                            </el-select>
                        </el-form-item>
                    </el-col>
                </el-row>
                <span class="text-down">需要关机后设置</span>
                <el-row>
                    <el-col :span="24">
                        <el-form-item prop="parallelConnFlag">
                            <template slot="label">
                                <div class="form-item-label">单并机标志</div>
                            </template>
                            <el-select class="item" v-model="editForm.parallelConnFlag" placeholder="请选择单并机标志">
                                <el-option v-for="item in parallelConnFlagList" :key="item.value"
                                           :label="item.name" :value="item.value"/>
                            </el-select>
                        </el-form-item>
                    </el-col>
                </el-row>
                <span class="text-down">需要关机后设置</span>
                <el-row>
                    <el-col :span="24">
                        <el-form-item prop="parallelConnAddr">
                            <template slot="label">
                                <div class="form-item-label">并机地址</div>
                            </template>
                            <el-select class="item" v-model="editForm.parallelConnAddr" placeholder="请选择并机地址">
                                <el-option v-for="item in parallelConnAddrList" :key="item.value"
                                           :label="item.name" :value="item.value"/>
                            </el-select>
                        </el-form-item>
                    </el-col>
                </el-row>
                <span class="text-down">需要关机后设置</span>
            </el-form>
        </div>
        <div style="text-align: center">
            <el-button type="primary" :disabled="disableBtn" @click="parallelSettingSubmit">设置</el-button>
        </div>
    </div>
</template>

<script>

    import mqttService from '@/api/mqtt-service';
    import loading from '@/utils/cmdLoading';
    import message from '@/utils/message';
    import Global from '@/components/Global';
    import tool from '@/utils/tool';

    export default {

        name: 'parallel-setting',
        props: {

            deviceInfo: {

                type: Object
            }
        },
        data() {

            return {

                editForm: {

                    parallelConnBatteryType: '',
                    parallelConnFlag: '',
                    parallelConnAddr: ''
                },
                parallelConnAddrList: [

                    { value : '1', name : '主机' },
                    { value : '2', name : '从机1' },
                    { value : '3', name : '从机2' },
                    { value : '4', name : '从机3' }
                ],
                dr5kParallelConnFlagList: [

                    { value : '0', name : '单机' },
                    { value : '1', name : '并机' }
                ],
                dr5kA02ParallelConnFlagList: [

                    { value : '0', name : '单机' },
                    { value : '1', name : '并机' },
                    { value : '2', name : '组机' }
                ],
                parallelConnFlagList: [],
                parallelConnBatteryTypeList: [

                    { value : '0', name : '共用' },
                    { value : '1', name : '独立' }
                ],
                disableBtn: false,
                formRules: {

                    parallelConnBatteryType: [

                        { required: true, message: '请选择并机电池接线类型', trigger: 'change' }
                    ],
                    parallelConnFlag: [

                        { required: true, message: '请选择单并机标志', trigger: 'change' }
                    ],
                    parallelConnAddr: [

                        { required: true, message: '请选择并机地址', trigger: 'change' }
                    ]
                },
                command: {

                    readCommandCode: 'queryParallelParam',
                    modifyCommandCode: 'modifyParallelParam'
                }
            }
        },
        methods: {

            initPage() {

                if (this.deviceInfo.productModelCode === 'DR-5K-TL-AIO') {

                    this.parallelConnFlagList = this.dr5kParallelConnFlagList;
                } else if (this.deviceInfo.productModelCode === 'DR5KTLAIOA02' || this.deviceInfo.productModelCode === 'DR5KTLAIOS05') {

                    this.parallelConnFlagList = this.dr5kA02ParallelConnFlagList;
                }
                this.disableBtn = true;
                this.cmdLoading = this.openCmdLoading('并机设置参数读取中...');
                let that = this;
                let option = {

                    loading: that.cmdLoading,
                    data: {

                        deviceSn: that.deviceInfo.serialNumber,
                        commandCode: that.command.readCommandCode
                    },
                    mqttMessageCallback: function (res) {

                        if (res.code === Global.response_status_success_obj) {

                            that.successCallback(res.data);
                        } else {

                            message.error("指令读取失败");
                        }
                    }
                };
                mqttService.sendEsaioBusinessCommand(option);
            },
            parallelSettingSubmit() {

                this.$refs['editForm'].validate((valid) => {

                    if (valid) {

                        this.disableBtn = true;
                        this.cmdLoading = this.openCmdLoading('并机参数设置中...');
                        let that = this;
                        that.requestParam = {

                            parallelConnBatteryType: this.editForm.parallelConnBatteryType,
                            parallelConnFlag: this.editForm.parallelConnFlag,
                            parallelConnAddr: this.editForm.parallelConnAddr
                        };
                        let option = {

                            loading: that.cmdLoading,
                            data: {

                                deviceSn: that.deviceInfo.serialNumber,
                                commandCode: that.command.modifyCommandCode,
                                data: {

                                    ...that.requestParam
                                }
                            },
                            mqttMessageCallback: function (res) {

                                let resultData = that.successCallback(res.data);
                                // 修改后重新读到的值与下发值一致时提示用户修改成功,否则修改失败
                                if (tool.isObjectValueEqual(that.requestParam, resultData)) {

                                    message.success('配置成功');
                                } else {

                                    message.error('配置失败');
                                }
                            }
                        };
                        mqttService.sendEsaioBusinessCommand(option);
                    }
                });
            },
            successCallback(data) {

                this.disableBtn = false;
                this.editForm.parallelConnBatteryType = data.parallelConnBatteryType;
                this.editForm.parallelConnFlag = data.parallelConnFlag;
                this.editForm.parallelConnAddr = data.parallelConnAddr;
                return {

                    parallelConnBatteryType: data.parallelConnBatteryType,
                    parallelConnFlag: data.parallelConnFlag,
                    parallelConnAddr: data.parallelConnAddr
                };
            },
            openCmdLoading(msg) {

                return loading.loading(msg, 30);
            }
        }
    }
</script>

<style lang="scss" scoped>

    .text-down {

        float: left;
        margin: -42px 0 0 10px;
        font-size: 10px;
        color: #b9b9b9;
    }

    .form-item-label {

        margin: -50px 0 0 10px;
    }

    .item {

        width: 200px;
    }

    .content {

        overflow: auto;
        height: 490px;
        margin-left: 180px;
    }

    .content::-webkit-scrollbar {

        width: 3px;
    }

    .content::-webkit-scrollbar-thumb {

        background: #8798AF;
        border-radius: 2px;
    }

    .content::-webkit-scrollbar-track {

        background: transparent;
    }
</style>
