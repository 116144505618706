<template>
    <div>
        <div class="content">
            <el-form v-if="autoSyncTime === false" :model="dataForm" label-width="150px" :inline="true">
                <el-form-item label="日期时间：">
                    <el-date-picker
                        v-model="dataForm.dateTime"
                        type="datetime"
                        value-format="yyyy-MM-dd HH:mm:ss"
                        :editable="false"
                        @focus="timeEditable"
                        placeholder="选择日期时间">
                    </el-date-picker>
                </el-form-item>
            </el-form>
        </div>
        <div style="text-align: center">
            <el-button @click="handleModifyDeviceTimeClickEvent" :disabled="disableBtn" type="primary">同步时间</el-button>
        </div>
    </div>
</template>
<script>

    import {aioDrtlCommand} from '@/api/esaio/device/esaioDeviceInfo';
    import message from '@/utils/message';
    import Global from '@/components/Global';
    import mqttService from '@/api/mqtt-service';
    import loading from '@/utils/cmdLoading';

    export default {

        name: 'sync-time-setting',
        props: {

            deviceInfo: {

                type: Object
            },
            autoSyncTime: {

                type: Boolean
            }
        },
        data() {

            return {

                disableBtn: false,
                dataForm: {

                    dateTime: ''
                },
                queryTimeCommandCode: 'queryDeviceTime',
                // 自动同步时间无需手动设置
                modifySyncTimeCommandCode: 'modifySyncTime',
                // 手动设置同步的时间
                modifyDeviceTimeCommandCode: 'modifyDeviceTime'
            }
        },
        methods: {

            initPage() {

                let that = this;
                // 自动同步的则不需要读取时间
                if (that.autoSyncTime === true) {

                    return;
                }
                that.disableBtn = true;
                that.cmdLoading = loading.loading('时间读取中...', 30);
                let option = {

                    loading: that.cmdLoading,
                    data: {

                        deviceSn: that.deviceInfo.serialNumber,
                        commandCode: that.queryTimeCommandCode
                    },
                    mqttMessageCallback: function (res) {

                        if (res.code === Global.response_status_success_obj) {

                            let data = res.data;
                            that.dataForm.dateTime = data.year + '-' + data.month + '-' + data.day + ' '
                                + data.hour + ':' + data.minute + ':' + data.second;
                            that.disableBtn = false;
                        } else {

                            message.error("指令读取失败");
                        }
                    }
                }
                mqttService.sendEsaioBusinessCommand(option);
            },
            handleModifyDeviceTimeClickEvent() {

                this.disableBtn = true;
                let param = {

                    deviceSn: this.deviceInfo.serialNumber,
                    commandCode: this.modifySyncTimeCommandCode,
                    messageId: new Date().getTime()
                }
                // 手动设置时间需要传递时间参数
                if (this.autoSyncTime === false) {

                    param.commandCode = this.modifyDeviceTimeCommandCode;
                    let dateTimeArr =  this.dataForm.dateTime.split(' ');
                    let dateArr = dateTimeArr[0].split('-');
                    let timeArr = dateTimeArr[1].split(':');
                    param.data = {

                        year: dateArr[0],
                        month: dateArr[1],
                        day: dateArr[2],
                        hour: timeArr[0],
                        minute: timeArr[1],
                        second: timeArr[2]
                    };
                }
                aioDrtlCommand(param).then((res) => {

                    if (res.code === Global.response_status_success_obj) {

                        message.success('指令下发成功');
                    } else {

                        message.error(res.msg);
                    }
                    this.disableBtn = false;
                }).catch(err => {

                    this.disableBtn = false;
                    message.error('请求失败,请稍后再试');
                    console.error(err);
                });
            },
            timeEditable(){

                this.$nextTick(() => {

                    let els = document.querySelectorAll('.el-date-picker__editor-wrap input');
                    for (let i = 0; i <= els.length-1 ; i++) {

                        els[i].setAttribute('readonly', 'readonly');
                    }
                });
            }
        }
    }
</script>

<style lang="scss" scoped>

    .content {

        height: 490px;
    }
</style>
