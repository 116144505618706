<template>
    <div>
        <div class="content">
            <el-form :inline="true">
                <el-row>
                    <el-col :span="24">
                        <el-form-item label="工作模式：">
                            <el-select v-model="workMode" placeholder="请选择工作模式">
                                <el-option v-for="item in workModeList" :key="item.value" :label="item.name" :value="item.value">
                                </el-option>
                            </el-select>
                        </el-form-item>
                    </el-col>
                </el-row>
            </el-form>
        </div>
        <div style="text-align: center">
            <el-button v-if="workMode === '4'" @click="openEdit" :disabled="disableBtn" type="primary">时段设置</el-button>
            <el-button v-else @click="openEdit" :disabled="disableBtn" type="primary">电价设置</el-button>
        </div>
        <el-dialog id="workModeDialog" title="时段设置" :visible="settleVisible" :close-on-click-modal="false" :destroy-on-close=true
                   append-to-body :close-on-press-escape="false" :show-close="false" width="800px" center style="z-index: 2013">
            <el-form :model="settleForm" :rules="settleRules" ref="settleForm" label-width="180px">
                <div v-if="workMode === '4'">
                    <el-row v-if="sharpNeed">
                        <el-col :span="20">
                            <el-form-item :label="'尖时电价（' + priceUnit + '）：'" prop="sharpPrice">
                                <template>
                                    <el-input v-model="settleForm.sharpPrice" placeholder="请输入尖时电价" autocomplete="off"/>
                                </template>
                            </el-form-item>
                        </el-col>
                    </el-row>
                    <el-row v-if="peakNeed">
                        <el-col :span="20">
                            <el-form-item :label="'峰时电价（' + priceUnit + '）：'" prop="peakPrice">
                                <template>
                                    <el-input v-model="settleForm.peakPrice" placeholder="请输入峰时电价" autocomplete="off"/>
                                </template>
                            </el-form-item>
                        </el-col>
                    </el-row>
                    <el-row v-if="shoulderNeed">
                        <el-col :span="20">
                            <el-form-item :label="'平时电价（' + priceUnit + '）：'" prop="shoulderPrice">
                                <template>
                                    <el-input v-model="settleForm.shoulderPrice" placeholder="请输入平时电价" autocomplete="off"/>
                                </template>
                            </el-form-item>
                        </el-col>
                    </el-row>
                    <el-row v-if="offPeakNeed">
                        <el-col :span="20">
                            <el-form-item :label="'谷时电价（' + priceUnit + '）：'" prop="offPeakPrice">
                                <template>
                                    <el-input v-model="settleForm.offPeakPrice" placeholder="请输入谷时电价" autocomplete="off"/>
                                </template>
                            </el-form-item>
                        </el-col>
                    </el-row>
                    <el-row>
                        <el-button class="button" type="primary" @click="addData" plain>添加时段</el-button>
                    </el-row>
                    <el-row>
                        <el-table :data="settleForm.tableData" size="mini" class="table">
                            <el-table-column prop="startTime" label="开始时间" width="230px" align="center">
                                <template slot-scope="scope">
                                    <el-time-picker v-model="scope.row.startTime" format="HH:mm" value-format="HH:mm"
                                                    @change="bindStartChange(scope.row)" size="mini" align="center" />
                                </template>
                            </el-table-column>
                            <el-table-column prop="endTime" label="结束时间" width="230px" align="center">
                                <template slot-scope="scope">
                                    <el-time-picker v-model="scope.row.endTime" format="HH:mm" value-format="HH:mm"
                                                    @change="bindEndChange(scope.row)" size="mini" align="center" />
                                </template>
                            </el-table-column>
                            <el-table-column prop="type" label="时段类型" align="center">
                                <template slot-scope="scope">
                                    <el-select size="mini" v-model="scope.row.type" @change="typeChange">
                                        <el-option v-for="item in typeList" :key="item.value" :label="item.name" :value="item.value">
                                        </el-option>
                                    </el-select>
                                </template>
                            </el-table-column>
                            <el-table-column label="删除" align="center" >
                                <template slot-scope="scope">
                                    <el-button size="mini" @click="delDate(scope.$index)" type="danger">删除</el-button>
                                </template>
                            </el-table-column>
                        </el-table>
                    </el-row>
                </div>
                <div v-else>
                    <el-row>
                        <el-col :span="20">
                            <el-form-item :label="'电价（' + priceUnit + '）：'" prop="price">
                                <template>
                                    <el-input v-model="settleForm.price" placeholder="请输入电价" autocomplete="off"/>
                                </template>
                            </el-form-item>
                        </el-col>
                    </el-row>
                </div>
            </el-form>
            <div slot="footer" class="dialog-footer">
                <el-button type="primary" :disabled="disableBtn" @click="sendCmd">确定</el-button>
                <el-button :disabled="disableBtn" @click="settleVisible = false">取消</el-button>
            </div>
        </el-dialog>
    </div>
</template>

<script>
    import loading from '@/utils/cmdLoading';
    import tool from '@/utils/tool.js';
    import message from '@/utils/message';
    import mqttService from '@/api/mqtt-service';
    import {queryCurrencyCodeByStationId} from '@/api/esaio/device/esaioDeviceInfo';
    import Global from '@/components/Global';

    export default {

        name: 'work-mode-setting',
        props: {

            deviceInfo: {

                type: Object
            }
        },
        data() {

            return {

                disableBtn: false,
                settleVisible: false,
                workMode: '1',
                workModeList: [

                    { value : '1', name : '通用模式' },
                    { value : '2', name : '储能模式' },
                    { value : '3', name : '微网模式' },
                    { value : '4', name : '峰谷模式' },
                    { value : '6', name : 'AC耦合模式' }
                ],
                typeList: [

                    { value : '1', name : '尖' },
                    { value : '2', name : '峰' },
                    { value : '3', name : '平' },
                    { value : '4', name : '谷' }
                ],
                sharpNeed: true,
                peakNeed: false,
                shoulderNeed: false,
                offPeakNeed: false,
                settleForm: {

                    price: '',
                    sharpPrice: '',
                    peakPrice: '',
                    shoulderPrice: '',
                    offPeakPrice: '',
                    tableData: [

                        {

                            startTime: '00:00',
                            endTime : '00:00',
                            type: '1'
                        }
                    ],
                },
                settleRules: {

                    price: [

                        { required: true, message: '请输入电价', trigger: 'blur' },
                        { pattern: /^[0-9]+(\.[0-9])?$/, message: '电价为整数或一位小数', trigger: ['blur', 'change'] }
                    ],
                    sharpPrice: [

                        { required: true, message: '请输入尖时电价', trigger: 'blur' },
                        { pattern: /^[0-9]+(\.[0-9])?$/, message: '电价为整数或一位小数', trigger: ['blur', 'change'] }
                    ],
                    peakPrice: [

                        { required: true, message: '请输入峰时电价', trigger: 'blur' },
                        { pattern: /^[0-9]+(\.[0-9])?$/, message: '电价为整数或一位小数', trigger: ['blur', 'change'] }
                    ],
                    shoulderPrice: [

                        { required: true, message: '请输入平时电价', trigger: 'blur' },
                        { pattern: /^[0-9]+(\.[0-9])?$/, message: '电价为整数或一位小数', trigger: ['blur', 'change'] }
                    ],
                    offPeakPrice: [

                        { required: true, message: '请输入谷时电价', trigger: 'blur' },
                        { pattern: /^[0-9]+(\.[0-9])?$/, message: '电价为整数或一位小数', trigger: ['blur', 'change'] }
                    ]
                },
                priceUnit: '',
                command: {

                    readCommandCode: 'queryWorkModeParam',
                    modifyCommandCode: 'modifyWorkModeParam'
                },
                requestParam: {}
            }
        },
        methods: {

            initPage() {

                this.queryPriceUnit();
                this.disableBtn = true;
                this.cmdLoading = this.openCmdLoading('工作模式读取中...');
                let that = this;
                let option = {

                    loading: that.cmdLoading,
                    data: {

                        deviceSn: that.deviceInfo.serialNumber,
                        commandCode: that.command.readCommandCode
                    },
                    mqttMessageCallback: function (res) {

                        if (res.code === Global.response_status_success_obj) {

                            that.successCallback(res.data);
                        } else {

                            message.error("指令读取失败");
                        }
                    }
                }
                mqttService.sendEsaioBusinessCommand(option);
            },
            sendCmd() {

                this.$refs['settleForm'].validate((valid) => {

                    if (valid) {

                        let dataListSubmit = [];
                        if (this.workMode === '4') {

                            if (this.settleForm.tableData.length === 0) {

                                this.$message.error({

                                    message: '时间段不能为空',
                                    center: true
                                });
                                return;
                            }
                            let totalTimeMinutes = 0;
                            let errMsg = '';
                            for (let info of this.settleForm.tableData) {

                                if (info.type === '1') {

                                    if (this.settleForm.sharpPrice < 0 || this.settleForm.sharpPrice > 6553.5) {

                                        errMsg = '电价请在0~6553.5区间内';
                                        break;
                                    }
                                }
                                if (info.type === '2') {

                                    if (this.settleForm.peakPrice < 0 || this.settleForm.peakPrice > 6553.5) {

                                        errMsg = '电价请在0~6553.5区间内';
                                        break;
                                    }
                                }
                                if (info.type === '3') {

                                    if (this.settleForm.shoulderPrice < 0 || this.settleForm.shoulderPrice > 6553.5) {

                                        errMsg = '电价请在0~6553.5区间内';
                                        break;
                                    }
                                }
                                if (info.type === '4') {

                                    if (this.settleForm.offPeakPrice < 0 || this.settleForm.offPeakPrice > 6553.5) {

                                        errMsg = '电价请在0~6553.5区间内';
                                        break;
                                    }
                                }
                                let startTime = this.changeStrToMinutes(info.startTime);
                                let endTime = this.changeStrToMinutes(info.endTime);
                                if (info.endTime !== '00:00' && startTime >= endTime) {

                                    errMsg = '开始时间必须小于结束时间';
                                    break;
                                }
                                if (info.endTime === '00:00') {

                                    endTime = 1440;
                                }
                                totalTimeMinutes = totalTimeMinutes + (endTime - startTime);
                                dataListSubmit.push({

                                    startTime: startTime,
                                    endTime : endTime,
                                    type: info.type
                                });
                            }
                            if (errMsg !== '') {

                                this.$message.error({

                                    message: errMsg,
                                    center: true
                                });
                                return;
                            }
                            if (totalTimeMinutes !== 1440) {

                                this.$message.error({

                                    message: '时间段之和必须为24小时',
                                    center: true
                                });
                                return;
                            }
                            // 检查多个时间段的时间范围是否有重复
                            if (!this.checkTimeRange(this.settleForm.tableData)) {

                                this.$message.error({

                                    message: '时间范围有重叠',
                                    center: true
                                });
                                return;
                            }

                        } else {

                            // 非峰谷模式需要设置尖时电价，时间段为0-6553.5
                            if (this.settleForm.price < 0 || this.settleForm.price > 6553.5) {

                                this.$message.error({

                                    message: '电价请在0~6553.5区间内',
                                    center: true
                                });
                                return;
                            }
                            this.settleForm.sharpPrice = this.settleForm.price;
                            this.settleForm.peakPrice = '0';
                            this.settleForm.shoulderPrice = '0';
                            this.settleForm.offPeakPrice = '0';
                            dataListSubmit.push({

                                startTime: 0,
                                endTime : 1440,
                                type: '1'
                            });
                        }
                        this.disableBtn = true;
                        this.cmdLoading = this.openCmdLoading('工作模式设置中...');
                        let that = this;
                        that.requestParam = {

                            workMode : that.workMode,
                            data: dataListSubmit,
                            sharpPrice: this.workMode === '4' ? that.settleForm.sharpPrice : that.settleForm.price,
                            peakPrice: that.settleForm.peakPrice,
                            shoulderPrice: that.settleForm.shoulderPrice,
                            offPeakPrice: that.settleForm.offPeakPrice,
                        };
                        let option = {

                            loading: that.cmdLoading,
                            data: {

                                deviceSn: that.deviceInfo.serialNumber,
                                commandCode: that.command.modifyCommandCode,
                                data: {

                                    ...that.requestParam
                                }
                            },
                            mqttMessageCallback: function (res) {

                                let resultData = that.successCallback(res.data);
                                let compareFlag = true;
                                // 先比较工作模式
                                if (that.requestParam.workMode === resultData.workMode) {
                                    // 模式设置成功，检查其它值，只在峰谷模式比较日期数组
                                    if (res.data.workMode === '4') {

                                        if (that.requestParam.offPeakPrice !== ''
                                            && parseFloat(that.requestParam.offPeakPrice) !== parseFloat(resultData.offPeakPrice)) {

                                            compareFlag = false;
                                        }
                                        if (that.requestParam.peakPrice !== ''
                                            && parseFloat(that.requestParam.peakPrice) !== parseFloat(resultData.peakPrice)) {

                                            compareFlag = false;
                                        }
                                        if (that.requestParam.sharpPrice !== ''
                                            && parseFloat(that.requestParam.sharpPrice) !== parseFloat(resultData.sharpPrice)) {

                                            compareFlag = false;
                                        }
                                        if (that.requestParam.shoulderPrice !== ''
                                            && parseFloat(that.requestParam.shoulderPrice) !== parseFloat(resultData.shoulderPrice)) {

                                            compareFlag = false;
                                        }
                                        if (!tool.isObjectValueEqual(that.requestParam.data, resultData.data)) {

                                            compareFlag = false;
                                        }
                                    } else {

                                        // 只检查尖时电价是否相同
                                        let sharpPrice = parseFloat(resultData.sharpPrice);
                                        let oldSharpPrice = parseFloat(that.requestParam.sharpPrice);
                                        if (sharpPrice !== oldSharpPrice) {

                                            compareFlag = false;
                                        }
                                    }
                                } else {

                                    compareFlag = false;
                                }
                                if (compareFlag) {

                                    message.success('配置成功');
                                } else {

                                    message.error('配置失败');
                                }
                                that.settleVisible = false;
                            }
                        }
                        mqttService.sendEsaioBusinessCommand(option);
                    }
                });
            },
            successCallback(data) {

                this.disableBtn = false;
                this.workMode = data.workMode;
                if (data.workMode === '4') {

                    let checkData = [];
                    this.settleForm.sharpPrice = data.sharpPrice;
                    this.settleForm.peakPrice = data.peakPrice;
                    this.settleForm.shoulderPrice = data.shoulderPrice;
                    this.settleForm.offPeakPrice = data.offPeakPrice;
                    this.settleForm.tableData = data.data;
                    for (let i = 0; i < data.data.length; i++) {

                        let startTime = this.changeStrToMinutes(data.data[i].startTime);
                        let endTime = data.data[i].endTime;
                        if (endTime === '00:00') {

                            endTime = 1440;
                        } else {

                            endTime = this.changeStrToMinutes(endTime);
                        }
                        checkData.push({

                            startTime: startTime,
                            endTime : endTime,
                            type: data.data[i].type
                        });
                    }
                    return {

                        workMode : data.workMode,
                        data: checkData,
                        sharpPrice: data.sharpPrice,
                        peakPrice: data.peakPrice,
                        shoulderPrice: data.shoulderPrice,
                        offPeakPrice: data.offPeakPrice
                    };
                } else {

                    this.settleForm.price = data.sharpPrice;
                    return {

                        workMode : data.workMode,
                        sharpPrice: data.sharpPrice
                    }
                }
            },
            bindStartChange(row) {

                if (row.endTime !== '00:00' && row.startTime >= row.endTime) {

                    this.$message.error({

                        message: '开始时间必须小于结束时间',
                        center: true
                    });
                }
            },
            bindEndChange(row) {

                if (row.endTime !== '00:00' && row.startTime >= row.endTime) {

                    this.$message.error({

                        message: '结束时间必须大于开始时间',
                        center: true
                    });
                }
            },
            openEdit() {

                this.settleVisible = true;
                this.$nextTick(() => {

                    // 重置表单
                    this.$refs.settleForm.resetFields();
                });
                this.needJudge();
            },
            addData() {

                if (this.settleForm.tableData.length >= 16) {

                    this.$message.error({

                        message: '最多设置16组',
                        center: true
                    });
                    return;
                }
                this.settleForm.tableData.push({

                    startTime: '00:00',
                    endTime : '00:00',
                    type: '1',
                })
                this.needJudge();
            },
            delDate(index) {

                this.settleForm.tableData.splice(index, 1);
                this.needJudge();
                this.clearValue();
            },
            clearValue() {

                if (!this.sharpNeed) {

                    this.settleForm.sharpPrice = '';
                }
                if (!this.peakNeed) {

                    this.settleForm.peakPrice = '';
                }
                if (!this.shoulderNeed) {

                    this.settleForm.shoulderPrice = '';
                }
                if (!this.offPeakNeed) {

                    this.settleForm.offPeakPrice = '';
                }
            },
            typeChange() {

                this.needJudge();
                this.clearValue();
            },
            needJudge() {

                this.sharpNeed = false;
                this.peakNeed = false;
                this.shoulderNeed = false;
                this.offPeakNeed = false;
                for (let item of this.settleForm.tableData) {

                    switch(item.type){

                        case '1':
                            this.sharpNeed = true;
                            break;
                        case '2':
                            this.peakNeed = true;
                            break;
                        case '3':
                            this.shoulderNeed = true;
                            break;
                        case '4':
                            this.offPeakNeed = true;
                            break;
                        default:
                            break;
                    }
                }
            },
            checkTimeRange(tableData) {

                let data = [];
                for (let i in tableData) {

                    let a = tableData[i].startTime;
                    let b = tableData[i].endTime;
                    if (b === '00:00') {

                        b = '24:00';
                    }
                    data.push({startTime: a, endTime: b });
                }
                // 循环比较时间段是否冲突
                for (let j in data) {

                    for (let k in data) {

                        if (j !== k) {

                            if (data[k].startTime < data[j].startTime && data[k].endTime > data[j].startTime) {

                                return false;
                            }
                            if (data[k].startTime < data[j].endTime && data[k].endTime > data[j].endTime) {

                                return false;
                            }
                        }
                    }
                }
                return true;
            },
            changeStrToMinutes(str) {

                let minutesArr = str.split(':');
                let minutes = 0;
                if (minutesArr.length === 2) {

                    minutes = parseInt(minutesArr[0]) * 60 + parseInt(minutesArr[1]);
                }
                return minutes;
            },
            queryPriceUnit() {

                let param = {

                    stationId: this.deviceInfo.stationId,
                }
                queryCurrencyCodeByStationId(param).then((res) => {

                    if (res.code === Global.response_status_success_obj) {

                        this.priceUnit = res.data
                    } else {

                        message.error(res.msg);
                    }
                }).catch(err => {

                    message.error('请求失败,请稍后再试');
                    console.error(err);
                });
            },
            openCmdLoading(msg) {

                return loading.loading(msg, 30);
            }
        }
    }
</script>

<style lang="scss" scoped>

    .button {

        margin: 0 0 0 60px;
    }

    .table {

        width: 630px;
        margin: 10px 0 0 30px;
    }

    .content {

        margin-left: 250px;
        overflow: auto;
        height: 490px;
    }

    .content::-webkit-scrollbar {

        width: 3px;
    }

    .content::-webkit-scrollbar-thumb {

        background: #8798AF;
        border-radius: 2px;
    }

    .content::-webkit-scrollbar-track {

        background: transparent;
    }
</style>
