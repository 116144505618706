<template>
    <div>
        <div class="content">
            <el-form :model="editForm" :rules="formRules" ref="editForm" label-width="200px" label-position="left" :inline="true">
                <el-row>
                    <el-col :span="24">
                        <el-form-item prop="batterySocBacklash">
                            <template slot="label">
                                <div class="form-item-label">微网电池SOC回差：</div>
                            </template>
                            <el-input class="item" v-model="editForm.batterySocBacklash" placeholder="[5~40]" autocomplete="off">
                                <template slot="append">%</template>
                            </el-input>
                        </el-form-item>
                    </el-col>
                </el-row>
                <span class="text-down">到达限值后的回差值</span>
                <el-row>
                    <el-col :span="24">
                        <el-form-item prop="batteryActiveStatus">
                            <template slot="label">
                                <div class="no-sign-form-item-label">电池激活：</div>
                            </template>
                            <el-switch class="switch" v-model="editForm.batteryActiveStatus" :active-value="'EE00'"
                                    :inactive-value="'00EE'" />
                        </el-form-item>
                    </el-col>
                </el-row>
                <span class="text-down">单次生效，生效后恢复关</span>
                <el-row>
                    <el-col :span="24">
                        <el-form-item prop="batteryPowerLimit">
                            <template slot="label">
                                <div class="normal-fom-item-label">电池功率手动限幅：</div>
                            </template>
                            <el-switch class="switch" v-model="editForm.batteryPowerLimit" :active-value="'EE00'"
                                    :inactive-value="'00EE'"/>
                        </el-form-item>
                    </el-col>
                </el-row>
                <el-row v-if="editForm.batteryPowerLimit === 'EE00'">
                    <el-col :span="24">
                        <el-form-item label="电池充电功率上限：" prop="batteryChargePowerMax">
                            <el-input class="item" v-model="editForm.batteryChargePowerMax" placeholder="[0~4200]" autocomplete="off">
                                <template slot="append">W</template>
                            </el-input>
                        </el-form-item>
                    </el-col>
                </el-row>
                <el-row v-if="editForm.batteryPowerLimit === 'EE00'">
                    <el-col :span="24">
                        <el-form-item label="电池放电功率上限：" prop="batteryDisChargePowerMax">
                            <el-input class="item" v-model="editForm.batteryDisChargePowerMax"
                                      placeholder="[0~4200]" autocomplete="off">
                                <template slot="append">W</template>
                            </el-input>
                        </el-form-item>
                    </el-col>
                </el-row>
                <el-row v-if="editForm.batteryPowerLimit === 'EE00'">
                    <el-col :span="24">
                        <el-form-item label="市电充电电流上限：" prop="mainsElectricityCurrentMax">
                            <el-input class="item" v-model="editForm.mainsElectricityCurrentMax"
                                      placeholder="[0~20.0]" autocomplete="off">
                                <template slot="append">A</template>
                            </el-input>
                        </el-form-item>
                    </el-col>
                </el-row>
                <div v-if="deviceInfo.commMode === '1'">
                    <el-row>
                        <el-col :span="24">
                            <el-form-item prop="batteryVoltageStatus">
                                <template slot="label">
                                    <div class="normal-fom-item-label">电池电压是否可设：</div>
                                </template>
                                <el-switch class="switch" v-model="editForm.batteryVoltageStatus" :active-value="'EE00'"
                                        :inactive-value="'00EE'" />
                            </el-form-item>
                        </el-col>
                    </el-row>
                    <el-row v-if="editForm.batteryVoltageStatus === 'EE00'">
                        <el-col :span="24">
                            <el-form-item label="电池电压上限：" prop="batteryVoltageMax">
                                <el-input class="item" v-model="editForm.batteryVoltageMax" placeholder="[50.0~58.0]"
                                          autocomplete="off">
                                    <template slot="append">%</template>
                                </el-input>
                            </el-form-item>
                        </el-col>
                    </el-row>
                    <el-row v-if="editForm.batteryVoltageStatus === 'EE00'">
                        <el-col :span="24">
                            <el-form-item label="电池电压下限：" prop="batteryVoltageMin">
                                <el-input class="item" v-model="editForm.batteryVoltageMin" placeholder="[40.0~50.0]"
                                          autocomplete="off">
                                    <template slot="append">%</template>
                                </el-input>
                            </el-form-item>
                        </el-col>
                    </el-row>
                </div>
            </el-form>
        </div>
        <div style="text-align: center">
            <el-button type="primary" :disabled="disableBtn" @click="batteryParamSubmit">设置</el-button>
        </div>
    </div>
</template>

<script>

    import loading from '@/utils/cmdLoading';
    import mqttService from '@/api/mqtt-service';
    import tool from '@/utils/tool';
    import message from '@/utils/message';
    import Global from '@/components/Global';

    export default {

        name: 'battery-setting',
        props: {

            deviceInfo: {

                type: Object
            }
        },
        data() {

            return {

                editForm: {

                    batteryActiveStatus: '00EE',
                    batteryPowerLimit: '00EE',
                    batteryVoltageStatus: '00EE',
                    batterySocBacklash: '',
                    batteryChargePowerMax: '',
                    batteryDisChargePowerMax: '',
                    mainsElectricityCurrentMax: '',
                    batteryVoltageMax: '',
                    batteryVoltageMin: ''
                },
                disableBtn: false,
                formRules: {

                    batterySocBacklash: [

                        { required: true, message: '[5-40]', trigger: 'blur' },
                        { pattern: /^([5-9]|[1-3]\d|40)$/, message: '[5-40]' }
                    ],
                    batteryChargePowerMax: [

                        { required: true, validator: this.validateBatteryPowerMax, trigger: 'blur' }
                    ],
                    batteryDisChargePowerMax: [

                        { required: true, validator: this.validateBatteryPowerMax, trigger: 'blur' }
                    ],
                    mainsElectricityCurrentMax: [

                        { required: true, message: '[0-20.0]', trigger: 'blur' },
                        { pattern: /^(([0-9]|[1][0-9])([.][0-9])|([0-9]|1[0-9]|20|20[.][0]))$/, message: '[0-20.0]' }
                    ],
                    batteryVoltageMax: [

                        { required: true, message: '[50.0-58.0]', trigger: 'blur' },
                        { pattern: /^((5[0-7])([.][0-9])|(5[0-8]|58[.][0]))$/, message: '[50.0-58.0]'}
                    ],
                    batteryVoltageMin: [

                        { required: true, message: '[40.0-50.0]', trigger: 'blur' },
                        { pattern: /^((4[0-9])([.][0-9])|(4[0-9]|50[.][0]))$/, message: '[40.0-50.0]' }
                    ]
                },
                command: {

                    readCommandCode: 'queryBatteryParam',
                    modifyCommandCode: 'modifyBatteryParam'
                },
                requestParam: {}
            }
        },
        methods: {

            initPage() {

                this.disableBtn = true;
                this.cmdLoading = this.openCmdLoading('电池设置读取中...');
                let that = this;
                let option = {

                    loading: that.cmdLoading,
                    data: {

                        deviceSn: that.deviceInfo.serialNumber,
                        commandCode: that.command.readCommandCode
                    },
                    mqttMessageCallback: function (res) {

                        if (res.code === Global.response_status_success_obj) {

                            that.successCallback(res.data);
                        } else {

                            message.error("指令读取失败");
                        }
                    }
                };
                mqttService.sendEsaioBusinessCommand(option);
            },
            batteryParamSubmit() {

                this.$refs['editForm'].validate((valid) => {

                    if (valid) {

                        this.disableBtn = true;
                        this.disableBtn = true;
                        this.cmdLoading = this.openCmdLoading('电池参数设置中...');
                        let that = this;
                        that.requestParam = Object.assign({}, that.editForm);
                        let option = {

                            loading: that.cmdLoading,
                            data: {

                                deviceSn: that.deviceInfo.serialNumber,
                                commandCode: that.command.modifyCommandCode,
                                data: {

                                    ...that.editForm
                                }
                            },
                            mqttMessageCallback: function (res) {

                                let resultData = that.successCallback(res.data);
                                that.requestParam.mainsElectricityCurrentMax = parseFloat(that.requestParam.mainsElectricityCurrentMax);
                                if (tool.isObjectValueEqual(that.requestParam, resultData)) {

                                    message.success('配置成功');
                                } else {

                                    message.error('配置失败');
                                }
                            }
                        };
                        mqttService.sendEsaioBusinessCommand(option);
                    }
                });
            },
            successCallback(data) {

                this.disableBtn = false;
                this.editForm.batteryActiveStatus = data.batteryActiveStatus.toUpperCase();
                this.editForm.batteryChargePowerMax = data.batteryChargePowerMax;
                this.editForm.batteryDisChargePowerMax = data.batteryDisChargePowerMax;
                this.editForm.batteryPowerLimit = data.batteryPowerLimit.toUpperCase();
                this.editForm.batterySocBacklash = data.batterySocBacklash;
                this.editForm.batteryVoltageMax = data.batteryVoltageMax;
                this.editForm.batteryVoltageMin = data.batteryVoltageMin;
                this.editForm.batteryVoltageStatus = data.batteryVoltageStatus.toUpperCase();
                this.editForm.mainsElectricityCurrentMax = parseFloat(data.mainsElectricityCurrentMax);
                return {

                    batteryActiveStatus: data.batteryActiveStatus.toUpperCase(),
                    batteryChargePowerMax: data.batteryChargePowerMax,
                    batteryDisChargePowerMax: data.batteryDisChargePowerMax,
                    batteryPowerLimit: data.batteryPowerLimit.toUpperCase(),
                    batterySocBacklash: data.batterySocBacklash,
                    batteryVoltageMax: data.batteryVoltageMax,
                    batteryVoltageMin: data.batteryVoltageMin,
                    batteryVoltageStatus: data.batteryVoltageStatus.toUpperCase(),
                    mainsElectricityCurrentMax: parseFloat(data.mainsElectricityCurrentMax)
                }
            },
            validateBatteryPowerMax(rule, value, callback) {

                let reg = /^\d+$/;
                if (value === '') {

                    callback(new Error('[0-4200]'));
                } else if (!reg.test(value)){

                    callback(new Error('[0-4200]'));
                } else if (value < 0 || value > 4200) {

                    callback(new Error('[0-4200]'));
                } else {

                    callback();
                }
            },
            openCmdLoading(msg) {

                return loading.loading(msg, 30);
            }
        }
    }
</script>

<style lang="scss" scoped>

    .text-down {

        float: left;
        margin: -40px 0 0 10px;
        font-size: 10px;
        color: #b9b9b9;
    }

    .no-sign-form-item-label {

        margin: -7px 0 0 10px;
    }

    .form-item-label {

        margin: -48px 0 0 10px;
    }

    .normal-fom-item-label {

        margin: 0 0 0 10px;
    }

    .item {

        width: 200px;
    }

    .switch {

        margin-left: 160px;
    }

    .content {

        margin-left: 190px;
        overflow: auto;
        height: 490px;
    }

    .content::-webkit-scrollbar {

        width: 3px;
    }

    .content::-webkit-scrollbar-thumb {

        background: #8798AF;
        border-radius: 2px;
    }

    .content::-webkit-scrollbar-track {

        background: transparent;
    }
</style>
