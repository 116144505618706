<template>
    <div>
        <div class="content">
            <div class="main">
                <el-card shadow="hover" @click.native="upgrade('PCU')">
                    <div>
                        <label class="upper-text">PCU升级</label>
                        <label class="change-icon"><i class="el-icon-arrow-right"></i></label>
                        <label class="version-text">{{versionInfo.pcuCurVersion}}</label>
                        <el-image v-if="isShowUpgradePcu" :src="require('@/assets/image/upgrade_new.png')" class="new-icon"/>
                    </div>
                    <div>
                        <label class="under-text">升级完毕后设备会重新启动</label>
                    </div>
                </el-card>
                <el-card shadow="hover" @click.native="upgrade('DSP')">
                    <div>
                        <label class="upper-text">DSP升级</label>
                        <label class="change-icon"><i class="el-icon-arrow-right"></i></label>
                        <label class="version-text">{{versionInfo.dspCurVersion}}</label>
                        <el-image v-if="isShowUpgradeDsp" :src="require('@/assets/image/upgrade_new.png')" class="new-icon"/>
                    </div>
                    <div>
                        <label class="under-text">需要关机后进行升级，升级完毕后设备会重新启动</label>
                    </div>
                </el-card>
            </div>
            <el-dialog :visible.sync="dialogVisible" :close-on-click-modal="false" :append-to-body="true"
                :close-on-press-escape="false" :show-close="false" width="480px" top="30vh" class="dialog">
                <el-progress type="circle" :percentage="upgradeInfo.curProgress" :stroke-width="20" :width="170"/>
                <div style="font-size: 20px;color: #e7e7e7;margin-top: 10px ">
                    <label>{{upgradeInfoTitle}}</label>
                </div>
            </el-dialog>
        </div>
    </div>
</template>

<script>

    import {getUpgradeInfo, getVersionInfo} from '@/api/esaio/device/esaioDeviceInfo';
    import loading from '@/utils/cmdLoading';
    import message from '@/utils/message';
    import Global from '@/components/Global';
    import mqttService from '@/api/mqtt-service';

    export default {

        name: 'upgrade',
        props: {

            deviceInfo: {

                type: Object
            }
        },
        data() {

            return {

                dialogVisible: false,
                disableBtn: false,
                versionInfo: {

                    pcuCurVersion: '',
                    pcuLastVersion: '',
                    dspCurVersion: '',
                    dspLastVersion: ''
                },
                isShowUpgradePcu: false,
                isShowUpgradeDsp: false,
                queryUpgradeInfoTimer: null,
                upgradeInfo: {

                    isUpgrade: false,
                    upgradeType: null,
                    curProgress: 0
                },
                upgradeInfoTitle: '',
                popupChangeE: null,
                isUpgradeWatch: null,
                curProgressWatch: null,
            }
        },
        methods: {

            initPage() {

                this.initWatch();
                this.queryUpgradeInfo();
                this.queryVersionInfo();
            },
            queryUpgradeInfo() {

                let param = {

                    sn: this.deviceInfo.serialNumber,
                }
                getUpgradeInfo(param).then((res) => {

                    if (res.code === Global.response_status_success_obj) {

                        // 如果当前由升级中变为非升级中，则认为是更新失败，保留之前进度值
                        if (!res.data.isUpgrade) {

                            // 非升级中
                            if (this.upgradeInfo.isUpgrade && this.upgradeInfo.curProgress !== 100) {

                                // 升级失败，保留升级进度
                                this.upgradeInfo.isUpgrade = false;
                            }
                        } else {

                            // 升级中直接复制
                            this.upgradeInfo = res.data;
                        }
                    } else {

                        message.error(res.msg);
                    }
                }).catch(err => {

                    message.error('请求失败,请稍后再试');
                    console.error(err);
                });
            },
            queryVersionInfo() {

                let param = {

                    sn: this.deviceInfo.serialNumber,
                }
                getVersionInfo(param).then((res) => {

                    console.error("data:" + JSON.stringify(param))
                    if (res.code === Global.response_status_success_obj) {

                        let data = res.data;
                        this.isShowUpgradePcu = data.pcuCurVersion !== null &&
                            data.pcuLastVersion !== null &&
                            data.pcuCurVersion !== data.pcuLastVersion;
                        this.isShowUpgradeDsp = data.dspCurVersion === 'V0A00D00' || (data.dspCurVersion !== null &&
                            data.dspLastVersion !== null &&
                            data.dspCurVersion !== data.dspLastVersion);
                        this.versionInfo = data;
                    } else {

                        message.error(res.msg);
                    }
                }).catch(err => {

                    message.error('请求失败,请稍后再试');
                    console.error(err);
                });
            },
            upgrade(type) {

                // 校验是否可升级
                let isUpgrade = true;
                if (type === 'PCU') {

                    isUpgrade = this.isShowUpgradePcu;
                } else if (type === 'DSP') {

                    isUpgrade = this.isShowUpgradeDsp;
                }
                if (!isUpgrade) {

                    message.warning('已经是最新版本')
                    return;
                }
                // 提示升级确认框
                let content;
                if (type === 'PCU') {

                    content = '确认升级PCU版本？';
                } else if (type === 'DSP') {

                    content = '确认升级DSP版本？';
                }
                message.confirm(content, false, 'warning', ()=>{

                    this.cmdLoading = loading.loading('升级指令下发中...', 60);
                    let that = this;
                    let option = {

                        loading: that.cmdLoading,
                        data: {

                            deviceSn: that.deviceInfo.serialNumber,
                            commandCode: 'firmwareUpgrade',
                            data: {

                                type: type
                            }
                        },
                        mqttMessageCallback: function (res) {

                            if (res.code === Global.response_status_success_obj) {

                                that.resetUpgradeInfo();
                                that.queryUpgradeInfo();
                            } else {

                                message.error('升级失败，请重试...')
                            }
                        }
                    };
                    mqttService.sendEsaioBusinessCommand(option);
                })
            },
            /**
             * 创建属性监听.
             */
            initWatch() {

                // 逻辑问题点：
                // 1.升级进度为100，10秒后重置升级信息为 {isUpgrade: false, upgradeType: null, curProgress: null}
                // 2.10秒左右打开页面，会查询一下升级信息，这时候升级信息为 {isUpgrade: true, upgradeType: '1/2', curProgress: 100} 触发打开进度信息页面事件
                // 3.刷新后升级信息变为 {isUpgrade: false, upgradeType: null, crProgress: null}，触发关闭进度信息页面事件
                // 结论：打开或关闭升级进度页面，需满足升级进度不为空或不为100的情况
                this.isUpgradeWatch = this.$watch('upgradeInfo.isUpgrade', (newValue, oldValue) => {

                    if (newValue !== oldValue) {

                        console.log('触发升级状态发生改变事件：new->' + newValue + ' old->' + oldValue);
                        // 如果没有升级进度或者升级进度为100的情况下，不触发以下逻辑
                        if (this.upgradeInfo.curProgress === null || this.upgradeInfo.curProgress === 100) {

                            return;
                        }
                        // 打开进度信息窗口
                        if (newValue) {

                            console.log('开始打开信息进度窗口');
                            // 清除刷新进度除定时器
                            this.clearQueryUpgradeInfoTimer();
                            // 打开进度条弹窗
                            if (!this.dialogVisible) {

                                this.dialogVisible = true;
                            }
                            // 显示固件升级中
                            if (this.upgradeInfo.upgradeType === 'PCU') {

                                this.upgradeInfoTitle = 'PCU升级中，请勿关闭离开此页面...';
                            } else if (this.upgradeInfo.upgradeType === 'DSP') {

                                this.upgradeInfoTitle = 'DSP升级中，请勿关闭离开此页面...';
                            }
                            // 创建刷新进度定时器
                            this.queryUpgradeInfoTimer = setInterval(this.queryUpgradeInfo, 2000);
                        } else {

                            // 清除刷新进度除定时器
                            this.clearQueryUpgradeInfoTimer();
                            // 显示升级失败
                            this.upgradeInfoTitle = '升级失败，请重试...';
                            // 关闭进度条弹窗
                            setTimeout(() => {

                                this.dialogVisible = false;
                            }, 5000);
                        }
                    }
                }, {deep: true, immediate: false});
                this.curProgressWatch = this.$watch('upgradeInfo.curProgress', (newValue, oldValue) => {

                    if (newValue !== oldValue && newValue === 100) {

                        console.log('触发进度条为100事件：new->' + newValue + ' old->' + oldValue);
                        // 解绑升级进度监听
                        this.unWatch();
                        // 清除刷新进度条定时器
                        this.clearQueryUpgradeInfoTimer();
                        // 显示升级成功
                        this.upgradeInfoTitle = '升级成功，设备重启中，请稍后查看设备状态...';
                        // 关闭进度窗口
                        setTimeout(() => {

                            this.dialogVisible = false;
                        }, 5000);
                        // 重置升级信息及升级进度监听
                        setTimeout(() => {

                            console.log('开始重置升级进度信息');
                            this.resetUpgradeInfo();
                            this.initWatch();
                        }, 10000);
                        // 重新查询版本信息
                        this.queryVersionInfo();
                        this.$emit('fatherQueryVersionInfo');
                    }
                }, {deep: true, immediate: false});
            },
            /**
             * 重置升级信息.
             */
            resetUpgradeInfo() {

                this.upgradeInfo.isUpgrade = false;
                this.upgradeInfo.upgradeType = null;
                this.upgradeInfo.curProgress = 0;
            },
            /**
             * 解绑属性监听.
             */
            unWatch() {

                if (this.isUpgradeWatch) {

                    this.isUpgradeWatch();
                }
                if (this.curProgressWatch) {

                    this.curProgressWatch();
                }
            },
            /**
             * 清除查询升级信息的定时器.
             */
            clearQueryUpgradeInfoTimer() {

                if (this.queryUpgradeInfoTimer) {

                    clearInterval(this.queryUpgradeInfoTimer);
                }
            },
        }
    }
</script>

<style lang="scss" scoped>

    .change-icon{

        font-size:35px;
        margin-right: -10px;
        color: #a1a1a1;
        float: right;
    }

    ::v-deep .el-dialog {

        background-color: rgba(0, 0, 0, 0);
        box-shadow: 0 1px 3px rgba(0,0,0,0);
    }

    ::v-deep .el-progress__text {

        color: #ffffff;
    }

    ::v-deep .el-progress-circle__track {
        stroke: #EEEEEE;
    }

    .main {

        margin-left: 10px;
    }

    .dialog {

        text-align: center;
        margin-left: 110px;
    }

    .upper-text {

        font-size: 17px;
    }

    .under-text {

        color: #b3abab;
        font-size: 11px;
    }

    .new-icon {

        float: right;
        width: 38px;
    }

    .version-text {

        float: right;
        font-size: 17px;
        color: #b3abab;
        margin-top: 10px;
    }

    .content {

        height: 490px;
    }
</style>
